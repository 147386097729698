<template>
  <div class="">
    <div class="">
      <!-- Content Header (Page header) -->
      <div class="content-header" style="">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Renew Membership</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item">Renew Membership</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->

        <div class="row rm-p-m" v-if="loading" style="position: absolute; left:15%; right:0; top:50%;">
          <div class="col-md-12 text-center p-3">
            <i class="fa fa-circle-notch fa-spin fa-2x"></i>
          </div>
        </div>

        <div class="row rm-p-m" v-else>
          <div class="col-md-12">
            <div class="clubrenewal">
              <div class="tabbable">
                <div class="h-steps ">
                  <section>
                    <ul class="header-navigation">
                      <li><span :class="wiz_step3 ? 'header-steps current-item' : 'header-steps'">Verify</span></li>
                      <li><span :class="wiz_step4 ? 'header-steps current-item' : 'header-steps'">Donation</span></li>
                      <li><span :class="wiz_step5 ? 'header-steps current-item' : 'header-steps'">Legal Agreement</span>
                      </li>
                      <li><span :class="wiz_step6 ? 'header-steps current-item' : 'header-steps'">Payment</span></li>
                      <li><span :class="wiz_step7 ? 'header-steps current-item' : 'header-steps'">Confirmation</span>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
            <div>
              <div v-show="wiz_step3" class="verify-view">
                <b-modal ok-only ref="modal"
                         ok-title="Update"
                         @ok="pushClubInfo" id="modal-club-edit" size="lg" centered title="Club Information">
                  <div class="popupbody">
                    <div class="row">
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.clubname">
                        <div class="form-group">
                          <label for="clubname" class="form-label">Club Name <sup class="text-danger">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="clubname"
                                 aria-describedby="clubname" placeholder="Enter Club Name" v-model="clubForm.name"
                                 name="clubForm.name">
                          <span class="text-sm text-danger">{{ errors.name }}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.primary_club_type">
                        <div class="form-group">
                          <label for="clubname" class="form-label">Primary Club Type <sup
                              class="text-danger">*</sup></label><br>
                          <select name="club_type" class="form-control" v-model="clubForm.type">
                            <option value="">Select</option>
                            <option v-for="(typ, val) in clubType" :value="val" :key="val"
                                    :selected="clubForm.type == val">{{ typ }}
                            </option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.type }}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.secondary_club_type">
                        <div class="form-group">
                          <label for="clubname" class="form-label">Secondary Club Type</label><br>
                          <select name="club_type" class="form-control" v-model="clubForm.secondary_type">
                            <option value="">Select</option>
                            <option v-for="(typ, val) in clubType" :value="val" :key="val"
                                    :selected="clubForm.secondary_type == val">{{ typ }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_website">
                        <div class="form-group">
                          <label for="clubweb" class="form-label ">Club Website</label>
                          <div class="clearfix"></div>
                          <div class="left-field">
                            <input type="text" class="form-control" maxlength="45" id="clubweb"
                                   aria-describedby="clubweb" placeholder="Enter Club Website" name="clubForm.website"
                                   v-model="clubForm.website">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="fields.club_info && fields.club_info.include_directory">
                        <div class="form-group">
                          <div class="">
                            <input type="checkbox" :value="0" v-model="clubForm.exclude" :true-value="0"
                                   :false-value="1" name="clubForm.exclude">
                            <label class="form-check-label" for="directoryYes">Include in USA-WSWS online club
                              directory</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="fields.club_info && fields.club_info.email">
                        <div class="form-group">
                          <label for="email" class="form-label">Club E-Mail <sup class="text-danger">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="email" v-model="clubForm.email"
                                 name="clubForm.email">
                          <span class="text-sm text-danger">{{ errors.email }}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_address">
                        <div class="form-group">
                          <label for="club_address" class="form-label">Club Mailing Address <sup class="text-danger" v-if="fields.club_info.club_address.required">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="club_address"
                                 name="clubForm.address" v-model="clubForm.address">
                          <span class="text-sm text-danger">{{ errors.club_info_address }}</span>
                        </div>
                      </div>
                      <template
                          v-if="fields.club_info && fields.club_info.club_state || fields.club_info && fields.club_info.club_state_code">
                        <div class="col-md-6"
                             v-if="clubForm.country == 'USA' && fields.club_info && fields.club_info.club_state_code">
                          <div class="form-group">
                            <label for="club_state" class="form-label">State <sup class="text-danger" v-if="fields.club_info.club_state.required">*</sup></label>
                            <select class="form-control" id="club_state" name="clubForm.state_code"
                                    v-model="clubForm.state_code">
                              <option value="">Select</option>
                              <option v-for="listOfState in listOfStates" :key="listOfState.state"
                                      :value="listOfState.state_code"
                                      :selected="listOfState.state_code == clubForm.state_code">{{ listOfState.state }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.club_info_state_code }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-else>
                          <div class="form-group">
                            <label for="club_state_code" class="form-label">Other State</label>
                            <input type="text" class="form-control" id="club_state_code" maxlength="45"
                                   v-model="clubForm.state" name="clubForm.state">
                            <span class="text-sm text-danger">{{ errors.club_info_state }}</span>
                          </div>
                        </div>
                      </template>
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_city">
                        <div class="form-group">
                          <label for="club_city" class="form-label">City <sup class="text-danger" v-if="fields.club_info.club_city.required">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="club_city" name="clubForm.city"
                                 v-model="clubForm.city">
                          <span class="text-sm text-danger">{{ errors.club_info_city }}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_zip">
                        <div class="form-group">
                          <label for="club_zip" class="form-label">Zip/Postal Code <sup class="text-danger" v-if="fields.club_info.club_zip.required">*</sup></label>
                          <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="club_zip" name="clubForm.zip"
                                 v-model="clubForm.zip">
                          <span class="text-sm text-danger">{{ errors.club_info_zip }}</span>
                        </div>
                      </div>
                      <!--- Shooting --->
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_visible_type">
                        <div class="form-group">
                          <label for="club_visible_type"
                                 class="control-label">{{ fields.club_info.club_visible_type.label || 'Club Type' }}<sup
                              class="text-danger" v-if="fields.club_info.club_visible_type.required">*</sup></label>
                          <select name="clubForm.club_visible_type" id="club_visible_type" class="form-control"
                                  v-model="clubForm.visibility_type">
                            <option value="">Select</option>
                            <option v-for="(item, k) in fields.club_info.club_visible_type.choices"
                                    :key="'club_visible_type'+k">{{ item }}
                            </option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.club_visible_type }}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_type">
                        <div class="form-group">
                          <label for="club_type"
                                 class="control-label">{{ fields.club_info.club_type.label || 'Club Type' }}<sup
                              class="text-danger" v-if="fields.club_info.club_type.required">*</sup></label>
                          <Select2 v-if="fields.club_info.club_type.multiple" v-model="clubForm.club_type"
                                   name="form.club_type" id="club_type" :options="fields.club_info.club_type.choices"
                                   :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"/>
                          <select name="clubForm.club_type" id="club_type" class="form-control"
                                  v-model="clubForm.club_type" v-else>
                            <option value="">Select</option>
                            <option v-for="(item, k) in fields.club_info.club_type.choices" :key="'club_type'+k">{{
                                item
                              }}
                            </option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.club_type }}</span>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="fields.club_info && fields.club_info.club_discipilines">
                        <div class="form-group discipilineitem">
                          <label for=""
                                 class="form-label">{{ fields.club_info.club_discipilines.label || 'Club Disciplines' }}<sup
                              class="text-danger" v-if="fields.club_info.club_discipilines">*</sup></label>
                          <div class="row mb-2">
                            <div class="col-md-12">
                              <div class="form-check">
                                <input class="form-check-input club_discipilines_check" type="checkbox"
                                       id="clubdisciplines-all"
                                       name="club_disciplines_all" @change="triggerSelectDisciplinesAll">
                                <label class="d-flex mr-1" for="clubdisciplines-all">Select all that apply</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4" v-for="(name, id) in clubDisciplinesList" :key="'cd_'+id">
                              <div class="form-check">
                                <input class="form-check-input club_discipilines_check" type="checkbox"
                                       :id="'clubdisciplines-'+id" name="clubForm.club_disciplines" :value="id"
                                       v-model="clubForm.club_disciplines">
                                <label class="d-flex mr-1" :for="'clubdisciplines-'+id">{{ name }}</label>
                              </div>
                            </div>
                          </div>
                          <span class="text-sm text-danger">{{ errors.club_discipilines }}</span>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="fields.club_info && fields.club_info.club_admin_member_id">
                        <div class="form-group">
                          <label for="clubname" class="form-label">{{ fields.club_info.club_admin_member_id.label }}
                            <sup class="text-danger"
                                 v-if="fields.club_info.club_admin_member_id.required">*</sup></label>
                          <div class="input-group">
                            <input type="number" class="form-control" maxlength="45" id="club_admin_member_id"
                                   name="clubForm.club_admin_member_id" v-model="clubForm.club_admin_member_id"
                                   @keyup="clearAdminName">
                            <div class="input-group-append">
                              <button class="btn btn-outline-primary" type="button" @click="searchMember"><i
                                  class="fa fa-search"></i> Search
                              </button>
                            </div>
                          </div>
                          <span class="text-sm text-danger">{{ errors.club_admin_member_id }}</span>
                        </div>
                        <label class="text-danger" v-if="clubAdminSearch.searched && clubForm.club_admin_name == ''">No
                          Member Found with Member ID #{{ clubAdminSearch.memberIDSearched }}</label>
                      </div>
                      <div class="col-md-12" v-if="fields.club_info && fields.club_info.club_admin_name">
                        <div class="form-group">
                          <label for="clubname" class="form-label">{{ fields.club_info.club_admin_name.label }} <sup
                              class="text-danger" v-if="fields.club_info.club_admin_name.required">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="clubname"
                                 aria-describedby="club_admin_name" name="clubForm.club_admin_name"
                                 v-model="clubForm.club_admin_name"
                                 :readonly="fields.club_info.club_admin_name.readonly"
                                 :placeholder="fields.club_info.club_admin_name.placeholder">
                          <span class="text-sm text-danger">{{ errors.club_admin_name }}</span>
                        </div>
                      </div>
                      <!--- Shooting --->
                    </div>
                  </div>
                </b-modal>
                <b-modal id="edit-club-contact" size="xl" ok-title="Update" @ok="updateClubContact" centered
                         title="Contact Information">
                  <div class="form-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-12" v-if="fields.club_info && fields.club_contact.email">
                            <div class="form-group">
                              <label for="email" class="form-label">{{ fields.club_contact.email.label }} <sup
                                  class="text-danger" v-if="fields.club_contact.email.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" id="email"
                                     v-model="contactForm.email" name="form.email">
                              <span class="text-sm text-danger">{{ errors.email }}</span>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="fields.club_info && fields.club_contact.retype_email">
                            <div class="form-group">
                              <label for="retype_email" class="form-label">{{ fields.club_contact.retype_email.label }}
                                <sup class="text-danger"
                                     v-if="fields.club_contact.retype_email.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" id="retype_email"
                                     v-model="contactForm.retype_email" name="form.retype_email">
                              <span class="text-sm text-danger">{{ errors.retype_email }}</span>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="fields.club_info && fields.club_contact.mobilephone">
                            <div class="form-group ">
                              <div class="phone-nu-wrapper">
                                <label for="country_code_h" class="form-label">{{
                                    fields.club_contact.mobilephone.label
                                  }} <sup class="text-danger"
                                          v-if="fields.club_contact.mobilephone.required">*</sup></label>
                                <vue-tel-input v-model="contactForm.phone_1" ref="phone" @validate="validateTelinput"
                                               v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                <span class="text-sm text-danger">{{ errors.mobilephone }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-12" v-if="fields.club_info && fields.club_contact.club_address">
                            <div class="form-group">
                              <label for="firstname" class="form-label">{{ fields.club_contact.club_address.label }}
                                <sup class="text-danger"
                                     v-if="fields.club_contact.club_address.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="100" id="club_address"
                                     v-model="contactForm.address" name="form.club_address">
                              <span class="text-sm text-danger">{{ errors.club_address }}</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="fields.club_info && fields.club_contact.club_city">
                            <div class="form-group">
                              <label for="club_city" class="form-label">{{ fields.club_contact.club_city.label }} <sup
                                  class="text-danger" v-if="fields.club_contact.club_city.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" id="club_city"
                                     v-model="contactForm.city" name="form.club_city">
                              <span class="text-sm text-danger">{{ errors.club_city }}</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="fields.club_info && fields.club_contact.club_state">
                            <div class="form-group">
                              <label for="club_state" class="form-label">{{ fields.club_contact.club_state.label }} <sup
                                  class="text-danger" v-if="fields.club_contact.club_state.required">*</sup></label>
                              <select class="form-control" id="club_state" v-model="contactForm.state_code"
                                      name="form.club_state">
                                <option value="">Select</option>
                                <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                        :value="listOfState.state_code">{{ listOfState.state }}
                                </option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.club_state }}</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="fields.club_info && fields.club_contact.club_zip">
                            <div class="form-group">
                              <label for="club_zip" class="form-label">{{ fields.club_contact.club_zip.label }} <sup
                                  class="text-danger" v-if="fields.club_contact.club_zip.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="5" id="club_zip"
                                     v-model="contactForm.zip" name="form.club_zip">
                              <span class="text-sm text-danger">{{ errors.club_zip }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-md-12" v-if="fields.club_info && fields.club_contact.club_physical_address">
                            <div class="form-group">
                              <label for="club_physical_address"
                                     class="form-label">{{ fields.club_contact.club_physical_address.label }} <sup
                                  class="text-danger" v-if="fields.club_contact.club_physical_address.required">*</sup></label>
                              <div class="form-check mt-2 mb-3"
                                   v-if="fields.club_contact.club_physical_address.same_check">
                                <input type="checkbox" id="same_as_mailing_address" name="form.same_as_mailing_address"
                                       class="form-check-input" value="1" v-model="contactForm.same_as_mailing_address"
                                       @change="changeSameAddress">
                                <label for="same_as_mailing_address" class="d-flex mr-1">Same as Mailing Address</label>
                              </div>
                              <input type="text" class="form-control" maxlength="100" id="club_physical_address"
                                     v-model="contactForm.physical_address" name="form.club_physical_address"
                                     :readonly="contactForm.same_as_mailing_address">
                              <span class="text-sm text-danger">{{ errors.club_physical_address }}</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="fields.club_info && fields.club_contact.club_physical_city">
                            <div class="form-group">
                              <label for="club_physical_city"
                                     class="form-label">{{ fields.club_contact.club_physical_city.label }} <sup
                                  class="text-danger"
                                  v-if="fields.club_contact.club_physical_city.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" id="club_physical_city"
                                     v-model="contactForm.physical_city" name="form.club_physical_city"
                                     :readonly="contactForm.same_as_mailing_address">
                              <span class="text-sm text-danger">{{ errors.club_physical_city }}</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="fields.club_info && fields.club_contact.club_physical_state">
                            <div class="form-group">
                              <label for="club_physical_state"
                                     class="form-label">{{ fields.club_contact.club_physical_state.label }} <sup
                                  class="text-danger"
                                  v-if="fields.club_contact.club_physical_state.required">*</sup></label>
                              <select class="form-control" id="club_physical_state"
                                      v-model="contactForm.physical_state_code" name="form.club_physical_state"
                                      :disabled="contactForm.same_as_mailing_address">
                                <option value="">Select</option>
                                <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                        :value="listOfState.state_code">{{ listOfState.state }}
                                </option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.club_physical_state }}</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="fields.club_info && fields.club_contact.club_physical_zip">
                            <div class="form-group">
                              <label for="club_physical_zip"
                                     class="form-label">{{ fields.club_contact.club_physical_zip.label }} <sup
                                  class="text-danger"
                                  v-if="fields.club_contact.club_physical_zip.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="15" id="club_physical_zip"
                                     v-model="contactForm.physical_zip" name="form.club_physical_zip"
                                     :readonly="contactForm.same_as_mailing_address">
                              <span class="text-sm text-danger">{{ errors.club_physical_zip }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>
                <b-modal v-if="fields.club_sanction_fields" ok-only ref="modal"
                         @ok="SanctionAgreementCheck"
                         @hidden="resetPurchasePractice"
                         ok-title="Update"
                         id="modal-club-sanction-edit" size="lg" centered
                         :title="fields.club_sanction_fields ? fields.club_sanction_fields.title : ''">
                  <div class="popupbody">
                    <div class="card club-info">
                      <!--                        <div class="card-header register-title">
                          <h3> {{ fields.club_sanction_fields.fields.title }}</h3>
                        </div>-->
                      <div class="card-body pr-4 pl-4 clubinfo">
                        <div class="row">
                          <div class="col-md-12">
                            <p dir="ltr"><span>An important benefit USA Water Ski &amp; Wake Sports (USA-WSWS) provides to its affiliated clubs is the ability to host and sanction practices and exhibitions. As part of practice/exhibition sanctioning, USA-WSWS provides General Liability and Participant Accident insurance coverage to clubs that adhere to the requirements outlined below:</span>
                            </p>
                            <ul class="grassrootul">
                              <li>
                                <span class="font-13 align-items-baseline"><i
                                    class="fas fa-chevron-right mt-2"></i><span>Organizing club must be a current USA-WSWS affiliated club for the calendar year in which they are sanctioning.</span></span>
                              </li>
                              <li>
                                <span class="font-13 align-items-baseline"><i
                                    class="fas fa-chevron-right mt-2"></i><span>Event site must be on file with USA-WSWS in advance of the event.</span></span>
                              </li>
                              <li>
                                <span class="font-13 align-items-baseline"><i
                                    class="fas fa-chevron-right mt-2"></i><span>from the organizing club must be present for the duration of the event.</span></span>
                              </li>
                              <li>
                                <span class="font-13 align-items-baseline"><i
                                    class="fas fa-chevron-right mt-2"></i><span>All participants must be current USA-WSWS Active or GrassRoots Active members or USA-WSWS Guest Members.</span></span>
                              </li>
                              <li>
                                <span class="font-13 align-items-baseline"><i
                                    class="fas fa-chevron-right mt-2"></i><span>All drivers towing skiers during sanctioned practices/exhibitions must be a current USA-WSWS Trained Driver or sport discipline-rated driver.</span></span>
                              </li>
                              <li>
                                <span class="font-13 align-items-baseline"><i
                                    class="fas fa-chevron-right mt-2"></i><span>A minimum of one USA-WSWS safety official with a rating of Coordinator, State, Regional, or National Safety must be present at all exhibitions and sanctioned practices where guest members are participating.</span></span>
                              </li>
                              <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i>Cost:<span>&nbsp;$50 (one-time annual fee for unlimited practices/exhibitions</span></span>
                              </li>
                            </ul>
                            <p><br></p>
                          </div>
                          <template v-if="fields.club_sanction_fields">
                            <div :class="[fields.club_sanction_fields.fields.sanction_types.colClass]"
                                 v-if="fields.club_sanction_fields.fields.sanction_types">
                              <h3 style="font-size: 20px;line-height: 25px;margin-bottom: 0;color: #05213e;font-weight: 700;font-style: italic;"
                                  :for="fields.club_sanction_fields.fields.sanction_types.label" class="">
                                {{ fields.club_sanction_fields.fields.sanction_types.label }}<sup
                                  v-if="fields.club_sanction_fields.fields.sanction_types.required">*</sup></h3><br>
                              <div class="form-group ml-4" :key="'sanction_types'+k"
                                   v-for="(choice,k) in fields.club_sanction_fields.fields.sanction_types.choices">
                                <div class="grossrootcheck">
                                  <input v-if="choice.id=='without_guest'" type="checkbox" class="form-check-input"
                                         :id="choice.id" name="clubForm.club_sanction_data.practise_without_guest"
                                         value="1" v-model="clubForm.club_sanction_data.practise_without_guest">
                                  <input v-if="choice.id=='with_guest'" type="checkbox" class="form-check-input"
                                         :id="choice.id" name="clubForm.club_sanction_data.practise_with_guest"
                                         value="1"
                                         v-model="clubForm.club_sanction_data.practise_with_guest">
                                  <input v-if="choice.id=='exhibition'" type="checkbox" class="form-check-input"
                                         :id="choice.id" name="clubForm.club_sanction_data.exhibition" value="1"
                                         v-model="clubForm.club_sanction_data.exhibition">
                                  <label class="form-check-label" :for="'sanction_types'+k">{{ choice.label }}</label>
                                </div>
                              </div>
                            </div>
                          </template>
                          <div
                              class="col-md-12 accordion  membership-type"
                              role="tablist">
                            <b-card no-body class="mb-1">
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'sanct_1'" variant="info">
                                  Sanction Acknowledgment
                                </b-button>
                              </b-card-header>
                              <b-collapse id="sanct_1" accordion="mtype-accordion" visible role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <div class="col-md-12">
                                      <p class="font-13"><span>Practice/Exhibition Acknowledgment</span></p>
                                      <p class="font-13"><span>What type of events will you be hosting (check all that apply)?</span>
                                      </p>
                                      <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                        <li v-if="clubForm.club_sanction_data.practise_without_guest == 1"
                                            aria-level="1" dir="ltr"
                                            style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                                          <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
                                              style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Practices (with only USA-WSWS members)</span>
                                          </p>
                                        </li>
                                        <li v-if="clubForm.club_sanction_data.practise_with_guest == 1" aria-level="1"
                                            dir="ltr"
                                            style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                                          <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
                                              style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Practices with guest members&nbsp;</span>
                                          </p>
                                        </li>
                                        <li v-if="clubForm.club_sanction_data.exhibition == 1" aria-level="1" dir="ltr"
                                            style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                                          <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
                                              style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Exhibitions (e.g., Ski Shows)</span>
                                          </p>
                                        </li>
                                      </ul>
                                      <p><br></p>
                                      <p class="font-13"><span>All club and team practices or exhibitions are considered &ldquo;sanctioned&rdquo; by USA Water Ski &amp; Wake Sports if&nbsp;</span><span
                                          class="font-13">ALL&nbsp;</span><span class="font-13">the below criteria are met.&nbsp;</span>
                                      </p>
                                      <p class="font-13"><span>I understand that for practice or exhibition sanctioning that:</span>
                                      </p>
                                      <ul class="grassrootul">
                                        <li>
                                          <span class="font-13 align-items-baseline"><i
                                              class="fas fa-chevron-right mt-2"></i><span>All participants must be current USA-WSWS Active or GrassRoots Active members or USA-WSWS Guest Members. All participants, or their parent/legal guardian, in the case of a minor, must have a signed Adult-Minor Annual Waiver on file with USA-WSWS prior to participating. &nbsp;Guest members are not eligible to jump over a ramp.</span></span>
                                        </li>
                                        <li>
                                          <span class="font-13 align-items-baseline"><i
                                              class="fas fa-chevron-right mt-2"></i><span>All drivers towing skiers during sanctioned practices/exhibitions must be a current USA-WSWS Trained Driver or sport discipline-rated driver. NSSA (show ski) club practices/exhibitions require exclusive use of NSSA-rated drivers.</span></span>
                                        </li>
                                        <li>
                                          <span class="font-13 align-items-baseline"><i
                                              class="fas fa-chevron-right mt-2"></i><span>A minimum of one USA-WSWS safety official with a rating of Coordinator, State, Regional, or National Safety must be present at all exhibitions and sanctioned practices where guest members are participating.</span></span>
                                        </li>
                                        <li>
                                          <span class="font-13 align-items-baseline"><i
                                              class="fas fa-chevron-right mt-2"></i><span>Club practices or exhibitions must take place during normal and reasonable water ski hours (typically from dusk until dawn). Practice/exhibition sanctioning does not constitute 24-hour on the water insurance coverage. For example, water ski activity at 1 a.m. would not be considered normal water ski hours and would not be deemed a sanctioned activity.</span></span>
                                        </li>
                                        <li>
                                          <span class="font-13 align-items-baseline"><i
                                              class="fas fa-chevron-right mt-2"></i><span>Practice/Exhibition sanctioning does not include Basic Skills Clinics or Learn to Ski events where instruction is taking place. Basic Skills Clinics need to be to be sanctioned as individual events and are not considered a sanctioned practice or exhibition.</span></span>
                                        </li>
                                      </ul>
                                      <div class="">
                                        <label class="form-check-label">
                                          <input type="checkbox" id="practise_agree" class="form-check-input" :value="1"
                                                 v-model="clubForm.club_sanction_data.practise_agree"
                                                 name="clubForm.club_sanction_data.practise_agree">
                                          <span class="font-13" :style="{ 'display': 'inline-block' }"
                                                v-if="fields.club_sanction_fields.fields.practise_agree"
                                                v-html="fields.club_sanction_fields.fields.practise_agree"></span>
                                        </label>
                                        <label>Name : <input type="text" v-on:keypress="isLetter($event)"
                                                             class="onlybor-bot" style="width: 40px"
                                                             id="practise_sign_name"
                                                             name="clubForm.club_sanction_data.practise_sign_name"
                                                             v-model="clubForm.club_sanction_data.practise_sign_name"></label><br>
                                        <label>Date : <input type="text" class="datepicks" disabled
                                                             :value="currentDate"></label>
                                        <span class="d-block text-sm text-danger">{{ errors.practise_agree }}</span>
                                        <span class="d-block text-sm text-danger">{{ errors.practise_sign_name }}</span>
                                      </div>
                                    </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button v-b-toggle.collapse-3 class="m-1 btn btn-info btn-block" variant="info">
                                  Event Sanctioning Agreement
                                </b-button>
                              </b-card-header>


                              <b-collapse visible id="collapse-3">
                                <b-card-body>
                                  <b-card-text>
                                    <div class="col-md-12">
                                      <p class="font-13"><span>This is a contract (the &ldquo;Agreement&rdquo;) between USA Water Ski &amp; Wake Sports (&ldquo;USA-WSWS:&rdquo;), a New Jersey not-for-profit corporation, the event promoter(s) or director(s) and all other persons or entities connected with the management and production of the Event as reflected in this Agreement and/or the Event sanction application and documents (who are referred to in this Agreement individually and collectively as the &ldquo;Local Organizing Club.&rdquo;). This Agreement is a part of the Sanction for the Event, which also includes the Local Organizing Club&rsquo;s application to sanction the Event and any other agreements between USA-WSWS and the Local Organizing Club relating to the Event, and may include activities on different dates as set forth in the sanction application and documents. Please read this Agreement, the sanction application and all other documents and agreements carefully before completing the sanction application process and executing this Agreement. This Agreement and all other sanction documents must be approved and executed by someone authorized to do so on behalf of the Local Organizing Club. By agreeing to these terms, the Local Organizing Club is agreeing that the Event shall meet the minimum event sanctioning requirements as outlined in this Agreement and all other sanction agreements and documents. In consideration of the mutual promises and terms of this Agreement, the adequacy of which is hereby acknowledged, USA-WSWS and the Local Organizing Club agree as follows:&nbsp;</span>
                                      </p>
                                      <ul class="grassrootul">
                                        <li><span>1. &nbsp;Event Administration. Local Organizing Club agrees, represents and warrants that Local Organizing Club shall:</span>
                                          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                            <li>
                                            <li><span class="font-13 align-items-baseline"><i
                                                class="fas fa-chevron-right mt-2"></i><span>Ensure that the Event complies with all USA-WSWS&rsquo;s safety requirements and guidelines, Rated Driver requirements, rules, regulations, policies, directives, decisions and all procedures set forth by USA-WSWS as are in effect at the time of the Event (see USA-WSWS website for further information);&nbsp;</span></span>
                                            </li>
                                            <li><span class="font-13 align-items-baseline"><i
                                                class="fas fa-chevron-right mt-2"></i><span>Collect, as trustee on behalf of USA-WSWS, all required USA-WSWS guest membership fees and annual membership fees, release forms and results (for tournaments only), and shall deliver those items to USA-WSWS within the time required by USA-WSWS policies or this Agreement;&nbsp;</span></span>
                                            </li>
                                            <li><span class="font-13 align-items-baseline"><i
                                                class="fas fa-chevron-right mt-2"></i><span>Reserve/obtain permission and/or permits from local jurisdiction(s) to use the course(s), facilities venues etc.;</span></span>
                                            </li>
                                            <li><span class="font-13 align-items-baseline"><i
                                                class="fas fa-chevron-right mt-2"></i><span>Conduct the Event in accordance with all applicable current competitive rules as specified in the applicable USA-WSWS sport discipline rulebook or International Water Ski and Wakeboard Federation (IWWF) rules;</span></span>
                                            </li>
                                            <li><span class="font-13 align-items-baseline"><i
                                                class="fas fa-chevron-right mt-2"></i><span>Accept full responsibility for the management and promotion of this Event and any parties it employs or recruits to assist with the management and production of said Event, including but not limited to, the supervision of paid and non-paid employees and volunteers. Local Organizing Club is solely responsible for complying with all applicable statutes, rules and regulations. Local Organizing Club shall provide to USA-WSWS all Event permits, certificates, contracts and other similar documents or agreements, upon request and within a reasonable time.&nbsp;</span></span>
                                            </li>
                                          </ol>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>2. Safety Guidelines. Local Organizing Club agrees to meet all safety requirements as specified in the USA-WSWS Official Safety Manual &amp; Policy Handbook or otherwise established by USA-WSWS as are in effect at the time of the Event (see USA-WSWS website for further information). &nbsp;Local Organizing Club acknowledges that safety considerations may require that additional precautions or measures be taken beyond the minimum requirements established by USA-WSWS, and Local Organizing Club agrees to determine (in its sole discretion) and implement reasonable measures to promote and conduct the event in a safe manner. Local Organizing Club shall appoint a Safety Director or Coordinator as required under applicable USA-WSWS rules or policies, in addition to such other personnel as may be required to carry out the event administration duties. Local Organizing Club shall not conduct the event if Local Organizing Club is not able to accept or provide for the performance of these responsibilities.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>3. Sanction Fee. Local Organizing Club shall promptly submit the sanction fee according to the fee schedule in the applicable USA-WSWS sport discipline rulebook. The appropriate sanction fee shall be submitted prior to sanction approval. In the event of a weather-related cancellation, a refund of the sanction fee shall be made to Local Organizing Club provided that written notification of cancellation of the Event is provided to USA-WSWS as required by this Agreement. A $25 administration fee and any applicable late fees are non-refundable in all cases.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>4. Financial Responsibilities. All outstanding debts to USA-WSWS, International Water Ski and Wakeboard Federation (IWWF) or any other USA-WSWS affiliates shall be paid prior to sanction approval. Local Organizing Club is financially responsible for all costs incurred in association with the Event.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>5. Sanction Applications. Local Organizing Club shall complete and submit all information needed to complete the Event sanction application and approval process in a timely manner. Local Organizing Club warrants that all of the minimum event sanctioning requirements for USA-WSWS shall be complied with, that all changes to an already submitted sanction application shall be submitted in writing and that all other changes shall be communicated to USA-WSWS prior to the start of the Event (with the exception of a weekend Event cancellation, in which case Local Organizing Club shall notify USA-WSWS of cancellation the next business day). Falsifying, concealing or misrepresenting information in the event sanction application to USA-WSWS may result in either voiding or reducing the applicable insurance coverage made available to Local Organizing Club with respect to the Event.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>6. Membership and Waiver and Release Requirements. Local Organizing Club shall confirm that all participants and volunteers in the Event are members in good standing with USA-WSWS and shall ensure that all participants and volunteers have completed the required USA-WSWS membership forms, signed the mandatory waivers and release forms, and paid the mandatory membership fees. For those persons who are not legally competent by virtue of age (i.e. a minor under eighteen (18) years of age) or mental incapacity (i.e. a mentally challenged person) to sign the required USA-WSWS membership and participant waiver forms, the Local Organizing Club shall ensure that a qualified parent or legal guardian completes and signs the required forms on behalf of such individuals before the Local Organizing Club allows these individuals to participate in the Event. For IWWF World Championship and World Cup events conducted in the United States, members of foreign federations are not required to become USA Water Ski members; however, all foreign federation members are required to sign the mandatory waiver and release forms.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>7. Insurance. As part of sanctioning, USA-WSWS provides General Liability, Umbrella Liability and Participant Accident coverage for the protection and benefit of its Event Organizers, clubs, associations, sport disciplines, divisions and individual members in connection with USA-WSWS sanctioned events. Please refer to the Insurance Fact Summary posted on the USA-WSWS website for a summary of the USA-WSWS insurance programs, for information about how to request certificates of insurance, and for instructions about how to report incidents and claims. Upon approval of the sanction application, certificates of insurance can be requested through USA-WSWS using the Certificate Request Form posted on the USA-WSWS website. Certificates of insurance will be issued upon receipt of the completed form along with the appropriate payment. USA-WSWS is not acting as an insurer or insurance agent. Local Organizing Club shall be bound by the written terms of the insurance policy and certificates of insurance. The insurance provided by USA-WSWS as part of sanctioning is not all inclusive, and coverage is subject to the terms, conditions and exclusions of the policies, including communicable disease. Local Organizing Club is solely responsible for and shall determine the necessity of purchasing additional insurance policies and/or limits of coverage appropriate for the Event. Insurance is a benefit of sanctioning, and exclusion, separation or division cannot be made for either.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>Any non-traditional events, or any ancillary activities, functions or events in association with the Event, shall not be covered by USA-WSWS insurance unless such non-traditional events, activities, functions or events have been disclosed and prior approval has been granted by USA-WSWS (and the applicable insurance underwriters, as appropriate). As an example, hang gliding and similar aerial type acts within a show ski show are not approved for USA Water Ski insurance coverage; see NSSA&rsquo;s specific rules addressing these activities. Local Organizing Club is solely responsible for procuring any other insurance, and for costs and liabilities not covered by USA-WSWS insurance.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>8. Post Event Materials. Local Organizing Club shall ensure that all post -Event reports, results and other materials are electronically posted or submitted to USA Water Ski within the specified deadlines. Future sanctions will be withheld from those Local Organizing Clubs with ANY and ALL outstanding paperwork. These LOCs will be allowed to submit future sanction applications, but the tournament will not be published on the website nor sanction approval granted until all outstanding post-tournament reports are received from prior Events. Post event materials include, but are not limited to, collected USA-WSWS membership monies, completed USA-WSWS membership forms, signed participant waiver forms, results (with the exception of clinics, practices and exhibitions that are excluded from submitting results), incident report forms, USA-WSWS officials&rsquo; records, Safe Sport MAAPP compliance documents and safety director reports.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>9. Incident Reporting/Management. Local Organizing Club shall promptly report all incidents and claims in accordance with the guidelines set forth in the Incident Reporting Procedures and Medical Claim Filing Instructions and forms posted on the USA-WSWS website.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>10. Legal Claims and Event Records. Local Organizing Club hereby covenants and agrees to cooperate in good faith with USA-WSWS and its agents in the event of any claims or other legal actions arising out of the Event; to maintain all business records for the Event for at least seven (7) years after the Event; and to provide USAWS access to such records. Business records include, but are not limited to, participant waiver and release forms (except for waiver forms signed electronically through USA-WSWS&rsquo; online registration system), facility use agreements, agency permits/documents, vendor certificates of insurance, event participant census data, and accounting records. All records of incidents at the Event including incident reports and police reports shall be kept for a minimum of seven (7) years.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>11. Advertising. Included in the cost of sanctioning, USA-WSWS shall advertise the Event on its website calendar. Additionally, Local Organizing Club may display USA-WSWS banners and may advertise that the Event is sanctioned by USA-WSWS upon receiving written notice of approval from USA-WSWS.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>12. Limited Use of USA-WSWS Trademark. Local Organizing Club shall not use the USA-WSWS logo in such a way as to imply that USA-WSWS is a sponsor.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>13. Media Release. Local Organizing Club hereby agrees to give USA-WSWS and its authorized agents the right to use the Local Organizing Club and Event names on any written, photographic, audio or videotaped materials relating to the Event for advertising, promotional, commercial, educational or other purposes, and Local Organizing Club hereby waives any right to additional consideration or compensation with respect to any such use. Local Organizing Club is prohibited from making any public/formal statements regarding an incident at the Event, but instead the Local Organizing Club should direct questions from the media or others to USA-WSWS&rsquo;s Executive Director or to USA-WSWS&rsquo;s Communications Director for comment.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>14. Drug Testing. Local Organizing Club hereby acknowledges that the Event may be selected for, drug testing by USA-WSWS, IWWF, USOC, or United States Anti-Doping Agency (USADA). In such case, Local Organizing Club shall permit drug testing to proceed and shall comply with the Doping Control Event Packet and other protocol developed by USADA. Through USA-WSWS&rsquo;s agreement with the USOC, drug testing rights are given to USADA. Local Organizing Club shall not conduct any drug testing without the prior written approval of USAWS and supervision of USADA.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13"><i class="fas fa-chevron-right mt-2"></i><span
                                            class="insdiespan">15. Safe Sport MAAPP Policy- Local Organizing Club acknowledges that it has read and will follow the Safe Sport MAAPP (<a
                                            target="_blank"
                                            href="https://www.usawaterski.org/graphics/downloads/USAWSWSMAAPPolicies.pdf"
                                            style="text-decoration:none;">Minor Athlete Abuse Prevention Polices</a>) and acknowledges the MAAPP protocol found in the&nbsp;<a
                                            target="_blank"
                                            href="http://www.usawaterski.org/graphics/downloads/USAWSWSSafeSportHandbook.pdf"
                                            style="text-decoration:none;">USA-WSWS Safe Sport Handbook</a>&nbsp;for reporting suspected or alleged MAAPP violations. Local Organizing Club acknowledges that is has reviewed the USA-WSWS&nbsp;<a
                                            target="_blank"
                                            href="https://www.teamusa.org/-/media/USA_Waterski/Membership-Docs/USAWSWSSuspendedMembers.pdf"
                                            style="text-decoration:none;">Suspended Members List</a>&nbsp;and will actively prevent any individual on the list from participating as a registered athlete, official, or volunteer at the event. &nbsp;Future sanctions can be withheld from those LOCs for failure to implement and comply with protocol outlined in the Safe Sport MAAPP and SafeSport Handbook.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>16. Right to Terminate. USA-WSWS may terminate the Event&rsquo;s sanction at any time if Local Organizing Club fails to comply with any of the terms of this Agreement or other USA-WSWS requirements. If USA-WSWS terminates Event&rsquo;s sanction, Local Organizing Club shall be reimbursed for the sanction fee, less late fees, less any USAWS expenses and less any outstanding monies owed to USA-WSWS or its affiliates by Local Organizing Club (collectively, &ldquo;USA-WSWS Expenses&rdquo;).&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>17. Probation/Sanction Refusal. USA-WSWS has the right to impose probation or other penalties of sanction if there is reasonable evidence of Local Organizing Club&rsquo;s failure to meet minimum sanctioning requirements, or for outstanding monies owed to USA-WSWS or its affiliates, or for safety concerns, or for any other reason(s) that may apply.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>18. Indemnification. Local Organizing Club and the entities responsible for the management and promotion of the Event, hereby, jointly and severally, indemnify and agree to defend and hold harmless USA-WSWS, its Associations, Sport Disciplines and Divisions, and each of their respective officers, directors, employees, volunteers and agents (collectively, the &ldquo;USA-WSWS Parties&rdquo;) for and against any and all claims, liabilities, demands, obligations, damages, costs of litigation, attorney&rsquo;s fees or other expenses related to or arising out of the management or promotion of any Event that does not meet the minimum sanctioning requirements outlined in this Agreement. Local Organizing Club HEREBY WAIVES, RELEASES, and FOREVER DISCHARGES the USA-WSWS Parties from any and all claims, causes of action, damages, and losses arising out of Local Organizing Club&apos;s breach or failure to abide by any part of this Sanction Agreement.</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>19. No Agency. Local Organizing Club is not the agent of USA-WSWS. There is no intent to create an agency, partnership or joint venture relationship between USA-WSWS and Local Organizing Club of the Event. It is understood that Local Organizing Club is not employed by USA-WSWS and therefore USA-WSWS shall not provide worker&rsquo;s compensation coverage or any other benefits that would be provided to USA-WSWS employees.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>20. Modifications. This Agreement represents the entire agreement between both parties with respect to the sanctioning of the Event. No modification or amendment of this Agreement or waiver of any provision of this Agreement shall be valid unless it is set forth in writing and signed by all parties to this Agreement. Local Organizing Club shall not modify, nor shall Local Organizing Club allow anyone, including participants, to modify any other USA-WSWS documents, including, but not limited to the athlete&rsquo;s participant waiver and release form.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>21. Severability. If any provision of this Agreement shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>22. Headings. The sections and other headings contained in this Agreement are intended solely for convenience of reference and are not intended to be part of or affect the meaning or interpretation of this Agreement.&nbsp;</span></span>
                                        </li>
                                        <li><span class="font-13 align-items-baseline"><i
                                            class="fas fa-chevron-right mt-2"></i><span>23. Other. Local Organizing Club voluntarily agrees to these terms and acknowledges that it understands and accepts of all terms and conditions of this Agreement. This Agreement and its benefits are not transferable. WHEREFORE, intending to be legally bound by the terms hereof, the Local Organizing Club agrees to the terms of this Agreement on the date as indicated by his/her/its approval of this Agreement.&nbsp;</span></span>
                                        </li>
                                      </ul>


                                      <div class="">
                                        <label class="form-check-label">
                                          <input type="checkbox" id="sanction_exhib_agree" class="form-check-input "
                                                 :value="1" v-model="clubForm.club_sanction_data.exhibition_agree"
                                                 name="clubForm.club_sanction_data.exhibition_agree">
                                          <span :style="{ 'display': 'inline-block' }"
                                                v-if="fields.club_sanction_fields.fields.exhibition_agree_i"
                                                v-html="fields.club_sanction_fields.fields.exhibition_agree_i"></span>
                                          <input class="onlybor-bot" type="text" v-on:keypress="isLetter($event)"
                                                 style="width: 40px" id="club_sanction_exhibition_sign_name"
                                                 name="clubForm.club_sanction_data.exhibition_sign_name"
                                                 v-model="clubForm.club_sanction_data.exhibition_sign_name">
                                          <span :style="{ 'display': 'inline-block' }"
                                                v-if="fields.club_sanction_fields.fields.exhibition_agree"
                                                v-html="fields.club_sanction_fields.fields.exhibition_agree"></span><input
                                            type="text" class="datepicks" disabled :value="currentDate">
                                        </label>
                                        <span class="d-block text-sm text-danger">{{ errors.exhibition_agree }}</span>
                                        <span class="d-block text-sm text-danger">{{
                                            errors.exhibition_sign_name
                                          }}</span>
                                      </div>
                                    </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="text-right topspace">
                          <span class="text-sm text-danger"><br>{{ clubSancError }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-body card">
                      <div class="row">
                        <div class="col-md-12">
                          <h3>Membership Type</h3>
                          <span class="Individual">{{ membership.type || 'Club' }} Membership</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="card-body card">
                      <div class="row">
                        <div class="col-md-9">
                          <h3>Club Information </h3>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="modal-btn">
                            <b-button v-b-modal.modal-club-edit @click="fillClubData"><img
                                v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                            </b-button>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.clubname">
                          <label>Club Name</label>
                          <span>{{ clubInfoData.name }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.primary_club_type">
                          <label>Primary Club Type</label>
                          <span>{{ clubType[clubInfoData.type] }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.secondary_club_type">
                          <label>Secondary Club Type</label>
                          <span>{{ clubType[clubInfoData.secondary_type] }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_website">
                          <label>Club Website</label>
                          <span>{{ clubInfoData.website }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.include_directory">
                          <label>Include in USA-WSWS online club directory</label>
                          <span>{{ clubInfoData.exclude != 0 ? 'No' : 'Yes' }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.email">
                          <label>Club E-Mail</label>
                          <span>{{ clubInfoData.email }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_address">
                          <label>Club Mailing Address</label>
                          <span>{{ clubInfoData.address }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_state">
                          <label>State</label>
                          <span v-if="clubInfoData.state_code">{{ clubInfoData.state_code }}</span>
                          <span v-else>N/A</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_city">
                          <label>City</label>
                          <span>{{ clubInfoData.city }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_zip">
                          <label>Zip/Postal Code</label>
                          <span>{{ clubInfoData.zip }}</span>
                        </div>
                        <!-- Shooting Extra Fields -->
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_visible_type">
                          <label>{{ fields.club_info.club_visible_type.label || 'Type' }}</label>
                          <span>{{ clubInfoData.visibility_type }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_type">
                          <label>{{ fields.club_info.club_type.label || 'Club Type' }}</label>
                          <span>{{ clubInfoData.club_type }}</span>
                        </div>
                        <div class="col-md-12" v-if="fields.club_info && fields.club_info.club_discipilines">
                          <label>{{ fields.club_info.club_discipilines.label || 'Club Disciplines' }}</label>
                          <ul v-if="clubInfoData.club_disciplines">
                            <li v-for="(item, k) in clubInfoData.club_disciplines" :key="'cb-'+k">
                              {{ clubDisciplinesList[item] }}
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6"
                             v-if="fields.club_info && fields.club_info.club_admin_member_id && clubInfoData.club_admin_member_id">
                          <label>Club Admin Member ID</label>
                          <span>{{ clubInfoData.club_admin_member_id }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_admin_name">
                          <label>Club Admin Name</label>
                          <span>{{ clubInfoData.club_admin_name }}</span>
                        </div>
                        <!-- Shooting Extra Fields -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-body card" v-if="fields.club_contact && fields.club_contact.email">
                      <div class="row">
                        <div class="col-md-9">
                          <h3>Contact Information</h3>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="modal-btn">
                            <b-button v-b-modal.edit-club-contact @click="fillContactData">
                              <img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                            </b-button>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label>E-Mail</label>
                          <span>{{ clubInfoData.email }}</span>
                          <hr>
                        </div>
                        <div class="col-md-12">
                          <label>Mailing Address</label>
                          <span>{{ clubInfoData.primary_contact.address1 }}</span>
                        </div>
                        <div class="col-md-4">
                          <label>City</label>
                          <span>{{ clubInfoData.primary_contact.city }}</span>
                        </div>
                        <div class="col-md-4">
                          <label>State</label>
                          <span>{{
                              clubInfoData.primary_contact.state || clubInfoData.primary_contact.state_code
                            }}</span>
                        </div>
                        <!-- <div class="col-md-4">
                                            <label>Country</label>
                                        <span>{{ clubInfoData.country }}</span>
                                    </div> -->
                        <div class="col-md-4">
                          <label>Zip/Postal Code</label>
                          <span>{{ clubInfoData.primary_contact.zip }}</span>
                        </div>
                      </div>
                      <div class="row mt-3" v-if="clubInfoData.primary_contact.physical_address">
                        <div class="col-md-12">
                          <hr class="mt-0">
                          <label>Physical Address</label>
                          <span>{{ clubInfoData.primary_contact.physical_address }}</span>
                        </div>
                        <div class="col-md-4">
                          <label>City</label>
                          <span>{{ clubInfoData.primary_contact.physical_city || 'N/A' }}</span>
                        </div>
                        <div class="col-md-4">
                          <label>State</label>
                          <span>{{
                              clubInfoData.primary_contact.physical_state || clubInfoData.primary_contact.physical_state_code || 'N/A'
                            }}</span>
                        </div>
                        <!-- <div class="col-md-4">
                                            <label>Country</label>
                                        <span>{{ clubInfoData.country || clubInfoData.primary_contact.country || 'N/A' }}</span>
                                    </div> -->
                        <div class="col-md-4">
                          <label>Zip/Postal Code</label>
                          <span>{{ clubInfoData.primary_contact.physical_zip || 'N/A' }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <hr class="mt-0">
                          <label>Phone Number</label>
                          <span>{{ clubInfoData.primary_contact.phone_1 }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="card-body card" v-if="fields.club_contact && fields.club_contact.search_by_member">
                      <div class="row">
                        <div class="col-md-9">
                          <h3>Primary Contact Information</h3>
                        </div>
                        <div class="col-md-3 text-right">
                          <div class="modal-btn">
                            <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('0')">
                              <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                            </b-button>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="clubInfo.primary_contact != null">
                        <div class="col-md-6 ">
                          <div class="form-group">
                            <label>Member#</label>
                            <span v-if="clubInfo.primary_contact != null">{{
                                clubInfo.primary_contact.membership_id
                              }}</span>
                            <span v-else>N/A</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>First Name</label>
                            <span v-if="clubInfo.primary_contact != null">{{
                                clubInfo.primary_contact.first_name
                              }}</span>
                            <span v-else>N/A</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Last Name</label>
                            <span v-if="clubInfo.primary_contact != null">{{
                                clubInfo.primary_contact.last_name
                              }}</span>
                            <span v-else>N/A</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>City</label>
                            <span v-if="clubInfo.primary_contact != null">{{ clubInfo.primary_contact.city }}</span>
                            <span v-else>N/A</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>State</label>
                            <span v-if="clubInfo.primary_contact != null">{{
                                clubInfo.primary_contact.state_code
                              }}</span>
                            <span v-else>N/A</span>
                          </div>
                        </div>
                        <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Country</label>
                                            <span v-if="clubInfo.primary_contact != null">{{ clubInfo.primary_contact.country }}</span>
                                            <span v-else>N/A</span>
                                        </div>
                                    </div> -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Zip/Postal Code</label>
                            <span v-if="clubInfo.primary_contact != null">{{ clubInfo.primary_contact.zip }}</span>
                            <span v-else>N/A</span>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Membership Status <span class="d-inline ml-2"
                                                                              v-if="clubInfo.primary_contact.member.status_text"
                                                                              v-b-tooltip.hover
                                                                              :title="clubInfo.primary_contact.member.membership_status_info"><img
                                v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                style="width:20px;"/></span></label>
                            <span class="text-success" v-if="clubInfo.primary_contact.member.status == 1"><img
                                class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{
                                clubInfo.primary_contact.member.status_text
                              }} (Expires {{ clubInfo.primary_contact.member.valid_thru  | formatDob }})
                             </span>
                            <span class="text-warning" v-else-if="clubInfo.primary_contact.member.status >=7"> <img
                                class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{
                                clubInfo.primary_contact.member.status_text
                              }}</span>
                            <span class="text-danger" v-else> <img style="width:18px;" class="mr-1"
                                                                   src="/dist/img/profile/deactivated.png"> {{
                                clubInfo.primary_contact.member.status_text
                              }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="clubInfo.primary_contact.member.member_type_id != 2">
                          <div class="form-group">
                            <label class="form-label">Waiver Status</label>
                            <span class="text-success" v-if="clubInfo.primary_contact.member.waiver_status == 1"><img
                                class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                            <span class="text-warning" v-else> <img class="mr-1"
                                                                    src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                          </div>
                        </div>
                        <template v-if="subdomain == 'waterski'">
                          <div class="col-md-6">
                            <div class="form-group"
                                 v-if="clubInfo.primary_contact.member.safe_sport_screening_compliance == true && clubInfo.primary_contact.member.safe_sport !=null">
                              <label class="form-label">Safe Sport Training Status</label>
                              <span class="text-success"
                                    v-if="clubInfo.primary_contact.member.safe_sport.safe_sport_status_text == 'Complete'"><img
                                  class="mr-1"
                                  src="/dist/img/profile/ico-status-current.png"> {{
                                  clubInfo.primary_contact.member.safe_sport.safe_sport_status_text
                                }} (Expires {{
                                  clubInfo.primary_contact.member.safe_sport.expiration
                                }})</span>
                              <span class="text-warning" v-else><img class="mr-1"
                                                                     src="/dist/img/profile/ico-status-pending.png"> {{
                                  clubInfo.primary_contact.member.safe_sport.safe_sport_status_text || "N/A"
                                }}</span>
                            </div>
                            <div v-else>
                              <div class="form-group">
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="clubInfo.primary_contact.member.background_screening">
                            <div class="form-group">
                              <label class="form-label">Background Screen
                                <span v-if="clubInfo.primary_contact.member.background_mvr_review != null" class="d-inline">/ MVR</span>
                                Status <span class="d-inline ml-2"
                                             v-if="clubInfo.primary_contact.member.background_screening.background_screening_info"
                                             v-b-tooltip.hover
                                             :title="clubInfo.primary_contact.member.background_screening.background_screening_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                    alt="tooltipicon"/></span></label>
                              <span class="text-success d-inline"
                                    v-if="clubInfo.primary_contact.member.background_screening.status == 2"><img
                                  class="mr-1"
                                  src="/dist/img/profile/ico-status-current.png"> {{
                                  clubInfo.primary_contact.member.background_screening.background_screening_status_text || "N/A"
                                }}</span>
                              <span class="text-warning d-inline" v-else><img class="mr-1"
                                                                     src="/dist/img/profile/ico-status-pending.png"> {{
                                  clubInfo.primary_contact.member.background_screening.background_screening_status_text || "N/A"
                                }}</span>
                              <span class="text-success d-inline"
                                    v-if="clubInfo.primary_contact.member.background_mvr_review != null && clubInfo.primary_contact.member.background_mvr_review.status == 2"> / <img
                                  class="mr-1"
                                  src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.primary_contact.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                              <span class="text-warning d-inline"
                                    v-else-if="clubInfo.primary_contact.member.background_mvr_review != null"> / <img
                                  class="mr-1"
                                  src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.primary_contact.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                              <span class="text-warning d-inline"
                                    v-else-if="clubInfo.primary_contact.member.background_mvr_review != null"> / Not Started</span>
                            </div>
                          </div>
                          <div class="col-md-4" v-else>
                            <div class="form-group">
                              <label class="form-label">Background Screen Status</label>
                              <span class="text-warning">N/A</span>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row" v-if="clubInfo.primary_contact == null">
                        <div class="col-md-12 text-danger">
                          <p class="text-danger">
                            Primary Contact Information is required
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-modal ok-only
                           ok-title="Add Site Information"
                           ref="modal"
                           @show="resetModalClubSite"
                           @hidden="resetModalClubSite"
                           @ok="handleOkClubSite" id="modal-club-address" size="lg" centered title="Site information">
                    <form ref="form" @submit.stop.prevent="handleOkClubSite">
                      <div class="popupbody row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="school" class="form-label">Specific site/body of water of all club activities <i
                                class="text-danger">*</i></label>
                            <input type="text" class="form-control" maxlength="45" id="school" aria-describedby="school"
                                   placeholder="Enter Specific site" name="form.school" v-model="address.school">
                            <span class="text-sm text-danger">{{ errors.school }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-check mt-4">
                            <input type="checkbox" id="membership_sign_social" name="form.membership_sign_social"
                                   class="form-check-input" v-model="address.is_primary" value="1">
                            <label for="membership_sign_social" class="form-check-label font-13">This site is the clubs
                              primary/home ski site.</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="address" class="form-label">Physical Address </label>
                            <input type="text" class="form-control" maxlength="45" id="address" name="address.address"
                                   v-model="address.address">
                            <!--<span class="text-sm text-danger">{{ errors.address }}</span>-->
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="address" class="form-label">Address 2 </label>
                            <input type="text" class="form-control" maxlength="45" id="address2" name="address.address2"
                                   v-model="address.address2">
                            <!--<span class="text-sm text-danger">{{ errors.address }}</span>-->
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="city" class="form-label">City <i class="text-danger">*</i></label>
                            <input type="text" class="form-control" maxlength="45" id="city" name="form.city"
                                   v-model="address.city">
                            <span class="text-sm text-danger">{{ errors.city }}</span>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="state" class="form-label">State <i class="text-danger">*</i></label>
                            <select class="form-control" id="state" name="form.state_code" v-model="address.state_code">
                              <option value="">Select</option>
                              <option v-for="listOfState in listOfStates" :key="listOfState.state"
                                      :value="listOfState.state_code">{{ listOfState.state }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.state_code }}</span>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="zip" class="form-label">Zip/Postal Code <i class="text-danger">*</i></label>
                            <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip" name="form.zip"
                                   v-model="address.zip">
                            <span class="text-sm text-danger">{{ errors.zip }}</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                </div>
                <div class="row profile-body" v-if="fields.club_officer && fields.club_officer.secretary  && eligibleForPurchaseSanction ==1">
                  <div class="col-md-12">
                    <div class="card" id="exnodiv">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>{{ fields.club_sanction_fields.title || '' }}</h3>
                        </div>
                        <div class="modal-btn" v-if="clubInfoData.club_sanction_data != null">
                          <b-button v-b-modal.modal-club-sanction-edit style="width: 100px">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                          </b-button>
                        </div>
                      </div>
                      <p class="pl-3 mb-0 mt-2 font-12">{{ fields.club_sanction_fields.description }}</p>
                      <div class="card-body">
                        <div v-if=" eligibleForPurchaseSanction == 1 && practice_and_sanction == false" class="ml-3">
                          <label class="">Would you like to purchase practice/exhibition sanctioning?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showPurchasePractice()" value="yes" class="form-check-input"
                                     v-model="practiceExbhition" id="exyes" name="practiceExbhition" :disabled="!sanctionFee">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showPurchasePractice()" value="no" class="form-check-input"
                                     v-model="practiceExbhition" id="exno" name="practiceExbhition">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="exhibitionData">{{ practiceExbhitionError }}</span>
                          <p class="text-sm text-danger" v-if="!sanctionFee">Practice/Exhibition Sanctioning purchase is disabled now.</p>
                        </div>
                        <div v-else class="row">
                          <div class="col-md-12 d-flex">
                            <label class="col-md-3">Practice With Guest : </label>
                            <span class="col-md-9">{{
                                (clubInfoData.club_sanction_data != null) ? (clubInfoData.club_sanction_data.practise_with_guest ? 'Yes' : 'No') : ''
                              }}</span>
                          </div>
                          <div class="col-md-12 d-flex">
                            <label class="col-md-3">Practice Without Guest : </label>
                            <span class="col-md-9">{{
                                (clubInfoData.club_sanction_data != null) ? (clubInfoData.club_sanction_data.practise_without_guest ? 'Yes' : 'No') : ''
                              }}</span>
                          </div>
                          <div class="col-md-12 d-flex">
                            <label class="col-md-3">Exhibition : </label>
                            <span class="col-md-9">{{
                                (clubInfoData.club_sanction_data != null) ? (clubInfoData.club_sanction_data.exhibition ? 'Yes' : 'No') : ''
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row profile-body" v-if="fields.club_info && fields.club_info.club_info">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Site Information</h3>
                        </div>
                        <div class="modal-btn">
                          <b-button v-b-modal.modal-club-address style="width: 100px">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                          </b-button>
                        </div>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body">
                        <b-table
                            ref="teamSite"
                            id="my-table"
                            :items="clubInfo.team_sites"
                            :fields="tblSitesFields"
                            class="mt-3"
                            hover
                            show-empty
                        >
                          <template
                              :fields="items"
                          >
                            <div class="text-left py-0 align-middle">
                              <div class="btn-group btn-group-sm">
                                <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                </button>
                                <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                              </div>
                            </div>
                          </template>
                          <template #empty="scope">
                            <h4>{{ scope.emptyText }}</h4>
                          </template>
                          <template #cell(actions)="row">
                            <div class="text-left py-0 align-middle">
                              <div class="btn-group btn-group-sm">
                                <!--                                                    <button class="btn btn-info" @click="viewRow(row.item, row.index, $event.target)"><i class="fas fa-eye"></i></button>-->
                                <button class="btn btn-primary ml-1" @click="editAddress(row.index, row)"><i
                                    class="fas fa-pencil-alt"></i></button>
                                <button class="btn btn-danger ml-1" @click="deleteSiteRow(row.index, row)"><i
                                    class="fas fa-trash"></i></button>
                              </div>
                            </div>
                          </template>
                          <!--<template #table-busy>
                                            <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                            </div>
                                        </template>-->
                        </b-table>
                        <span class="text-sm text-danger" ref="teamSiteError">{{ teamSiteError }}</span>
                      </div>
                      <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                  </div>
                </div>
                <div class="row profile-body" v-if="fields.club_officer && fields.club_officer.president">
                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>President</h3>
                        </div>
                        <div class="modal-btn" v-if="clubInfo.president_info != null">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('2')">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="clubInfo.president_info == null" class="ml-3">
                          <label class="">Does your club have a President?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(2, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="prsidentInfoData" id="pyes" name="prsidentInfoData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(2, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="prsidentInfoData" id="pno" name="prsidentInfoData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="presidentData">{{ presidentError }}</span>
                        </div>
                        <div class="row" v-if="clubInfo.president_info != null">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Member#</label>
                              <span v-if="clubInfo.president_info != null">{{
                                  clubInfo.president_info.membership_id
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>First Name</label>
                              <span v-if="clubInfo.president_info != null">{{
                                  clubInfo.president_info.first_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Last Name</label>
                              <span v-if="clubInfo.president_info != null">{{
                                  clubInfo.president_info.last_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>City</label>
                              <span v-if="clubInfo.president_info != null">{{ clubInfo.president_info.city }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>State</label>
                              <span v-if="clubInfo.president_info != null">{{
                                  clubInfo.president_info.state_code
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Zip/Postal Code</label>
                              <span v-if="clubInfo.president_info != null">{{ clubInfo.president_info.zip }}</span>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Membership Status <span class="ml-2 d-inline"
                                                                                v-if="clubInfo.president_info.member.status_text"
                                                                                v-b-tooltip.hover
                                                                                :title="clubInfo.president_info.member.membership_status_info"><img
                                  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                  style="width:20px;"/></span></label>
                              <span class="text-success" v-if="clubInfo.president_info.member.status == 1"><img
                                  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{
                                  clubInfo.president_info.member.status_text
                                }} (Expires {{ clubInfo.president_info.member.validThru | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubInfo.president_info.member.status >=7"> <img
                                  class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{
                                  clubInfo.president_info.member.status_text
                                }}</span>
                              <span class="text-danger" v-else> <img style="width:18px;" class="mr-1"
                                                                     src="/dist/img/profile/deactivated.png"> {{
                                  clubInfo.president_info.member.status_text
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="clubInfo.president_info.member.member_type_id != 2">
                            <div class="form-group">
                              <label class="form-label">Waiver Status</label>
                              <span class="text-success" v-if="clubInfo.president_info.member.waiver_status == 1"><img
                                  class="mr-1"
                                  src="/dist/img/profile/ico-status-current.png">Signed</span>
                              <span class="text-warning" v-else> <img class="mr-1"
                                                                      src="/dist/img/profile/ico-status-pending.png"> Not Signed</span>
                            </div>
                          </div>
                          <template v-if="subdomain == 'waterski'">
                            <div class="col-md-6">
                              <div class="form-group" v-if="clubInfo.president_info.member.safe_sport !=null">
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-success"
                                      v-if="typeof (clubInfo.president_info.member.safe_sport) != 'undefined' && clubInfo.president_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.president_info.member.safe_sport.safe_sport_status_text
                                  }} (Expires {{
                                    clubInfo.president_info.member.safe_sport.expiration
                                  }})</span>
                                <span class="text-warning"
                                      v-else-if="typeof (clubInfo.president_info.member.safe_sport) != 'undefined'"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.president_info.member.safe_sport.safe_sport_status_text || "N/A"
                                  }}</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    "N/A"
                                  }}</span>
                              </div>
                              <div class="form-group" v-else>
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="clubInfo.president_info.member.background_screening">
                              <div class="form-group">
                                <label class="form-label">Background Screen
                                  <span
                                      v-if="clubInfo.president_info.member.background_mvr_review != null"> / MVR</span>
                                  Status <span
                                      v-if="clubInfo.president_info.member.background_screening.background_screening_info"
                                      v-b-tooltip.hover
                                      :title="clubInfo.president_info.member.background_screening.background_screening_info"> <img
                                      v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                      alt="tooltipicon"/></span></label>
                                <span class="text-success"
                                      v-if="clubInfo.president_info.member.background_screening.status == 2"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.president_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.president_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-success"
                                      v-if="clubInfo.president_info.member.background_mvr_review != null && clubInfo.president_info.member.background_mvr_review.status == 2"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.president_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.president_info.member.background_mvr_review != null"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.president_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.president_info.member.background_mvr_review != null"> / Not Started</span>
                              </div>
                            </div>
                            <div class="col-md-4" v-else>
                              <div class="form-group">
                                <label class="form-label">Background Screen Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="fields.club_officer && fields.club_officer.vice_president">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Vice President</h3>
                        </div>
                        <div class="modal-btn" v-if="clubInfo.vp_info != null">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('3')">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="clubInfo.vp_info == null" class="ml-3">
                          <label for="">Does your club have a Vice President?</label>
                          <div class="form-check-inline">

                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(3, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="vprsidentInfoData" id="vpyes" name="vprsidentInfoData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(3, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="vprsidentInfoData" id="vpno" name="vprsidentInfoData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="presidentData">{{ vpresidentError }}</span>
                        </div>
                        <div class="row" v-if="clubInfo.vp_info != null">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Member#</label>
                              <span v-if="clubInfo.vp_info != null">{{ clubInfo.vp_info.membership_id }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>First Name</label>
                              <span v-if="clubInfo.vp_info != null">{{ clubInfo.vp_info.first_name }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Last Name</label>
                              <span v-if="clubInfo.vp_info != null">{{ clubInfo.vp_info.last_name }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>City</label>
                              <span v-if="clubInfo.vp_info != null">{{ clubInfo.vp_info.city }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>State</label>
                              <span v-if="clubInfo.vp_info != null">{{ clubInfo.vp_info.state_code }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Zip/Postal Code</label>
                              <span v-if="clubInfo.vp_info != null">{{ clubInfo.vp_info.zip }}</span>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Membership Status <span class="ml-2 d-inline"
                                                                                v-if="clubInfo.vp_info.member.status_text"
                                                                                v-b-tooltip.hover
                                                                                :title="clubInfo.vp_info.member.membership_status_info"><img
                                  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                  style="width:20px;"/></span></label>
                              <span class="text-success" v-if="clubInfo.vp_info.member.status == 1"><img class="mr-1"
                                                                                                         src="/dist/img/profile/ico-status-current.png"> {{
                                  clubInfo.vp_info.member.status_text
                                }} (Expires {{ clubInfo.vp_info.member.validThru | formatDob }})
                                
                                </span>
                              <span class="text-warning" v-else-if="clubInfo.vp_info.member.status >=7"> <img
                                  class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{
                                  clubInfo.vp_info.member.status_text
                                }}</span>
                              <span class="text-danger" v-else> <img style="width:18px;" class="mr-1"
                                                                     src="/dist/img/profile/deactivated.png"> {{
                                  clubInfo.vp_info.member.status_text
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="clubInfo.vp_info.member.member_type_id != 2">
                            <div class="form-group">
                              <label class="form-label">Waiver Status</label>
                              <span class="text-success" v-if="clubInfo.vp_info.member.waiver_status == 1"><img
                                  class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                              <span class="text-warning" v-else> <img class="mr-1"
                                                                      src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                            </div>
                          </div>
                          <template v-if="subdomain == 'waterski'">
                            <div class="col-md-6">
                              <div class="form-group" v-if="clubInfo.vp_info.member.safe_sport != null">
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-success"
                                      v-if="clubInfo.vp_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.vp_info.member.safe_sport.safe_sport_status_text
                                  }} (Expires {{
                                    clubInfo.vp_info.member.safe_sport.expiration
                                  }})</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.vp_info.member.safe_sport.safe_sport_status_text || "N/A"
                                  }}</span>
                              </div>
                              <div class="form-group" v-else>
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="clubInfo.vp_info.member.background_screening">
                              <div class="form-group">
                                <label class="form-label">Background Screen
                                  <span v-if="clubInfo.vp_info.member.background_mvr_review != null"> / MVR</span>
                                  Status <span
                                      v-if="clubInfo.vp_info.member.background_screening.background_screening_info"
                                      v-b-tooltip.hover
                                      :title="clubInfo.vp_info.member.background_screening.background_screening_info"> <img
                                      v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                      alt="tooltipicon"/></span></label>
                                <span class="text-success"
                                      v-if="clubInfo.vp_info.member.background_screening.status == 2"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.vp_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.vp_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-success"
                                      v-if="clubInfo.vp_info.member.background_mvr_review != null && clubInfo.vp_info.member.background_mvr_review.status == 2"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.vp_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.vp_info.member.background_mvr_review != null"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.vp_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.vp_info.member.background_mvr_review != null"> / Not Started</span>
                              </div>
                            </div>
                            <div class="col-md-4" v-else>
                              <div class="form-group">
                                <label class="form-label">Background Screen Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row profile-body" v-if="fields.club_officer && fields.club_officer.secretary">
                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Secretary</h3>
                        </div>
                        <div class="modal-btn" v-if="clubInfo.secretary_info != null">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('4')">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="clubInfo.secretary_info == null" class="ml-3">
                          <label for="">Does your club have a Secretary?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(4, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="secretaryInfoData" id="syes" name="secretaryInfoData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(4, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="secretaryInfoData" id="sno" name="secretaryInfoData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="presidentData">{{ secretaryError }}</span>
                        </div>
                        <div class="row" v-if="clubInfo.secretary_info != null">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Member#</label>
                              <span v-if="clubInfo.secretary_info != null">{{
                                  clubInfo.secretary_info.membership_id
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>First Name</label>
                              <span v-if="clubInfo.secretary_info != null">{{
                                  clubInfo.secretary_info.first_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Last Name</label>
                              <span v-if="clubInfo.secretary_info != null">{{
                                  clubInfo.secretary_info.last_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>City</label>
                              <span v-if="clubInfo.secretary_info != null">{{ clubInfo.secretary_info.city }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>State</label>
                              <span v-if="clubInfo.secretary_info != null">{{
                                  clubInfo.secretary_info.state_code
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Zip/Postal Code</label>
                              <span v-if="clubInfo.secretary_info != null">{{ clubInfo.secretary_info.zip }}</span>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Membership Status <span class="ml-2 d-inline"
                                                                                v-if="clubInfo.secretary_info.member.status_text"
                                                                                v-b-tooltip.hover
                                                                                :title="clubInfo.secretary_info.member.membership_status_info"><img
                                  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                  style="width:20px;"/></span></label>
                              <span class="text-success" v-if="clubInfo.secretary_info.member.status == 1"><img
                                  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{
                                  clubInfo.secretary_info.member.status_text
                                }} (Expires {{ clubInfo.secretary_info.member.validThru | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubInfo.secretary_info.member.status >=7"> <img
                                  class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{
                                  clubInfo.secretary_info.member.status_text
                                }}</span>
                              <span class="text-danger" v-else> <img style="width:18px;" class="mr-1"
                                                                     src="/dist/img/profile/deactivated.png"> {{
                                  clubInfo.secretary_info.member.status_text
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="clubInfo.secretary_info.member.member_type_id != 2">
                            <div class="form-group">
                              <label class="form-label">Waiver Status</label>
                              <span class="text-success" v-if="clubInfo.secretary_info.member.waiver_status == 1"><img
                                  class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                              <span class="text-warning" v-else> <img class="mr-1"
                                                                      src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                            </div>
                          </div>
                          <template v-if="subdomain == 'waterski'">
                            <div class="col-md-6">
                              <div class="form-group" v-if="clubInfo.secretary_info.member.safe_sport !=null">
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-success"
                                      v-if="clubInfo.secretary_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.secretary_info.member.safe_sport.safe_sport_status_text
                                  }} (Expires {{
                                    clubInfo.secretary_info.member.safe_sport.expiration
                                  }})</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.secretary_info.member.safe_sport.safe_sport_status_text || "N/A"
                                  }}</span>
                              </div>
                              <div class="form-group" v-else>
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="clubInfo.secretary_info.member.background_screening">
                              <div class="form-group">
                                <label class="form-label">Background Screen
                                  <span
                                      v-if="clubInfo.secretary_info.member.background_mvr_review != null"> / MVR</span>
                                  Status <span
                                      v-if="clubInfo.secretary_info.member.background_screening.background_screening_info"
                                      v-b-tooltip.hover
                                      :title="clubInfo.secretary_info.member.background_screening.background_screening_info"> <img
                                      v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                      alt="tooltipicon"/></span></label>
                                <span class="text-success"
                                      v-if="clubInfo.secretary_info.member.background_screening.status == 2"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.secretary_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.secretary_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-success"
                                      v-if="clubInfo.secretary_info.member.background_mvr_review != null && clubInfo.secretary_info.member.background_mvr_review.status == 2"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.secretary_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.secretary_info.member.background_mvr_review != null"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.secretary_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.secretary_info.member.background_mvr_review != null"> / Not Started</span>
                              </div>
                            </div>
                            <div class="col-md-4" v-else>
                              <div class="form-group">
                                <label class="form-label">Background Screen Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                          </template>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Treasurer</h3>
                        </div>
                        <div class="modal-btn" v-if="clubInfo.treasurer_info != null">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('5')">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="clubInfo.treasurer_info == null" class="ml-3">
                          <label for="">Does your club have a Treasurer?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(5, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="treasurerInfoData" id="tyes" name="treasurerInfoData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(5, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="treasurerInfoData" id="tno" name="treasurerInfoData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="treasurerInfoData">{{ treasurerError }}</span>
                        </div>
                        <div class="row" v-if="clubInfo.treasurer_info != null">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Member#</label>
                              <span v-if="clubInfo.treasurer_info != null">{{
                                  clubInfo.treasurer_info.membership_id
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>First Name</label>
                              <span v-if="clubInfo.treasurer_info != null">{{
                                  clubInfo.treasurer_info.first_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Last Name</label>
                              <span v-if="clubInfo.treasurer_info != null">{{
                                  clubInfo.treasurer_info.last_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>City</label>
                              <span v-if="clubInfo.treasurer_info != null">{{ clubInfo.treasurer_info.city }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>State</label>
                              <span v-if="clubInfo.treasurer_info != null">{{
                                  clubInfo.treasurer_info.state_code
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Zip/Postal Code</label>
                              <span v-if="clubInfo.treasurer_info != null">{{ clubInfo.treasurer_info.zip }}</span>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Membership Status <span class="ml-2 d-inline"
                                                                                v-if="clubInfo.treasurer_info.member.status_text"
                                                                                v-b-tooltip.hover
                                                                                :title="clubInfo.treasurer_info.member.membership_status_info"><img
                                  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                  style="width:20px;"/></span></label>
                              <span class="text-success" v-if="clubInfo.treasurer_info.member.status == 1"><img
                                  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{
                                  clubInfo.treasurer_info.member.status_text
                                }} (Expires {{ clubInfo.treasurer_info.member.validThru | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubInfo.treasurer_info.member.status >=7"> <img
                                  class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{
                                  clubInfo.treasurer_info.member.status_text
                                }}</span>
                              <span class="text-danger" v-else> <img style="width:18px;" class="mr-1"
                                                                     src="/dist/img/profile/deactivated.png"> {{
                                  clubInfo.treasurer_info.member.status_text
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="clubInfo.treasurer_info.member.member_type_id != 2">
                            <div class="form-group">
                              <label class="form-label">Waiver Status</label>
                              <span class="text-success" v-if="clubInfo.treasurer_info.member.waiver_status == 1"><img
                                  class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                              <span class="text-warning" v-else> <img class="mr-1"
                                                                      src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                            </div>
                          </div>
                          <template v-if="subdomain == 'waterski'">
                            <div class="col-md-6">
                              <div class="form-group" v-if="clubInfo.treasurer_info.member.safe_sport !=null">
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-success"
                                      v-if="clubInfo.treasurer_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.treasurer_info.member.safe_sport.safe_sport_status_text
                                  }} (Expires {{
                                    clubInfo.treasurer_info.member.safe_sport.expiration
                                  }})</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.treasurer_info.member.safe_sport.safe_sport_status_text || "N/A"
                                  }}</span>
                              </div>
                              <div class="form-group" v-else>
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-warning"><img class="mr-1"
                                                                src="/dist/img/profile/ico-status-pending.png">N/A</span>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="clubInfo.treasurer_info.member.background_screening">
                              <div class="form-group">
                                <label class="form-label">Background Screen
                                  <span
                                      v-if="clubInfo.treasurer_info.member.background_mvr_review != null"> / MVR</span>
                                  Status <span
                                      v-if="clubInfo.treasurer_info.member.background_screening.background_screening_info"
                                      v-b-tooltip.hover
                                      :title="clubInfo.treasurer_info.member.background_screening.background_screening_info"> <img
                                      v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                      alt="tooltipicon"/></span></label>
                                <span class="text-success"
                                      v-if="clubInfo.treasurer_info.member.background_screening.status == 2"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.treasurer_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.treasurer_info.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-success"
                                      v-if="clubInfo.treasurer_info.member.background_mvr_review != null && clubInfo.treasurer_info.member.background_mvr_review.status == 2"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.treasurer_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.treasurer_info.member.background_mvr_review != null"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.treasurer_info.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.treasurer_info.member.background_mvr_review != null"> / Not Started</span>
                              </div>
                            </div>
                            <div class="col-md-4" v-else>
                              <div class="form-group">
                                <label class="form-label">Background Screen Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                          </template>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>


                <div class="row profile-body">

                  <div class="col-md-12" v-if="fields.club_officer && fields.club_officer.treasurer">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Junior Development Leader(s)</h3>
                          <div class="pl-2 pr-2" v-if="prop_req_junior.show_ski">
                            <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                            <template v-if="prop_req_junior.show_ski.info">
                              <ul class="jdlinfo" v-for="(item,k) in prop_req_junior.show_ski.info" :key="'desc_' + k">
                                <li v-html="item"></li>
                              </ul>
                            </template>
                          </div>
                        </div>
                        <div class="modal-btn" v-if="Object.keys(clubInfo.junior_development).length > 0">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('1')">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                          </b-button>
                        </div>

                      </div>
                      <!-- /.card-header -->
                      <div class="card-body">

                        <div v-if="Object.keys(clubInfo.junior_development).length == 0" class="ml-3">
                          <label for="">Does your club have a Junior Development Leader?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(1, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="juniorInfoData" id="jyes" name="juniorInfoData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(1, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="juniorInfoData" id="jno" name="juniorInfoData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="juniorInfoData">{{ juniorError }}</span>
                        </div>
                        <div v-else>

                          <b-table
                              ref="junior_table"
                              id="my-table"
                              :items="clubInfo.junior_development"
                              :fields="tblFields"
                              class="mt-3"
                              hover
                              show-empty
                          >
                            <template #empty="scope">
                              <h4>{{ scope.emptyText }}</h4>
                            </template>
                            <template #cell(actions)="row">
                              <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                  <!--<button class="btn btn-info" @click="viewRow(row.item, row.index, $event.target)"><i class="fas fa-eye"></i></button>
                                                      <button class="btn btn-primary ml-1" @click="editRow(row.item, row.index, $event.target)"><i
                                                          class="fas fa-pencil-alt"></i></button>-->
                                  <button class="btn btn-danger ml-1" @click="deleteRow(row.index, 1)"><i
                                      class="fas fa-trash"></i></button>
                                </div>
                              </div>
                            </template>
                            <template #cell(memStatus)="row">
                              <span class="text-success" v-if="row.item.member.status == 1"><img class="mr-1"
                                                                                                 src="/dist/img/profile/ico-status-current.png">{{
                                  row.item.member.status_text
                                }} (Expires {{ row.item.member.valid_thru | formatDob }}) <span class="d-inline ml-2"
                                                                                                v-if="row.item.member.status_text"
                                                                                                v-b-tooltip.hover
                                                                                                :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                              <span class="text-warning" v-else-if="row.item.member.status >=7"><img class="mr-1"
                                                                                                     src="/dist/img/profile/ico-status-pending.png">{{
                                  row.item.member.status_text
                                }} <span class="d-inline ml-2" v-if="row.item.member.status_text" v-b-tooltip.hover
                                         :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                              <span class="text-danger" v-else><img style="width:18px;" class="mr-1"
                                                                    src="/dist/img/profile/deactivated.png">{{
                                  row.item.member.status_text
                                }} <span class="d-inline ml-2" v-if="row.item.member.status_text" v-b-tooltip.hover
                                         :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                            </template>
                            <template #cell(waiverStatus)="row">
                              <span class="text-success" v-if="row.item.member.waiver_status == 1"><img class="mr-1"
                                                                                                        src="/dist/img/profile/ico-status-current.png">Signed</span>
                              <span class="text-danger" v-else><img class="mr-1"
                                                                    src="/dist/img/profile/ico-status-pending.png">Not signed</span>
                            </template>
                            <template #cell(ssStatus)="row">
                            <span class="text-success"
                                  v-if="row.item.member.safe_sport.safe_sport_status_text == 'Complete'">{{
                                row.item.member.safe_sport.safe_sport_status_text
                              }} (Expires {{
                                row.item.member.safe_sport.expiration
                              }})</span>
                              <span class="text-warning"
                                    v-else>{{ row.item.member.safe_sport.safe_sport_status_text || "N/A" }}</span>
                            </template>
                            <template #cell(bgStatus)="row">
                                        <span v-if="row.item.member.background_screening">
                                          <span class="text-success"
                                                v-if="row.item.member.background_screening.status == 2">{{
                                              row.item.member.background_screening.background_screening_status_text || "N/A"
                                            }}</span>
                                          <span class="text-danger"
                                                v-else>{{
                                              row.item.member.background_screening.background_screening_status_text || "N/A"
                                            }}</span>
                                        </span>
                              <span v-else>N/A</span>
                            </template>
                          </b-table>
                        </div>

                      </div>
                      <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                  </div>
                </div>
                <div class="row profile-body">
                  <div class="col-md-12" v-if="fields.club_director">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Show Director(s)</h3>
                          <div class="pl-2 pr-2" v-if="prop_req_director.show_ski">
                            <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                            <template v-if="prop_req_director.show_ski.info">
                              <ul class="jdlinfo" v-for="(item,k) in prop_req_director.show_ski.info"
                                  :key="'desc_' + k">
                                <li v-html="item"></li>
                              </ul>
                            </template>
                          </div>
                        </div>
                        <div class="modal-btn" v-if="Object.keys(clubInfo.ski_directors).length > 0">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('6')">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                          </b-button>
                        </div>

                      </div>
                      <!-- /.card-header -->
                      <div class="card-body">

                        <div v-if="Object.keys(clubInfo.ski_directors).length == 0" class="ml-3">
                          <label for="">Does your club have a Show Director?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(6, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="skiDirectorsData" id="sdyes" name="skiDirectorsData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(6, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="skiDirectorsData" id="sdno" name="skiDirectorsData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="skiDirectorsData">{{ skiDirectorsError }}</span>
                        </div>
                        <div v-else>

                          <b-table
                              ref="table"
                              id="my-table"
                              :items="clubInfo.ski_directors"
                              :fields="tblFields"
                              class="mt-3"
                              hover
                              show-empty
                          >
                            <template
                                :fields="items"
                            >
                              <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                  <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                  <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                  </button>
                                  <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                </div>
                              </div>
                            </template>
                            <template #empty="scope">
                              <h4>{{ scope.emptyText }}</h4>
                            </template>
                            <template #cell(actions)="row">
                              <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                  <!--<button class="btn btn-info" @click="viewRow(row.item, row.index, $event.target)"><i class="fas fa-eye"></i></button>
                                                      <button class="btn btn-primary ml-1" @click="editRow(row.item, row.index, $event.target)"><i
                                                          class="fas fa-pencil-alt"></i></button>-->
                                  <button class="btn btn-danger ml-1" @click="deleteRow(row.index, 6)"><i
                                      class="fas fa-trash"></i></button>
                                </div>
                              </div>
                            </template>
                            <template #cell(memStatus)="row">
                              <span class="text-success" v-if="row.item.member.status == 1"><img class="mr-1"
                                                                                                 src="/dist/img/profile/ico-status-current.png">{{
                                  row.item.member.status_text
                                }} (Expires {{ row.item.member.valid_thru | formatDob }}) <span class="d-inline"
                                                                                                v-if="row.item.member.status_text"
                                                                                                v-b-tooltip.hover
                                                                                                :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                              <span class="text-warning" v-else-if="row.item.member.status >=7"><img class="mr-1"
                                                                                                     src="/dist/img/profile/ico-status-pending.png">{{
                                  row.item.member.status_text
                                }} <span class="d-inline" v-if="row.item.member.status_text" v-b-tooltip.hover
                                         :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                              <span class="text-danger" v-else><img style="width:18px;" class="mr-1"
                                                                    src="/dist/img/profile/deactivated.png">{{
                                  row.item.member.status_text
                                }} <span class="d-inline" v-if="row.item.member.status_text" v-b-tooltip.hover
                                         :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                            </template>
                            <template #cell(waiverStatus)="row">
                              <span class="text-success" v-if="row.item.member.waiver_status == 1"><img class="mr-1"
                                                                                                        src="/dist/img/profile/ico-status-current.png">Signed</span>
                              <span class="text-danger" v-else><img class="mr-1"
                                                                    src="/dist/img/profile/ico-status-pending.png">Not signed</span>
                            </template>
                            <template #cell(ssStatus)="row">
                            <span
                                v-if="row.item.member.safe_sport.safe_sport_status_text == 'Complete'">{{
                                row.item.member.safe_sport.safe_sport_status_text
                              }} (Expires {{
                                row.item.member.safe_sport.expiration
                              }})</span>
                              <span v-else>{{ row.item.member.safe_sport.safe_sport_status_text || "N/A" }}</span>
                            </template>
                            <template #cell(bgStatus)="row">
                          <span v-if="row.item.member.background_screening">
                            <span class="text-success"
                                  v-if="row.item.member.background_screening.status == 2">{{
                                row.item.member.background_screening.background_screening_status_text || "N/A"
                              }}</span>
                            <span class="text-danger"
                                  v-else>{{
                                row.item.member.background_screening.background_screening_status_text || "N/A"
                              }}</span>
                          </span>
                              <span v-else>N/A</span>
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                  </div>
                  <div class="col-md-12" v-if="fields.club_director">
                    <div class="card  border-none">
                      <div class="card-header bg-white">
                        <div class="header-title">
                          <h3>Assistant Show Director(s)</h3>
                          <div class="pl-2 pr-2" v-if="prop_req_asst_dir.show_ski">
                            <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                            <template v-if="prop_req_asst_dir.show_ski.info">
                              <ul class="jdlinfo" v-for="(item,k) in prop_req_asst_dir.show_ski.info"
                                  :key="'desc_' + k">
                                <li v-html="item"></li>
                              </ul>
                            </template>
                          </div>
                        </div>
                        <div class="modal-btn" v-if="Object.keys(clubInfo.assistant_skidirectors).length > 0">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('7')">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon">Add
                          </b-button>
                          <!-- <div class="pl-2 pr-2" v-if="prop_req_asst_dir.show_ski">
                            <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                            <template v-if="prop_req_asst_dir.show_ski.info">
                              <ul class="jdlinfo" v-for="(item,k) in prop_req_asst_dir.show_ski.info" :key="'desc_' + k">
                                <li v-html="item"></li>
                              </ul>
                            </template>
                          </div> -->
                        </div>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body">

                        <div v-if="Object.keys(clubInfo.assistant_skidirectors).length == 0" class="ml-3">
                          <label for="">Does your club have an Assistant Show Director?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(7, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="askiDirectorsData" id="asdyes" name="askiDirectorsData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(7, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="askiDirectorsData" id="asdno" name="askiDirectorsData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="skiDirectorsData">{{ askiDirectorsError }}</span>
                        </div>
                        <div v-else>

                          <b-table
                              ref="table"
                              id="my-table"
                              :items="clubInfo.assistant_skidirectors"
                              :fields="tblFields"
                              class="mt-3"
                              hover
                              show-empty
                          >
                            <template
                                :fields="items"
                            >
                              <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                  <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                  <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                  </button>
                                  <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                </div>
                              </div>
                            </template>
                            <template #empty="scope">
                              <h4>{{ scope.emptyText }}</h4>
                            </template>
                            <template #cell(actions)="row">
                              <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                  <!--<button class="btn btn-info" @click="viewRow(row.item, row.index, $event.target)"><i class="fas fa-eye"></i></button>
                                                      <button class="btn btn-primary ml-1" @click="editRow(row.item, row.index, $event.target)"><i
                                                          class="fas fa-pencil-alt"></i></button>-->
                                  <button class="btn btn-danger ml-1" @click="deleteRow(row.index, 7)"><i
                                      class="fas fa-trash"></i></button>
                                </div>
                              </div>
                            </template>
                            <template #cell(memStatus)="row">
                              <span class="text-success" v-if="row.item.member.status == 1"><img class="mr-1"
                                                                                                 src="/dist/img/profile/ico-status-current.png">{{
                                  row.item.member.status_text
                                }} (Expires {{ row.item.member.valid_thru | formatDob }}) <span class="d-inline"
                                                                                                v-if="row.item.member.status_text"
                                                                                                v-b-tooltip.hover
                                                                                                :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                              <span class="text-warning" v-else-if="row.item.member.status >=7"><img class="mr-1"
                                                                                                     src="/dist/img/profile/ico-status-pending.png">{{
                                  row.item.member.status_text
                                }} <span class="d-inline" v-if="row.item.member.status_text" v-b-tooltip.hover
                                         :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                              <span class="text-danger" v-else><img style="width:18px;" class="mr-1"
                                                                    src="/dist/img/profile/deactivated.png">{{
                                  row.item.member.status_text
                                }} <span class="d-inline" v-if="row.item.member.status_text" v-b-tooltip.hover
                                         :title="row.item.member.membership_status_info"> <img
                                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                    style="width:20px;"/></span></span>
                            </template>
                            <template #cell(waiverStatus)="row">
                              <span class="text-success" v-if="row.item.member.waiver_status == 1"><img class="mr-1"
                                                                                                        src="/dist/img/profile/ico-status-current.png">Signed</span>
                              <span class="text-danger" v-else><img class="mr-1"
                                                                    src="/dist/img/profile/ico-status-pending.png">Not signed</span>
                            </template>
                            <template #cell(ssStatus)="row">
                            <span
                                v-if="row.item.member.safe_sport.safe_sport_status_text == 'Complete'">{{
                                row.item.member.safe_sport.safe_sport_status_text
                              }} (Expires {{
                                row.item.member.safe_sport.expiration
                              }})</span>
                              <span v-else>{{ row.item.member.safe_sport.safe_sport_status_text || "N/A" }}</span>
                            </template>
                            <template #cell(bgStatus)="row">
                          <span v-if="row.item.member.background_screening">
                            <span class="text-success"
                                  v-if="row.item.member.background_screening.status == 2">{{
                                row.item.member.background_screening.background_screening_status_text || "N/A"
                              }}</span>
                            <span class="text-danger"
                                  v-else>{{
                                row.item.member.background_screening.background_screening_status_text || "N/A"
                              }}</span>
                          </span>
                              <span v-else>N/A</span>
                            </template>
                          </b-table>
                        </div>

                      </div>
                      <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                  </div>

                </div>
                <div class="row profile-body">
                  <div class="col-md-12" v-if="fields.safe_sport_coordinator">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Safe Sport Coordinator(s)</h3>
                          <div class="pl-2 pr-2" v-if="prop_req_ssc.desc ">
                            <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                            <template v-if="prop_req_ssc.desc.info">
                              <ul class="jdlinfo" v-for="(item,k) in prop_req_ssc.desc.info" :key="'desc_' + k">
                                <li v-html="item"></li>
                              </ul>
                            </template>
                          </div>
                        </div>
                        <div class="modal-btn">
                          <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('8')"
                                    v-if="clubInfo.safe_sport_coordinator != null">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="clubInfo.safe_sport_coordinator == null" class="ml-3">
                          <label for="">Does your club have a Safe Sport Coordinator?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(8, 'yes')" value="yes"
                                     class="form-check-input"
                                     v-model="safeSportCoordinatorData" id="sscyes" name="safeSportCoordinatorData">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="showMemberLookup(8, 'no')" value="no"
                                     class="form-check-input"
                                     v-model="safeSportCoordinatorData" id="sscno" name="safeSportCoordinatorData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="skiDirectorsData">{{
                              safeSportCoordinatorError
                            }}</span>
                        </div>
                        <div class="row" v-if="clubInfo.safe_sport_coordinator != null">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Member#</label>
                              <span v-if="clubInfo.safe_sport_coordinator != null">{{
                                  clubInfo.safe_sport_coordinator.membership_id
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>First Name</label>
                              <span v-if="clubInfo.safe_sport_coordinator != null">{{
                                  clubInfo.safe_sport_coordinator.first_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Last Name</label>
                              <span v-if="clubInfo.safe_sport_coordinator != null">{{
                                  clubInfo.safe_sport_coordinator.last_name
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>City</label>
                              <span v-if="clubInfo.safe_sport_coordinator != null">{{
                                  clubInfo.safe_sport_coordinator.city
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>State</label>
                              <span v-if="clubInfo.safe_sport_coordinator != null">{{
                                  clubInfo.safe_sport_coordinator.state_code
                                }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Zip/Postal Code</label>
                              <span v-if="clubInfo.safe_sport_coordinator != null">{{
                                  clubInfo.safe_sport_coordinator.zip
                                }}</span>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Membership Status <span class="d-inline"
                                                                                v-if="clubInfo.safe_sport_coordinator.member.status_text"
                                                                                v-b-tooltip.hover
                                                                                :title="clubInfo.safe_sport_coordinator.member.membership_status_info"><img
                                  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"
                                  style="width:20px;"/></span></label>
                              <span class="text-success" v-if="clubInfo.safe_sport_coordinator.member.status == 1"><img
                                  class="mr-1"
                                  src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.safe_sport_coordinator.member.status_text }} (Expires {{ clubInfo.safe_sport_coordinator.member.validThru | formatDob }})</span>
                              <span class="text-warning"
                                    v-else-if="clubInfo.safe_sport_coordinator.member.status >=7"><img class="mr-1"
                                                                                                       src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.safe_sport_coordinator.member.status_text }}</span>
                              <span class="text-danger" v-else> <img style="width:18px;" class="mr-1"
                                                                     src="/dist/img/profile/deactivated.png"> {{ clubInfo.safe_sport_coordinator.member.status_text }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="clubInfo.safe_sport_coordinator.member.member_type_id != 2">
                            <div class="form-group">
                              <label class="form-label">Waiver Status</label>
                              <span class="text-success"
                                    v-if="clubInfo.safe_sport_coordinator.member.waiver_status == 1"><img class="mr-1"
                                                                                                          src="/dist/img/profile/ico-status-current.png"> Signed</span>
                              <span class="text-warning" v-else> <img class="mr-1"
                                                                      src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                            </div>
                          </div>
                          <template v-if="subdomain == 'waterski'">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="text-success"
                                      v-if="clubInfo.safe_sport_coordinator.member.safe_sport.safe_sport_status_text == 'Complete'"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.safe_sport_coordinator.member.safe_sport.safe_sport_status_text
                                  }} (Expires {{
                                    clubInfo.safe_sport_coordinator.member.safe_sport.expiration
                                  }})</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                     clubInfo.safe_sport_coordinator.member.safe_sport.safe_sport_status_text || "N/A"
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="clubInfo.safe_sport_coordinator.member.background_screening">
                              <div class="form-group">
                                <label class="form-label">Background Screen
                                  <span v-if="clubInfo.safe_sport_coordinator.member.background_mvr_review != null"> / MVR</span>
                                  Status <span
                                      v-if="clubInfo.safe_sport_coordinator.member.background_screening.background_screening_info"
                                      v-b-tooltip.hover
                                      :title="clubInfo.safe_sport_coordinator.member.background_screening.background_screening_info"> <img
                                      v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                      alt="tooltipicon"/></span></label>
                                <span class="text-success"
                                      v-if="clubInfo.safe_sport_coordinator.member.background_screening.status == 2"><img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{
                                    clubInfo.safe_sport_coordinator.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-warning" v-else><img class="mr-1"
                                                                       src="/dist/img/profile/ico-status-pending.png"> {{
                                    clubInfo.safe_sport_coordinator.member.background_screening.background_screening_status_text || "N/A"
                                  }}</span>
                                <span class="text-success"
                                      v-if="clubInfo.safe_sport_coordinator.member.background_mvr_review != null && clubInfo.safe_sport_coordinator.member.background_mvr_review.status == 2"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.safe_sport_coordinator.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.safe_sport_coordinator.member.background_mvr_review != null"> / <img
                                    class="mr-1"
                                    src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.safe_sport_coordinator.member.background_mvr_review.background_screening_status_text || "N/A" }}</span>
                                <span class="text-warning"
                                      v-else-if="clubInfo.safe_sport_coordinator.member.background_mvr_review != null"> / Not Started</span>
                              </div>
                            </div>
                            <div class="col-md-4" v-else>
                              <div class="form-group">
                                <label class="form-label">Background Screen Status</label>
                                <span class="text-warning">N/A</span>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="fields.club_insurance">
                    <div class="card border-none">
                      <div class="card-header bg-white">
                        <div class="header-title">
                          <h3>Purchase Certificate of Insurance (Optional)</h3>
                          <div class="pl-2 pr-2" v-if="prop_req_coi.desc ">
                            <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                            <template v-if="prop_req_coi.desc.info">
                              <ul class="jdlinfo" v-for="(item,j) in prop_req_coi.desc.info" :key="'desc_' + j">
                                <li v-html="item"></li>
                              </ul>
                              <p v-if="prop_req_coi.desc.note" class="small"><strong>Note
                                : </strong>{{ prop_req_coi.desc.note }}</p>
                            </template>
                          </div>
                        </div>
                        <div class="modal-btn" v-if="Object.keys(clubInfo.insured_members).length > 0">
                          <b-button @click="addInsurance" style="width: 100px">
                            <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                          </b-button>
                        </div>
                        <b-modal ref="modal"
                                 ok-only
                                 ok-title="Add Insurance"
                                 @hidden="resetModal"
                                 @ok="handleInsuranceOk" id="modal-center6" size="lg" centered
                                 title="Certificate of Insurance Request">
                          <form ref="form" @submit.stop.prevent="handleInsuranceSubmit">
                            <div class="popupbody">
                              <!--<h5>Certificate of Insurance Request</h5>-->
                              <p class="font-13 mb-1">Complete for each third party requiring a certificate of insurance
                                from your club with respect to USA Water Ski & Wake Sports sanctioned events. Each
                                request is $50.</p>
                              <p class="font-13 text-danger"><i>Note: Coverage only applies with respect to tournaments,
                                practices, exhibitions, clinics, and related activities sanctioned and approved by USA
                                Water Ski & Wake Sports.</i></p>
                              <div class="newname-inner">
                                <div class="row">
                                  <div class="col-md-6" v-if="!notACompany">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label for="companyName" class="form-label">Company Name <sup
                                              class="text-danger">*</sup></label>
                                          <input id="companyName" type="text" maxlength="250" class="form-control"
                                                 name="insuranceForm.name" value=""
                                                 v-model="insuranceForm.company_name">
                                          <span class="text-sm text-danger">{{ insuranceErrors.company_name }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3" v-if="notACompany">
                                    <div class="form-group">
                                      <label for="firstName" class="form-label">First Name <sup
                                          class="text-danger">*</sup></label>
                                      <input id="firstName" type="text" class="form-control" maxlength="90"
                                             name="insuranceForm.first_name" value=""
                                             v-model="insuranceForm.first_name">
                                      <span class="text-sm text-danger">{{ insuranceErrors.first_name }}</span>
                                    </div>

                                  </div>
                                  <div class="col-md-3" v-if="notACompany">
                                    <div class="form-group">
                                      <label for="lastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                                      <input id="lastName" type="text" class="form-control" maxlength="90"
                                             name="insuranceForm.last_name" value="" v-model="insuranceForm.last_name">
                                      <span class="text-sm text-danger">{{ insuranceErrors.last_name }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3 mt-4">
                                    <div class="form-check">
                                      <input class="form-check-input" type="checkbox" id="notACompany"
                                             name="notACompany" v-model="notACompany" value="1">
                                      <label class="d-flex mr-1" for="notACompany">Not a company</label>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="address1" class="form-label">Address 1<sup class="text-danger">*</sup>
                                      </label>
                                      <input id="address1" type="text" class="form-control" maxlength="45"
                                             name="insuranceForm.address1" value="" v-model="insuranceForm.address1">
                                      <span class="text-sm text-danger">{{ insuranceErrors.address1 }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="address2" class="form-label">Address 2 </label>
                                      <input id="address2" type="text" class="form-control" maxlength="45"
                                             name="insuranceForm.address2" value="" v-model="insuranceForm.address2">
                                      <span class="text-sm text-danger">{{ insuranceErrors.address2 }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="form-group">
                                      <label for="city" class="form-label">City<sup class="text-danger">*</sup></label>
                                      <input id="city" type="text" class="form-control" maxlength="45"
                                             name="insuranceForm.city" value="" v-model="insuranceForm.city">
                                      <span class="text-sm text-danger">{{ insuranceErrors.city }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="form-group">
                                      <label for="state" class="form-label">State <sup class="text-danger">*</sup>
                                      </label>
                                      <select class="form-control" name="insuranceForm.state_code"
                                              v-model="insuranceForm.state_code">
                                        <option v-for="listOfState in listOfStates" :key="listOfState.state"
                                                :value="listOfState.state_code">{{ listOfState.state }}
                                        </option>
                                      </select>
                                      <span class="text-sm text-danger">{{ insuranceErrors.state_code }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="form-group">
                                      <label for="zipCode" class="form-label">Zip Code <sup class="text-danger">*</sup>
                                      </label>
                                      <input id="zipCode" type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')"
                                             name="insuranceForm.zip" v-model="insuranceForm.zip">
                                      <span class="text-sm text-danger">{{ insuranceErrors.zip }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label for="homePhone" class="form-label">Phone <sup class="text-danger">*</sup>
                                      </label>
                                      <vue-tel-input :inputOptions="options" v-model="insuranceForm.home_phone"
                                                     ref="phone" maxlength="20" v-bind="homeProps"  @validate="validateTelinput"
                                                     :validCharactersOnly="true"></vue-tel-input>
                                      <span class="text-sm text-danger">{{ insuranceErrors.home_phone }}</span>
                                    </div>
                                  </div>
                                  <!-- <div class="col-md-4">
                                                        <div class="form-group">
                                                        <label for="workPhone" class="form-label">Work Phone</label>
                                                            <vue-tel-input :inputOptions="options" v-model="insuranceForm.work_phone" ref="phone" maxlength="20" v-bind="workProps" :validCharactersOnly="true"></vue-tel-input>
                                                            <span class="text-sm text-danger">{{ insuranceErrors.work_phone_country_code }}</span>
                                                        </div>
                                                        </div>-->

                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label for="certificateFor" class="form-label">This certificate is for:<sup
                                          class="text-danger">*</sup> </label>
                                      <div class="form-group ">
                                        <label class="form-label" for="yearRoundclubactivity"> <input type="radio"
                                                                                                      id="yearRoundclubactivity"
                                                                                                      name="insuranceForm.certificate_type"
                                                                                                      v-model="insuranceForm.certificate_type"
                                                                                                      value="year"> Year
                                          - Round Club Activity</label>
                                        <label class="form-label" for="specificEvent">

                                          <input type="radio" id="specificEvent" maxlength="20"
                                                 name="insuranceForm.certificate_type"
                                                 v-model="insuranceForm.certificate_type" value="specificEvent">
                                          Specific Event</label><br>
                                        <span class="text-sm text-danger">{{ insuranceErrors.certificate_type }}</span>
                                      </div>
                                    </div>

                                  </div>
                                  <div class="col-md-12">
                                    <div class="form-group certificatefor"
                                         v-if="insuranceForm.certificate_type == 'specificEvent'">
                                      <span class="font-13 text-danger"><i>Note: Certificate for Specific sanctioned events will not be issued until the event is sanctioned and approved by USA Water Ski & Wake Sports.</i></span><br>
                                      <label for="eventType" class="form-label">Specific Event Title<sup
                                          class="text-danger">*</sup> </label>
                                      <input type="text" class="form-control" maxlength="45"
                                             name="insuranceForm.specific_event_title"
                                             v-model="insuranceForm.specific_event_title">
                                      <span class="text-sm text-danger">{{
                                          insuranceErrors.specific_event_title
                                        }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="form-group specificeventtype"
                                         v-if="insuranceForm.certificate_type == 'specificEvent'">
                                      <label for="eventType" class="form-label">Specific Sanctioned Event Type <sup
                                          class="text-danger">*</sup></label>
                                      <div class="form-group row">
                                        <label class="form-label" for="tournament"><input type="radio" id="tournament"
                                                                                          name="insuranceForm.sanctioned_type"
                                                                                          v-model="insuranceForm.sanctioned_type"
                                                                                          value="tournament"> Tournament</label>
                                        <label class="form-label" for="practice"><input type="radio" id="practice"
                                                                                        name="insuranceForm.sanctioned_type"
                                                                                        v-model="insuranceForm.sanctioned_type"
                                                                                        value="practice">
                                          Practice</label><br>
                                        <label class="form-label" for="basicskillclinic"><input type="radio"
                                                                                                id="basicskillclinic"
                                                                                                name="insuranceForm.sanctioned_type"
                                                                                                v-model="insuranceForm.sanctioned_type"
                                                                                                value="basicskillclinic">
                                          Basic Skills Clinic</label>
                                        <label class="form-label" for="exhibition"><input type="radio" id="exhibition"
                                                                                          name="insuranceForm.sanctioned_type"
                                                                                          v-model="insuranceForm.sanctioned_type"
                                                                                          value="exhibition"> Exhibition</label><br>
                                        <div v-show="insuranceForm.sanctioned_type == 'tournament'">
                                          <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will
                                            not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                        </div>
                                        <div v-show="insuranceForm.sanctioned_type == 'practice'">
                                          <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will
                                            not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                        </div>
                                        <div v-show="insuranceForm.sanctioned_type == 'basicskillclinic'">
                                          <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will
                                            not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                        </div>
                                        <div v-show="insuranceForm.sanctioned_type == 'exhibition'">
                                          <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will
                                            not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                                        </div>
                                        <span class="text-sm text-danger">{{ insuranceErrors.sanctioned_type }}</span>
                                      </div>
                                      <div class="row" v-if="insuranceForm.certificate_type == 'specificEvent'">
                                        <div class="col-md-6">
                                          <div class="form-group">
                                            <label for="eventType" class="form-label">Event Sanction# <sup
                                                class="text-danger">*</sup></label>
                                            <input id="eventSanction" type="text" name="insuranceForm.event_sanction"
                                                   maxlength="45" v-model="insuranceForm.event_sanction"
                                                   class="form-control">
                                            <span class="text-sm text-danger">{{
                                                insuranceErrors.event_sanction
                                              }}</span>
                                          </div>
                                        </div>







<div class="row">
                              <div class="col-md-6">
                                <div class="form-group insurence-datepicker">
                                  <label for="eventType" class="form-label">Specific Events Start Date </label><br>

                                  <date-pick
                                      class="form-control"
                                      v-model="insuranceForm.specific_event_start_date"
                                      :pickTime="false"
                                      name="insuranceForm.specific_event_start_date"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      @input="specialEventStartDateValidation(insuranceForm.specific_event_start_date)"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                  <span class="text-sm text-danger">{{ insuranceErrors.specific_event_start_date }}</span>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group insurence-datepicker" >
                                  <label for="eventType" class="form-label">Specific Events End Date </label><br>

                                  <date-pick
                                      class="form-control"
                                      v-model="insuranceForm.specific_event_end_date"
                                      :pickTime="false"
                                      name="insuranceForm.specific_event_end_date"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      @input="specialEventEndDateValidation(insuranceForm.specific_event_end_date)"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                  <span class="text-sm text-danger">{{ insuranceErrors.specific_event_end_date }}</span>
                                </div>
                              </div> 
</div>








                                        





                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group ">
                                      <label class="form-label">Has the club entered into any agreement, contract, or
                                        permit which contains assumption of liability, indemnification, or hold harmless
                                        language? <sup class="text-danger">*</sup></label>
                                      <div class="">
                                        <label class="form-label" for="agreeyes"><input type="radio" id="agreeyes"
                                                                                        name="insuranceForm.team_has_any_agreement"
                                                                                        v-model="insuranceForm.team_has_any_agreement"
                                                                                        value="Y"> Yes</label><br>

                                      </div>
                                      <div class="">
                                        <label class="form-label" for="agreeno"> <input type="radio" id="agreeno"
                                                                                        name="insuranceForm.team_has_any_agreement"
                                                                                        v-model="insuranceForm.team_has_any_agreement"
                                                                                        value="N"> No</label><br>
                                      </div>
                                      <span class="text-sm text-danger">{{
                                          insuranceErrors.team_has_any_agreement
                                        }}</span>
                                      <span class="font-13 text-danger d-none"><i> (A copy of the document should be forwarded to USA-WSWS)</i></span>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group ">
                                      <label class="form-label">Does the certificate holder require additional insured
                                        status? <sup class="text-danger">*</sup></label>
                                      <div class="">
                                        <label class="form-label" for="ins-status-yes"><input type="radio"
                                                                                              id="ins-status-yes"
                                                                                              name="insuranceForm.additional_insurance"
                                                                                              v-model="insuranceForm.additional_insurance"
                                                                                              value="Y"> Yes</label><br>

                                      </div>
                                      <div class="">
                                        <label class="form-label" for="ins-status-no"> <input type="radio"
                                                                                              id="ins-status-no"
                                                                                              name="insuranceForm.additional_insurance"
                                                                                              v-model="insuranceForm.additional_insurance"
                                                                                              value="N"> No</label><br>
                                      </div>
                                      <span class="text-sm text-danger">{{
                                          insuranceErrors.additional_insurance
                                        }}</span>
                                    </div>
                                    <div class="form-group " v-show="insuranceForm.additional_insurance=='Y'">
                                      <b-form-group label="Please check the relationship of the additional insured:">
                                        <b-form-checkbox-group
                                            id="additional_insurance_member"
                                            v-model="insuranceForm.additional_insurance_member"
                                            :options="additionalInsuranceMemberOptions"
                                            name="additional_insurance_member"
                                        ></b-form-checkbox-group>
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </b-modal>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body">
                        <div v-if="Object.keys(clubInfo.insured_members).length == 0" class="ml-3">
                          <label for="">Would you like to purchase a Certificate of Insurance?</label>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" @change="addInsurance" value="yes"
                                     class="form-check-input"
                                     v-model="insuranceData" id="insyes" name="insuranceData" :disabled="!insuranceFee">Yes
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" value="no"
                                     class="form-check-input"
                                     v-model="insuranceData" id="insdno" name="insuranceData">No
                            </label>
                          </div>
                          <span class="text-sm text-danger" ref="insuranceData">{{ insuranceError }}</span>
                          <p class="text-sm text-danger" v-if="!insuranceFee">Certificate of Insurance purchase is disabled now.</p>
                        </div>
                        <div v-else>
                          <b-table
                              ref="table"
                              id="my-table"
                              :items="clubInfo.insured_members"
                              :fields="tblInsuredFields"
                              class="mt-3"
                              hover
                              show-empty
                          >
                            <template #cell(company_name)="data">
                              {{ data.value || '_' }}
                            </template>
                            <template #cell(first_name)="data">
                              {{ data.value || '_' }}
                            </template>
                            <template #cell(last_name)="data">
                            {{ data.value || '_' }}
                          </template>
                            <template
                                :fields="items"
                            >
                              <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                  <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                  <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                  </button>
                                  <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                </div>
                              </div>
                            </template>
                            <template #empty="scope">
                              <h4>{{ scope.emptyText }}</h4>
                            </template>
                            <template #cell(actions)="row">
                              <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm">
                                  <!--<button class="btn btn-info" @click="viewRow(row.item, row.index, $event.target)"><i class="fas fa-eye"></i></button>-->
                                  <button class="btn btn-danger ml-1" @click="deleteInsuranceRow(row.index)"><i
                                      class="fas fa-trash"></i></button>
                                  <button class="btn btn-primary ml-1" @click="editInsuranceRow(row.index)"
                                          v-if="row.item.id"><i
                                      class="fas fa-pencil-alt"></i></button>
                                </div>
                              </div>
                            </template>
                            <template #cell(renew)="row">
                              <div class="text-left pl-5 d-flex align-items-center">
                                <div class="btn-group btn-group-sm">
                                  <input class="form-check-input" type="checkbox" v-if="row.item.id"
                                         name="isRenew" v-model="row.item.isRenew" :key="updateKey" value="true" @change="changeRenewInsurance(row.index)">
                                </div>
                                <div class="mt-3">
                                  <i v-if="row.item.isNotValid" class="fa fa-exclamation-circle" v-b-tooltip.hover title="To renew this certificate of insurance, please fill in the missing fields."></i>
                                </div>
                              </div>
                            </template>
                          </b-table>
                        </div>

                      </div>
                      <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                  </div>
                </div>
              </div>
              <div v-show="wiz_step4" class="col-md-12 donation">
                <form @submit.prevent="donationAmountData" method="post">
                  <div class="card donation">
                    <div class="card-header register-title">
                      <h3>Donation</h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                            <div class="form-group radiotext">
                                <label for="sport-trained" class="form-label-check">
                                    <strong>{{ fields.donation.confirm_text }}<sup class="text-danger">*</sup></strong>
                                </label><br>
                                <div>
                                    <input type="radio" name="donationForm.confirm_text" id="donation_confirm_text_yes" v-model="donationForm.donation_confirm" value="1" @change="validateConfirmation">
                                    <label for="donation_confirm_text_yes">Yes</label>
                                </div>
                                <div>
                                    <input type="radio" name="donationForm.confirm_text" id="donation_confirm_text_no" v-model="donationForm.donation_confirm" value="0" @change="validateConfirmation">
                                    <label for="donation_confirm_text_no">No</label>
                                </div>
                                <span class="text-sm text-danger">{{ errors.donation_confirm }}</span>
                            </div>
                        </div>
                      </div>
                      <div class="row" v-if="donationForm.donation_confirm == '1'">
                        <div class="col-md-12">
                          <label for="select-team" class="form-label"
                                 v-if="fields.donation && fields.donation.disciplines && fields.donation.disciplines.label">{{
                              fields.donation.disciplines.label
                            }}</label>
                          <!--<small class="text-danger">You will be e-mailed a receipt at the end of transaction tax purposes</small>-->
                          <div class="form-group">
                            <!-- <label for="select-team" class="form-label">Make a donation to the following sport discipline fund</label> -->
                            <select class="form-control" id="select-team" v-model="donationForm.donation_discipline"
                                    name="donationForm.donation_discipline">
                              <option v-for="(SportDiscipline, id) in  sortedDonations(listOfDonationSportDiscipline)" :value="SportDiscipline.id"
                                      :key="id">{{ SportDiscipline.name }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.donation_discipline }}</span>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <label for="donation-amount" class="form-label">Donation Amount (USD)</label>
                          <input type="number" class="form-control" maxlength="13" id="donation-amount"
                                 v-model="donationForm.donation_amount"
                                 name="donationForm.donation_amount" placeholder="10.00" min="1">
                          <span class="text-sm text-danger">{{ errors.donation_amount }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div v-show="wiz_step5" class="col-md-12">
                <div class="card waiver" v-for="(waiver, waiver_index) in waiverData" :key="'waiver_'+waiver_index">
                  <div :id="waiver_index">
                    <div v-show="waiversList[waiver_index] == true" :data-value="waiversList[waiver_index]">
                      <div v-if="waiver.type == 'SafeSport'" class="card-header register-title">
                        <h3>Safe Sport Acknowledgment </h3>
                      </div>
                      <div v-else-if="membership.member_type_id == 4 && waiver.type != 'SafeSport'"
                           class="card-header register-title">
                        <h3>Club Agreement </h3>
                      </div>
                      <div v-else class="card-header register-title">
                        <h3>Participant Waiver and Release of Liability, Assumption of Risk and Indemnity Agreement</h3>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <form @submit.prevent="waiverFormSubmit" ref="waiverFormSubmit" name="waiverFormSubmit"
                                method="post">
                            <div class="col-md-12 text-justify">
                              <div class="text-center mb-1" v-if="waiver.type != 'SafeSport'">
                                <strong>This <span v-if="membership.member_type_id == 4">club agreement</span><span
                                    v-else>waiver</span> is for your membership effective from
                                  {{ waiver.user_info[0].specific_event_start_date }} to {{ waiver.user_info[0].expires_at }}</strong>
                              </div>
                              <!--                                                                        <div class="text-center mb-1" v-if="membership.member_type_id != 4"><strong>Member name : {{ waiver.user_info.member_name }}</strong></div>-->
                              <!--                                                                        <div  class="text-center mb-1" v-else><strong>Club name : {{ verifyClubInfoData.name }}</strong></div>-->
                              <div v-html="waiver.waivers_content"></div>
                              <div v-if="subdomain == 'waterski'">
                                <div v-if="membership.member_type_id == 4">
                                  <span class="mb-2" style="display:block;">I hereby acknowledge that:</span>
                                  <ul>
                                    <li>I am authorized to sanction an event on behalf of the Local Organizing Club
                                      (LOC);
                                      and
                                    </li>
                                    <li>I have reviewed the sanctioning agreement and that the LOC agrees to its terms.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="" v-for="(waiver_user_info, waiver_user_info_index) in waiver.user_info"
                                   :key="waiver_user_info_index">
                                <input type="checkbox" class=""
                                       :id="waiverDataAgree[waiver_index].agree[waiver_user_info_index]"
                                       :name="waiverDataAgree[waiver_index].agree[waiver_user_info_index]"
                                       v-model="waiverDataAgree[waiver_index].agree[waiver_user_info_index]">
                                <div class="waiverinner">
                                  <input v-if="membership.member_type_id != 4" class="waivertext" type="text"
                                         :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials"
                                         :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials"
                                         v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials">
                                </div>
                                <label v-if="membership.member_type_id != 4" class="form-check-label dinline">I,
                                  <span
                                      v-if="waiver.type == 'Minor' && membership.id == 2 || (waiver.type == 'SafeSport' && membership.id == 2 && waiverData[0].type =='Minor')">
                                                                    <input class="waivertext fullnameofwaiver"
                                                                           type="text"
                                                                           :id="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member"
                                                                           :name="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member"
                                                                           v-model="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member">
                                                                </span>
                                  <span
                                      v-else-if="waiver.type == 'Minor'">{{
                                      waiver.user_info[waiver_user_info_index].signed_member
                                    }}</span>
                                  <span v-else>{{ waiver.user_info[waiver_user_info_index].member_name }}</span>

                                  <span v-if="waiver.type == 'SafeSport'">, have read and accept the {{
                                      prop_general.site_name
                                    }} Safesport Acknowledgment dated on: </span>
                                  <span v-else-if="waiver.type == 'Minor'">, accept the above waiver for {{
                                      waiver.user_info[waiver_user_info_index].member_name
                                    }} on this date: </span>
                                  <span v-else>, accept the above waiver on this date: </span>
                                  <input type="text" class="datepicks" disabled :value="currentDate"></label>
                                <label v-else class="form-check-label">I
                                  <!-- <input type="text" maxlength="2" v-on:keypress="isLetter($event)" style="width: 40px" :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials"> -->
                                  have read and accept the {{ prop_general.site_name }} club agreement dated on: <input
                                      type="text" class="datepicks" disabled :value="currentDate"></label>
                                <!-- <p class="font-13 mt-4 text-danger"><em>Please check the box and initial in the space provided.</em></p> -->
                                <p class="text-sm text-danger mt-3">
                                  {{ waiverErrors[waiver_index].agree[waiver_user_info_index] }}</p>
                                <p class="text-sm text-danger mt-3">
                                  {{ waiverErrors[waiver_index].initials[waiver_user_info_index] }}</p>
                                <p class="text-sm text-danger mt-3">
                                  {{ waiverErrors[waiver_index].signed_member[waiver_user_info_index] }}</p>
                              </div>
                              <div class="row mt-3" v-if="membership.member_type_id == 4">
                                <div class="col-md-12">
                                  <label><strong>Submitted by</strong></label>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">First Name</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder="Dickerson"
                                           name="clubSubmittedBy.first_name" v-model="clubSubmittedBy.first_name">
                                    <span class="text-sm text-danger">{{
                                        waiverClubErrors.club_submitted_first_name
                                      }}</span>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">Last Name</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder="Macdonald"
                                           name="clubSubmittedBy.last_name" v-model="clubSubmittedBy.last_name">
                                    <span class="text-sm text-danger">{{
                                        waiverClubErrors.club_submitted_last_name
                                      }}</span>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">Club Position</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder="President"
                                           name="clubSubmittedBy.position" v-model="clubSubmittedBy.position">
                                    <span class="text-sm text-danger">{{
                                        waiverClubErrors.club_submitted_position
                                      }}</span>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="form-label">Email</label>
                                    <input type="text" class="form-control" maxlength="45" placeholder=""
                                           name="clubSubmittedBy.email" v-model="clubSubmittedBy.email">
                                    <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_email }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="wiz_step6" class=" payment-view">
                <div class="row">
                  <b-overlay :show="showLoader" no-wrap></b-overlay>
                  <div class="col-md-6">
                    <div class="card-body card">
                      <div class="row">
                        <div class="col-md-12">
                          <h3>Total Amount</h3>
                        </div>
                        <div class="col-md-12">
                          <ul class="list-group mb-3 payment-details">
                            <li class="d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>{{ membership.type | filterBr }} Membership</strong></h6>
                              </div>
                              <span class="text-muted"><strong>${{ membership.fee | fullNumber }}</strong></span>
                            </li>
                            <li v-if="donated.type != ''" class=" d-flex justify-content-between lh-sm">
                              <div class="cartitemcard">
                                <span class="remove" @click="removeDonation()"><i class="fas fa-trash-alt"></i></span>
                                <h6 class="my-0" v-if="donated.type != ''">{{ donated.type }}</h6>
                              </div>

                              <span class="text-muted"><strong>${{ donated.amount }}</strong></span>
                            </li>
                            <template v-for="(vinsurance,index) in clubInfoData.insured_members">
                              <li v-if="clubInfoData.insured_members.length > 0"
                                  class="d-flex justify-content-between lh-sm" :key="'vcf_' +index ">
                                <div>
                                  <h6 class="my-0"><strong>Insurance - {{
                                      vinsurance.company_name || (vinsurance.first_name + " " + vinsurance.last_name)
                                    }}</strong></h6>
                                </div>
                                <span class="text-muted"><strong>${{ insuranceFee | fullNumber }}</strong></span>
                              </li>
                            </template>
                            <hr v-if="clubInfoData.club_sanction_data && clubInfoData.club_sanction_data.selected && clubInfoData.club_sanction_data.selected == true && this.eligibleForPurchaseSanction == 1 && this.practiceExbhition == 'yes' && this.practice_and_sanction">
                            <li v-if="clubInfoData.club_sanction_data && clubInfoData.club_sanction_data.selected && clubInfoData.club_sanction_data.selected == true && this.eligibleForPurchaseSanction == 1 && this.practiceExbhition == 'yes' && this.practice_and_sanction"
                                class="d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>{{ fields.club_sanction_fields.title || '' }} </strong></h6>
                              </div>
                              <span class="text-muted"><strong> ${{ sanctionFee | fullNumber }}</strong></span>
                            </li>
                            <hr>
                            <li class=" d-flex justify-content-between  ">
                              <div class="coupon-inner">
                                <h6 class="my-0"><strong>Coupon</strong></h6>
                              </div>
                              <div class="">
                                <input type="text" class="form-control coupon-text" v-model="couponApplied.coupon_code">
                                <button @click.prevent="applyCoupon()" class="btn-apply coupon-btn">{{
                                    button.coupon
                                  }}
                                </button>
                                <span class="text-sm text-danger">{{ couponErrors.error }}</span>
                                <span class="text-sm text-success">{{ couponErrors.success }}</span>
                              </div>
                            </li>
                            <li v-if="couponApplied.status == 1" class="d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>Discount - {{ couponApplied.text }} </strong></h6>
                              </div>
                              <span class="text-muted"><strong>-${{ couponApplied.amount | fullNumber }}</strong></span>
                            </li>
                            <hr>
                            <li class="d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>Sub Total</strong></h6>
                              </div>
                              <span class="text-muted"><strong>${{ totals.sub_total }}</strong></span>
                            </li>
                            <hr>
                            <template v-if="processingFeePercent">
                              <li class=" d-flex justify-content-between lh-sm">
                                <div class="processing-tooltip">
                                  <h6 class="my-0"><strong>Technology/Processing Fee ({{ this.processingFeePercent }}%)
                                    <b-button v-b-tooltip.hover
                                              title="This small fee enables USA-WSWS to make technology upgrades to enhance your membership experience.">
                                      <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                           alt="tooltipicon"/></b-button>
                                  </strong></h6>
                                </div>
                                <span class="text-danger"><strong>${{ processingFee }}</strong></span>
                              </li>
                              <hr>
                            </template>
                            <li class=" d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>Grand Total</strong></h6>
                              </div>
                              <span class="text-danger"><strong>${{ totals.grand_total }}</strong></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="card-body card">
                      <div class="row">
                        <div class="col-md-6">
                          <h3>Club Details</h3>
                        </div>
                        <!--                                                    <div class="col-md-6 text-right">
                                        <div class="modal-btn">
                                            <b-button v-b-modal.modal-club-edit @click.prevent="personalEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                        </div>
                                    </div>-->
                      </div>
                      <div class="row">
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.clubname">
                          <label>{{ fields.club_info ? fields.club_info.clubname.label : 'Club Name' }}</label>
                          <span>{{ clubInfoData.name }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_website">
                          <label>{{ fields.club_info ? fields.club_info.club_website.label : 'Club Website' }}</label>
                          <span>{{ clubInfoData.website }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_visible_type">
                          <label>{{ fields.club_info ? fields.club_info.club_visible_type.label : 'Club Type' }}</label>
                          <span>{{ clubInfoData.visibility_type }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_type">
                          <label>{{ fields.club_info ? fields.club_info.club_type.label : 'Club Type' }}</label>
                          <span>{{ clubInfoData.club_type }}</span>
                        </div>
                        <div class="col-md-6" v-if="fields.club_info && fields.club_info.primary_club_type">
                          <label>Club Type</label>
                          <span>{{ clubType[clubInfoData.type] }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-body card">
                      <div class="row">
                        <template v-if="paymentForm.visible">
                          <div class="col-md-12 card-info">
                            <h3>Card Information</h3>
                            <div class="text-right">
                              <!--                                                                            <img v-bind:src="'/dist/img/memberregistration/stripe.png'" alt="stripe">-->
                            </div>
                          </div>
                          <template v-if="getSiteProps('general.paymentGateway') == 'stripe'">
                            <div class="col-md-12">
                              <div class="form-group mb-0">
                                <label class="form-label">Card Number / Expiry Month and Year / CVC<sup
                                    class="text-danger">*</sup></label>
                                <stripe-element-card
                                    v-show="wiz_step6"
                                    ref="stripeForm"
                                    :pk="stripeKey"
                                    :hidePostalCode="true"
                                    iconStyle="solid"
                                    @token="stripeTokenCreated"
                                    @error="captureStripeErrors"
                                    @element-change="stripeFormChanges"
                                />
                                <!-- <span class="text-sm text-danger" v-if="stripeError">{{ stripeError }}</span> -->
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                                <input type="hidden" name="paymentForm.amount" v-model="paymentForm.amount">
                                <input type="hidden" name="paymentForm.registration_id"
                                       v-model="paymentForm.registration_id">
                                <input type="text" maxlength="16" placeholder="#### #### #### ####" class="form-control"
                                       name="paymentForm.cardNumber" v-model="paymentForm.cardNumber"
                                       @keypress="isNumber($event)">
                                <!--                                                                                <input class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" v-cardformat:formatCardNumber>-->
                                <span class="text-sm text-danger">{{ errors.cardNumber }}</span>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
                              <select class="form-control form-select" id="expiration-month" required=""
                                      name="paymentForm.expiration_month" v-model="paymentForm.expiration_month">
                                <option value="">Month</option>
                                <option>01</option>
                                <option>02</option>
                                <option>03</option>
                                <option>04</option>
                                <option>05</option>
                                <option>06</option>
                                <option>07</option>
                                <option>08</option>
                                <option>09</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.expiration_month }}</span>
                            </div>
                            <div class="col-md-3 pl-0 pr-0 col-md-offset-1" style="margin-top: 30px;">
                              <select class="form-select form-control" id="expiration-year" required=""
                                      name="paymentForm.expiration_year" v-model="paymentForm.expiration_year">
                                <option value="">Year</option>
                                <option v-for="year in yearArray" :value="year" :key="year">{{ year }}</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.expiration_year }}</span>
                            </div>
                            <div class="col-md-3">
                              <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                              <input type="password" class="form-control" name="paymentForm.cvv" maxlength="4"
                                     v-model="paymentForm.cvv" @keypress="isNumber($event)">
                              <span class="text-sm text-danger">{{ errors.cvv }}</span>
                            </div>
                          </template>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                              <input type="text" placeholder="William" class="form-control" id="billing_firstname"
                                     name="paymentForm.billing_firstname" v-model="paymentForm.billing_firstname">
                              <span class="text-sm text-danger">{{ errors.billing_firstname }}</span>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="getSiteProps('general.club_auto_renewal_option')">
                            <div class="form-check mt-3">
                              <input type="checkbox" id="autorenewall" class="form-check-input" value="1"
                                     v-model="paymentForm.auto_renew" checked>
                              <label for="autorenewall" class="form-check-label light-font"> Membership fees will
                                auto-renew and credit card will be charged upon expiration</label>
                              <span class="text-sm text-danger">{{ errors.auto_renew }}</span>
                            </div>


                          </div>
                          <hr/>
                          <div class="col-md-12">
                            <h3>Billing Address</h3>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Mailing Address<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" maxlength="45"
                                     name="paymentForm.billing_addressline1" v-model="paymentForm.billing_addressline1">
                              <span class="text-sm text-danger">{{ errors.billing_addressline1 }}</span>
                            </div>
                          </div>
                          <div class="col-md-6 pr-0">
                            <div class="form-group">
                              <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" name="paymentForm.billing_zip"
                                     v-model="paymentForm.billing_zip">
                              <span class="text-sm text-danger">{{ errors.billing_zip }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>City<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_city"
                                     v-model="paymentForm.billing_city">
                              <span class="text-sm text-danger">{{ errors.billing_city }}</span>
                            </div>
                          </div>
                          <div class="col-md-6 pr-0" v-if="paymentForm.billing_country == 'USA'">
                            <div class="form-group">
                              <label>State<sup class="text-danger">*</sup></label>
                              <select class="form-control" id="state_code" name="paymentForm.billing_state_code"
                                      v-model="paymentForm.billing_state_code">
                                <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                        :value="listOfState.state_code"
                                        :selected="paymentForm.billing_state_code == listOfState.state_code">
                                  {{ listOfState.state }}
                                </option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.billing_state_code }}</span>
                            </div>
                          </div>
                          <div class="col-md-6 pr-0" v-else-if="paymentForm.billing_country == 'Canada'">
                            <div class="form-group">
                              <label>State<sup class="text-danger">*</sup></label>
                              <select class="form-control" id="state_code" name="paymentForm.billing_state"
                                      v-model="paymentForm.billing_state">
                                <option v-for="listOfState in listOfCAStates" :key="listOfState.state_code"
                                        :value="listOfState.state"
                                        :selected="paymentForm.billing_state_code == listOfState.state">
                                  {{ listOfState.state }}
                                </option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.billing_state }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-else>
                            <div class="form-group">
                              <label for="state_code" class="form-label">State</label>
                              <input type="text" class="form-control" maxlength="45" v-model="paymentForm.billing_state"
                                     name="paymentForm.billing_state">
                              <span class="text-sm text-danger">{{ errors.billing_state }}</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Country<sup class="text-danger">*</sup></label>
                              <select class="form-control" @change="removeStateCode" id="country" v-model="paymentForm.billing_country"
                                      name="paymentForm.billing_country">
                                <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                        :value="listOfCountry.country"
                                        :selected="paymentForm.billing_country == listOfCountry.country">
                                  {{ listOfCountry.country }}
                                </option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.billing_country }}</span>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="this.getSiteProps('general.payment_fee_info')">
                            <p class="mt-3 font-13" style="font-size:11px;margin-bottom:0;"><i>All transactions also
                              include a 3% processing/technology fee.</i></p>
                          </div>
                          <hr/>
                        </template>
                        <div class="col-md-12 payment-conf confirmsc">
                          <h3>Confirm</h3>
                          <!-- <p style="font-13">By submitting this information, you agree to the <router-link class-active="active" target="_blank" to="/privacy-policy">privacy policy</router-link> and <router-link target="_blank" class-active="active" to="/terms-and-conditions">Terms &amp; Conditions</router-link> policy, including
                                            AUTO-RENEWAL and understand that there are NO REFUNDS. </p> -->

                          <p style="font-13" v-if="prop_confirmation.label" v-html="prop_confirmation.label"></p>
                          <!--                                                                            <p>If you do not agree to the terms, please click on Cancel</p>-->
                          <div class="form-check ">
                            <input type="checkbox" id="payment_agree" class="form-check-input"
                                   v-model="paymentForm.agree" name="paymentForm.agree">
                            <label for="payment_agree" class="form-check-label">
                              <input type="text" class="chkboxinput" v-model="paymentForm.sign" name="paymentForm.sign"
                                     maxlength="2" @keypress="isAlphabets($event)">
                              <span :style="{ 'display': 'inline-block' }" v-if="prop_confirmation.agree"
                                    v-html="prop_confirmation.agree"></span>
                            </label>
                            <span v-if="prop_confirmation.note" v-html="prop_confirmation.note"></span>
                            <span class="text-sm text-danger">{{ errors.payment_agree }}</span>
                            <span class="text-sm text-danger">{{ errors.payment_sign }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="wiz_step7" class=" confirmation">
                <div class="col-md-12">
                  <div class="card-body confirmation">
                    <div class="row">
                      <div class="col-md-12" style="padding:100px 0;" v-if="registration_message_flag">
                        <h3 class="text-center text-success"> {{ this.registration_message_text.title }}</h3>
                        <div class="form-group text-center" v-html="this.registration_message_text.content">

                        </div>
                      </div>
                      <div class="col-md-12" style="padding:100px 0;" v-else>
                        <h3 class="text-center text-success">Thanks for the Registration.</h3>
                        <div class="form-group text-center">
                          <!--                                                        <label class="form-label">Transaction Id</label>-->
                          <span>{{ paymentData }}</span>
                          <p>You will receive an email shortly regarding this transaction along with your receipt and
                            membership information.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-12 text-right mt-3">
                <template v-for="(pri_error,index) in primaryErrors">
                  <div class="text-sm text-danger" v-if="primaryErrors.length > 0" :key="'pe_'+index">{{
                      pri_error
                    }}
                  </div>
                </template>
              </div> -->
              <div class="col-md-12" v-show="overAllError != 0">
              <p class="text-danger text-right">Please fill the above missing fields.</p>
              </div>   
             
              <div class="col-md-12 text-right mt-2 mb-3">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <b-overlay :show="showLoader" no-wrap></b-overlay>
                  <button type="button" v-if="wiz_step != 3 && wiz_step != registerTotalstep"
                          @click.prevent="wizardPrevstep" class="btn btn-outline-primary cancelbtn btn-previious  ">
                    Previous Step
                  </button>
                  <button type="button" v-if="wiz_step != registerTotalstep" @click.prevent="wizardNextstep"
                          class="btn btn-secondary btnnextstp btndisabled btn-next-success">
                    {{ button.nextStepButton }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <member-lookup @member-lookup-data="ChangeClubMember"  :editContactType="editContactType"></member-lookup>

  </div>
</template>

<script>
import moment from "moment";


const validatePaymentName = (name, fieldTxt) => {
  if (name == undefined) {
    return {valid: false, error: fieldTxt + " is required"};
  } else if (!name.length) {
    return {valid: false, error: fieldTxt + " is required"};
  }
  return {valid: true, error: null};
};
/*const validateStateName = name => {
    console.log('v state');
    console.log(name);
    if (name == null || name == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateSelect = select => {
    console.log('lenghth='+select);
    if (!select) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};*/
const validateWaiverInitials = name => {
  if (!name) {
    return {valid: false, error: "Please enter your Initials to proceed"};
  } else {
    if (name.length != 2) {
      return {valid: false, error: "You must enter two letters to initial"};
    }
  }
  return {valid: true, error: null};
};
const validateWaiverCheckbox = checkbox => {
  console.log("resss=" + checkbox);
  if (!checkbox) {
    return {valid: false, error: "Please acknowledge by checking the box"};
  }
  return {valid: true, error: null};
};
const validateRadio = (radio, fieldTxt) => {
  if (!radio) {
    return {valid: false, error: fieldTxt+ " is required"};
  }
  return {valid: true, error: null};
};
const validateName = (name, fieldTxt) => {
  if ( name == undefined || !name.length || name.trim() == "") {
    return {valid: false, error: fieldTxt+ " is required"};
  }
  return {valid: true, error: null};
};
const validateNonMandatoryZip = (zipCode) => {
  if(zipCode != undefined && zipCode.length && zipCode.trim() != "" && !zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: "Zip Code Must Be Atleast 5 Character." };
  }
  return { valid: true, error: null };
};
const validateStateName = name => {
  console.log('v state');
  console.log(name);
  if (name == null || name == "") {
    return {valid: false, error: "This field is required"};
  }
  return {valid: true, error: null};
};
const validateCardNumber = (cardNumber, fieldTxt)  => {
  if (!cardNumber.length || cardNumber == "") {
    return {valid: false, error: fieldTxt +" is required"};
  }
  if ((cardNumber.replace(/\s/g, '')).length < 14) {
    return {valid: false, error: "Card number must be greater than 13 digits"};
  }
  return {valid: true, error: null};
};
const validateCvv = (cvv, fieldTxt) => {
  if (!cvv.length || cvv == "") {
    return {valid: false, error: fieldTxt+ " is required"};
  }
  if (!cvv.match(/^[0-9]{3,4}$/)) {
    return {valid: false, error: "CVV must be 3 or 4 digits"};
  }
  return {valid: true, error: null};
};
const validateSelect = (select, fieldTxt) => {
  if (select == "" || select == null) {
    return {valid: false, error: fieldTxt+ " is required"};
  }
  return {valid: true, error: null};
};
const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "This field is required"};
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return {valid: false, error: "Please enter a valid email."};
  }
  return {valid: true, error: null};
};
const validateDob = dob => {
  if (!dob.length) {
    return {valid: false, error: "This field is required"};
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
    return { valid: false, error: "Please, enter a valid data of birth." };
    }*/
  return {valid: true, error: null};
};
const validateGender = gender => {
  if (!gender) {
    return {valid: false, error: "This field is required"};
  }
  return {valid: true, error: null};
};
// Insurance
const validateInsuranceName = (name, fieldTxt) => {
  if (name == undefined) {
    return {valid: false, error: fieldTxt+ " is required"};
  } else if (!name.length) {
    return {valid: false, error: fieldTxt+ " is required"};
  }
  return {valid: true, error: null};
};
const validateInsuranceFirstName = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateInsuranceLastName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (name == undefined || !name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  if (!name.match(/^[a-zA-Z-\s]*$/))
  {

    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};

//Insurance end
const validateSignedUserName = (name, fieldName = 'This field') => {
  if (name == undefined) {
    return {valid: false, error: fieldName + " is required"};
  } else if (name == "") {
    return {valid: false, error: fieldName + " is required"};
  }
  return {valid: true, error: null};
};
const validateSignedFirstName = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateSignedLastName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (name == undefined || !name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  if (!name.match(/^[a-zA-Z-\s]*$/))
  {

    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};
const validateCheck = choices => {
  if (choices.length) return {valid: true, error: null};
  return {valid: false, error: "This field is required"};
}
const validatePhone = (phone, validTelinput, phoneField = '') => {
  let phoneText = (phoneField != '' ? phoneField : "This field");
  if (!phone.length) {
    return {valid: false, error: phoneText + ' is required .'};
  }
  if (validTelinput == false && phone.length) {
    return {valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).'};
  }
  return {valid: true, error: null};
}
const validateAmount = (name,fieldTxt) => {
  if (name == undefined || !name.length) {
    return {valid: false, error: fieldTxt+ " is required"};
  } else if (name == '') {
    return {valid: false, error: fieldTxt+ " is required"};
  }
  return {valid: true, error: null};
};

const validateZip = (zipCode) => {
  if ( zipCode == undefined || !zipCode.length || zipCode.trim() == "") {    
    return {valid: false, error: "Zip code is required"};
  }
  else if (!zipCode.match(/^\d{5}(-\d{4})?$/)) {
    return {valid: false, error: "Zip Code Must Be Atleast 5 Character"};
  }
   else if (zipCode.length < 5) {
    return {valid: false, error: "Zip code should be 5 character"};
  }
  return {valid: true, error: null};
}



const validateBillingzip = (zipCode, country) => {
    if(!zipCode.length)
    {
         return { valid: false, error: "Zip code is required" };
    }
    else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
        return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
    }
    else if(zipCode.length < 5  ){
         return { valid: false, error: "Zip code should be 5 character" };
    }
      return { valid: true, error: null };
}






import MemberLookup from "./components/MemberLookup";
import axios from 'axios';

import {StripeElementCard} from "@vue-stripe/vue-stripe";

export default {
  name: "memberrenewal",
  components: {
    MemberLookup,
    StripeElementCard
  },
  data() {
    return {     
      dateVar:'12/31/'+new Date().getFullYear(),
      practice_and_sanction:false, 
      overAllError: 0,
      yearArray: [],
      eligibleForPurchaseSanction:0,
      newRegId: '',
      loading: true,
      updateKey: 0,
      prsidentInfoData: '',
      presidentError: '',
      vprsidentInfoData: '',
      vpresidentError: '',
      treasurerInfoData: '',
      treasurerError: '',
      secretaryInfoData: '',
      secretaryError: '',
      juniorInfoData: '',
      juniorError: '',
      skiDirectorsData: '',
      skiDirectorsError: '',
      askiDirectorsData: '',
      askiDirectorsError: '',
      safeSportCoordinatorData: '',
      safeSportCoordinatorError: '',
      insuranceData: '',
      insuranceError: '',
      practiceExbhition: '',
      practiceExbhitionError: '',
      edit: false,
      stripeToken: null,
      stripeError: "",
      stripeValidate: false,
      validTelinput: false,
      processingFeePercent: 0,
      processingFee: 0,
      insuranceFee: 50.00,
      membershipCost: 125.00,
      sanctionFee: 50.00,
      wiz_step: 3,
      registerTotalstep: 7,
      clubInfo: {
        primary_contact: {},
        junior_development: [],
        team_sites: [],
        president_info: {
          membership_id: '',
          first_name: '',
          last_name: '',
          city: '',
          state_code: '',
          state: '',
          is_primary: ''
        },
        secretary_info: {
          membership_id: '',
          first_name: '',
          last_name: '',
          city: '',
          state_code: '',
          state: ''
        },
        treasurer_info: {
          membership_id: '',
          first_name: '',
          last_name: '',
          city: '',
          state_code: '',
          state: ''
        },
        vp_info: {
          membership_id: '',
          first_name: '',
          last_name: '',
          city: '',
          state_code: '',
          state: ''
        },
        safe_sport_coordinator: {
          membership_id: '',
          first_name: '',
          last_name: '',
          city: '',
          state_code: '',
          state: ''
        },
        ski_directors: [],
        assistant_skidirectors: [],
        insured_members: []

      },
      verifyClubInfoData: {},
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('clubToken')
      },
      tblSitesFields: [
        {key: 'school', sortable: true, label: 'Specific site/body of water of all club activities'},
        {key: "primary_club", sortable: true, label: "Primary Site"},
        {key: 'address', sortable: true, label: 'Physical Address'},
        {key: 'city', sortable: true, label: 'City'},
        {key: 'state_code', sortable: true, label: 'State'},
        {key: 'zip', sortable: false, label: 'Zip'},
        {key: 'actions', sortable: false},
      ],
      tblFields: [
        {key: 'membership_id', sortable: true, label: 'Member #'},
        {key: 'first_name', sortable: true},
        {key: 'last_name', sortable: true},
        {key: 'city', sortable: true},
        {key: 'state', sortable: true},
        {key: 'zip', sortable: false, label: 'Zip'},
        {key: 'memStatus', sortable: false, label: 'Membership Status'},
        {key: 'waiverStatus', sortable: false, label: 'Waiver Status'},
        {key: 'ssStatus', sortable: false, label: 'Safe Sport Training Status'},
        {key: 'bgStatus', sortable: false, label: 'Background Screen Status'},
        {key: 'actions', sortable: false},
      ],
      tblInsuredFields: [
        {key: 'company_name', sortable: true, label: 'Company Name'},
        {key: 'first_name', sortable: true},
        {key: 'last_name', sortable: true},
        {key: 'actions', sortable: false},
        {key: 'renew', sortable: false},
      ],
      tblSanctionFields: [
        {key: 'practise_with_guest', sortable: true, label: 'Practise with Guest'},
        {key: 'practise_without_guest', sortable: true, label: 'Practise with Guest'},
        {key: 'exhibition', sortable: true, label: 'Exhibition'},
        {key: 'actions', sortable: false},
      ],
      regId: '',
      clubInfoData: {
        membership_id: '',
        name: '',
        type: '',
        secondary_type: '',
        website: '',
        exclude: '',
        email: '',
        address: '',
        city: '',
        state_code: '',
        zip: '',
        phone_1: '',
        members: [],
        insured_members: [],
        team_sites: [],
        id: '',
        club_sanction: this.club_sanction,
        club_sanction_data: {
          selected: this.club_sanction,
          practise_with_guest: 0,
          practise_without_guest: 0,
          exhibition: 0,
          practise_agree: 0,
          exhibition_agree: 0,
          practise_sign_name: '',
          exhibition_sign_name: '',
          club_id: ''
        },
        visibility_type: "",
        club_type: "",
        club_disciplines: [],
        club_admin_member_id: "",
        club_admin_name: "",
        primary_contact: {},
        physical_address: '',
        physical_city: '',
        physical_state: '',
        physical_state_code: '',
        physical_zip: '',

        donation_id: '',
        donation_amount: '',

        coupon_id: null,
        discount_amount: 0.00
      },
      membership: {
        "id": '',
        "type": '',
        "fee": 0.00,
        "member_type_id": ""
      },
      donated: {
        "type": '',
        "amount": 0.00,
      },
      couponApplied: {
        status: 0,
        coupon_code: '',
        amount: 0.00,
        text: ''
      },
      errors: {},
      insuranceErrors: {},
      primaryErrors: [],
      clubAddresses: [],
      address: {
        school: '',
        is_primary: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        state_code: '',
        country: '',
        zip: '',
        index: '',
        extId: '',
        primary_club: ''
      },
      contactType:"",
      editContactType:"",
      clubContactList: {
        primary_contact: {},
        junior_development: [],
        president_info: {},
        secretary_info: {},
        treasurer_info: [],
        vp_info: {},
        ski_directors: [],
        assistant_skidirectors: [],

      },
      button: {
        nextButton: 'Next',
        nextStepButton: 'Next Step',
        coupon: 'Apply Coupon'
      },
      totals: {
        "merchandise_total": 0.00,
        "sub_total": 0.00,
        "grand_total": 0.00,
      },
      waiverDataAgree: [{
        agree: []
      }, {
        agree: []
      }, {
        agree: []
      }, {
        agree: []
      }],
      waiverForm: {
        waivers_id: '',
        agree: '',
        initials: '',
      },
      insuranceEdit: false,
      insuranceEditKey: 0,
      insuranceForm: {
        id: '',
        first_name: '',
        last_name: '',
        company_name: '',
        insurance_type: '',
        home_phone: '',
        home_phone_country_code: '',
        home_phone_extension: '',
        work_phone: '',
        work_phone_country_code: '',
        work_phone_extension: '',
        fax: '',
        address1: '',
        address2: '',
        city: '',
        state_code: '',
        state: '',
        zip: '',
        country: '',
        certificate_type: '',
        sanctioned_type: '',
        event_sanction: '',
        specific_event_title: '',
        specific_event_start_date: '',
        specific_event_end_date: '',
        team_has_any_agreement: '',
        additional_insurance: '',
        additional_insurance_member: [],
        index: ''
      },
      paymentForm: {
        "stripeToken": "",
        "renew": 1,
        "cardNumber": '',
        "expiration_year": '',
        "expiration_month": '',
        "cvv": '',
        "amount": '',
        "owner": "Integrass",
        "name_on_card": "",
        "registration_id": '',
        "billing_firstname": "",
        "billing_lastname": "",
        "billing_company": "Integrass",
        "billing_addressline1": "",
        "billing_zip": "",
        "billing_state": "",
        "billing_state_code": "",
        "billing_city": "",
        "billing_country": "",
        "billing_phone1": "88285224852",
        auto_renew: '',
        visible: true
      },
      currentDate: moment().format("MM/DD/YYYY H:mm"),
      waiverData: {},
      waiverType: true,
      signedWaiverData: {
        registration_id: '',
        waivers: []
      },
      waiverErrors: [{
        agree: [],
        initials: [],
        signed_member: []
      }, {
        agree: [],
        initials: [],
        signed_member: []
      }, {
        agree: [],
        initials: [],
        signed_member: []
      }],
      currentWaiver: 0,
      waiversList: [],
      clubSubmittedBy: {
        first_name: '',
        last_name: '',
        position: '',
        email: ''
      },
      waiverClubErrors: {},
      loadWaiver: true,
      wiz_step3: true,
      wiz_step4: false,
      wiz_step5: false,
      wiz_step6: false,
      wiz_step7: false,
      options: {placeholder: "Phone Number"},
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Home Number",
        maxLen: 20,
      },
      workProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Mobile Number",
        maxLen: 20,
      },
      faxProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Mobile Number",
        maxLen: 20,
      },
      clubSancError: '',
      currentAgreement: 1,
      paymentData: '',
      registration_message_flag: false,
      registration_message_text: [],
      clubForm: {
        name: '',
        type: '',
        secondary_type: '',
        website: '',
        exclude: '',
        email: '',
        address: '',
        country: '',
        city: '',
        state_code: '',
        zip: '',
        club_sanction: this.club_sanction,
        club_sanction_data: {
          selected: false,
          practise_with_guest: 0,
          practise_without_guest: 0,
          exhibition: 0,
          practise_agree: 0,
          exhibition_agree: 0,
          practise_sign_name: '',
          exhibition_sign_name: '',
          club_id: ''
        },

        retype_email: "",

        visibility_type: "",
        club_type: [],
        club_disciplines: [],
        club_admin_member_id: "",
        club_admin_name: "",
        same_as_mailing_address: "",
        primary_contact: {}
      },
      contactForm: {
        email: '',
        retype_email: "",
        same_as_mailing_address: "",
        address: "",
        city: "",
        state: "",
        state_code: "",
        zip: "",
        phone_1: "",
        physical_address: "",
        physical_city: "",
        physical_state: "",
        physical_state_code: "",
        physical_zip: ""
      },
      couponErrors: {},
      siteValid: false,
      donationForm: {
        donation_amount: '',
        donation_discipline: '0',
        donation_confirm: ""
      },
      notACompany: false,
      fields: {},
      clubAdminSearch: {
        searched: false,
        memberIDSearched: ''
      },
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Mobile Number",
        // maxLen: 30,
      },
      prop_confirmation: {},

      prop_req_junior: "",
      prop_req_director: "",
      prop_req_asst_dir: "",
      prop_req_ssc: "",
      isPrimarySiteAdded: false,
      teamSiteError: '',
      prop_req_coi: ''
    }

  },
  methods: {
    specialEventStartDateValidation(value){
      if(new Date(moment(this.dateVar)).getTime() < new Date(value).getTime()){
        this.valid = false;
        this.insuranceErrors.specific_event_start_date = "Your selected date is exceed of your Expire date";
      }else if(new Date().getTime() > new Date(value).getTime()){
        this.valid = false;
        this.insuranceErrors.specific_event_start_date= "Your selected date is Invalid";
      }else{
        this.insuranceErrors.specific_event_start_date = "";
      }
    },
     specialEventEndDateValidation(value){

      if(new Date(moment(this.dateVar)).getTime() < new Date(value).getTime()){
        this.valid = false;
        this.insuranceErrors.specific_event_end_date = "Your selected date is exceed of your Expire date";
      }else if(new Date().getTime() > new Date(value).getTime()){
        this.valid = false;
        this.insuranceErrors.specific_event_end_date= "Your selected date is Invalid";
      }else if(this.insuranceErrors.specific_event_start_date > new Date(value).getTime()){
        this.valid = false;
        this.insuranceErrors.specific_event_end_date= "Your selected end date is earlier than start date Invalid";
      }else{
        this.insuranceErrors.specific_event_end_date = "";
      }
    },
    validateConfirmation(next=false){
        if(next==false){
          this.donationForm.donation_discipline = '0';
          this.donationForm.donation_amount = '';
        }
        this.errors.donation_confirm = "";
        if(this.donationForm.donation_confirm == ""){
            this.errors.donation_confirm = "Please confirm that you want to make a donation";
            return false;
        }
        return true;
    },
    loadPropReqData() {
      const r1 = setInterval(() => {
        this.prop_req_junior = this.getSiteProps('memberRegistration.club.fields.club_junior');
        if (this.prop_req_junior) clearInterval(r1);
      }, 100);

      const r2 = setInterval(() => {
        this.prop_req_director = this.getSiteProps('memberRegistration.club.fields.club_director');
        if (this.prop_req_director) clearInterval(r2);
      }, 100);

      const r3 = setInterval(() => {
        this.prop_req_asst_dir = this.getSiteProps('memberRegistration.club.fields.club_director');
        if (this.prop_req_asst_dir) clearInterval(r3);
      }, 100);

      const r4 = setInterval(() => {
        this.prop_req_ssc = this.getSiteProps('memberRegistration.club.fields.safe_sport_coordinator');
        if (this.prop_req_ssc) clearInterval(r4);
      }, 100);
      const r5 = setInterval(() => {
        this.prop_req_coi = this.getSiteProps('memberRegistration.club.fields.certificate_of_insurance');
        if (this.prop_req_coi) clearInterval(r5);
      }, 100);
    },
    wizardNextstep: function () {

      if (this.wiz_step == 3) {
        console.log('this steps is equal to  : ' + this.wiz_step);
        this.fillClubData();
        if( !this.validateClub() ) {
          this.$bvModal.show('modal-club-edit');
          return;
        }
        
        if (this.validateYesNo()) {
          this.clubInformationApi();
        }
        return;
      } else if (this.wiz_step == 4) {
        if (this.validateDonation()) {
          this.clubInfoData.donation_id = null;
          this.clubInfoData.donation_amount = 0;

          this.donated.type = "";
          this.donated.amount = 0.00;
          if (this.donationForm.donation_confirm == "1" && this.donationForm.donation_amount && this.donationForm.donation_discipline != "0") {
            this.donated.type = this.donationForm.donation_discipline != "0" ? this.listOfDonationSportDiscipline[this.donationForm.donation_discipline] : "";
            this.donated.amount = parseFloat(this.donationForm.donation_amount).toFixed(2);

            this.clubInfoData.donation_id = parseInt(this.donationForm.donation_discipline);
            this.clubInfoData.donation_amount = parseFloat(this.donationForm.donation_amount).toFixed(2);
          }

          //   this.saveDonation();

          this.calculateTotals()

          this.wiz_step4 = false;
          this.wiz_step5 = true;
          this.wiz_step++;
          return;
        } else {
          console.log('un valid');
        }
      } else if (this.wiz_step == 5) {
        this.signedWaiverData.registration_id = this.regId
        if (this.validateWaiver()) {
          console.log('waiver valid');
          console.log('-----111-------');
          console.log(this.waiverForm);
          console.log('-----2222-------');
          console.log(this.waiverData);
          console.log(this.currentWaiver + ' == ' + this.waiverData.length);
          if (this.currentWaiver == this.waiverData.length) {
            console.log('------------');
            console.log(this.waiverForm);
            this.addFilledWaiverData();
            // this.submitWaiverForm();
            this.wiz_step5 = false;
            this.wiz_step6 = true;
            this.wiz_step++;
          } else {
            this.waiverForm.waivers_id = this.waiverData[this.currentWaiver - 1].waivers_id;
            console.log('-----333-------');
            console.log(this.waiverForm);
            this.signedWaiverData.waivers.push(this.waiverForm);
            this.waiverForm = {};
            this.waiverType = false;
            console.log("regstep=" + this.registerationStep);
            return;
          }
          console.log("regstep=" + this.registerationStep);
          console.log('-----444-------');
          console.log(this.waiverForm);
          console.log('-----555-------');
          if(this.membership.member_type_id == 4 && this.getSiteProps('general.club_auto_renewal_option') == false) document.querySelector(".auto_renew_text_sign").style.display= 'none';
          console.log(this.signedWaiverData);
        } else {
          console.log("regstep=" + this.registerationStep);
          console.log('waiver un valid');
          return;
        }
      } else if (this.wiz_step == 6) {
        if (this.totals.grand_total == 0.0 && this.couponApplied.status == 1) {
          this.submitForm();
          return;
        } else {
          if (this.validatePayment()) {
            console.log("regstep=" + this.wiz_step);
            console.log('payment valid');
            // this.paymentApi();
            console.log("Stripe Form:", this.$refs.stripeForm);
            if (this.$refs.stripeForm != undefined) {
              //Stripe Token generation
              this.showLoader = true;
              this.$refs.stripeForm.submit();
            } else {
              this.submitForm();
            }
            console.log(this.wiz_step);
            return;
          } else {
            console.log("regstep=" + this.wiz_step);
            console.log('payment un valid');
            return;
          }
        }
      } else {
        this.wiz_step++;
      }
    },
    wizardPrevstep: function () {
      //alert('prvious');
      console.log("wizardPrevstep=" + this.wiz_step);
      if (this.wiz_step == 6) {
        this.wiz_step6 = false;
        this.wiz_step5 = true;
        this.currentWaiver--;
      } else if (this.wiz_step == 5) {
        this.wiz_step5 = false;
        this.wiz_step4 = true;
      } else if (this.wiz_step == 4) {
        this.wiz_step4 = false;
        this.wiz_step3 = true;
      }
      this.wiz_step--;
      console.log("wizardPrevstep 1=" + this.wiz_step);
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date < currentDate;
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isAlphabets: function (evt) {

      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log('--' + charCode);
      if (charCode > 64 && charCode < 122) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    deleteRow(id, type) {
      if (type == 0) {
        this.clubInfo.primary_contact.splice(id, 1);
      } else if (type == 1) {
        this.clubInfo.junior_development.splice(id, 1);
        if (Object.keys(this.clubInfo.junior_development).length == 0) {
          this.juniorInfoData = '';
        }
      } else if (type == 2) {
        this.clubInfo.president_info.splice(id, 1);
      } else if (type == 3) {
        this.clubInfo.vp_info.splice(id, 1);
      } else if (type == 4) {
        this.clubInfo.secretary_info.splice(id, 1);
      } else if (type == 5) {
        this.clubInfo.treasurer_info.splice(id, 1);
      } else if (type == 6) {
        this.clubInfo.ski_directors.splice(id, 1);
        if (Object.keys(this.clubInfo.ski_directors).length == 0) {
          this.skiDirectorsData = '';
        }
      } else if (type == 7) {
        this.clubInfo.assistant_skidirectors.splice(id, 1);
        if (Object.keys(this.clubInfo.assistant_skidirectors).length == 0) {
          this.askiDirectorsData = '';
        }
      }
    },
    addInsurance() {
      this.insuranceEdit = false;
      this.$bvModal.show('modal-center6');
      this.insuranceErrors = {};
      this.insuranceForm = {
        id: '',
        first_name: '',
        last_name: '',
        company_name: '',
        insurance_type: '',
        home_phone: '',
        home_phone_country_code: '',
        home_phone_extension: '',
        work_phone: '',
        work_phone_country_code: '',
        work_phone_extension: '',
        fax: '',
        address1: '',
        address2: '',
        city: '',
        state_code: '',
        state: '',
        zip: '',
        country: '',
        certificate_type: '',
        sanctioned_type: '',
        event_sanction: '',
        specific_event_title: '',
        specific_event_start_date: '',
        specific_event_end_date: '',
        team_has_any_agreement: '',
        additional_insurance: '',
        additional_insurance_member: [],
        index: ''
      };
    },
    deleteInsuranceRow(i) {
      this.clubInfo.insured_members.splice(i, 1);
    },
    changeRenewInsurance(i){
      if(this.clubInfo.insured_members[i].isRenew){
        this.editInsuranceRow(i,true);
        if (!this.insuranceValidate()) {
          this.clubInfo.insured_members[i].isRenew = false;
          this.$bvModal.show('modal-center6');
        }
        else{
          this.clubInfo.insured_members[i].isRenew = true;
          this.clubInfo.insured_members[i].fulfilled = 0;
          this.clubInfo.insured_members[i].fulfilled_date = null;
        }
      }
      this.updateKey++;
    },
    editInsuranceRow(i,isRenewValid = false) {
      this.insuranceEdit = true;
      this.insuranceEditKey = i;
      if(!isRenewValid){
        this.$bvModal.show('modal-center6');
      }
      this.notACompany = this.clubInfo.insured_members[i].insurance_type || false;
      this.notACompany = this.notACompany == "1" ? this.notACompany : false;

      this.insuranceForm = {
        id: this.clubInfo.insured_members[i].id,
        first_name: this.clubInfo.insured_members[i].first_name,
        last_name: this.clubInfo.insured_members[i].last_name,
        company_name: this.clubInfo.insured_members[i].company_name,
        insurance_type: this.clubInfo.insured_members[i].insurance_type,
        home_phone: this.clubInfo.insured_members[i].home_phone,
        work_phone: this.clubInfo.insured_members[i].work_phone,
        fax: this.clubInfo.insured_members[i].fax,
        address1: this.clubInfo.insured_members[i].address1,
        address2: this.clubInfo.insured_members[i].address2,
        city: this.clubInfo.insured_members[i].city,
        state_code: this.clubInfo.insured_members[i].state_code,
        state: this.clubInfo.insured_members[i].state,
        zip: this.clubInfo.insured_members[i].zip,
        country: this.clubInfo.insured_members[i].country,
        certificate_type: this.clubInfo.insured_members[i].certificate_type,
        sanctioned_type: this.clubInfo.insured_members[i].sanctioned_type,
        event_sanction: this.clubInfo.insured_members[i].event_sanction,
        specific_event_title: this.clubInfo.insured_members[i].specific_event_title,
        specific_event_start_date: this.clubInfo.insured_members[i].specific_event_start_date,
        specific_event_end_date: this.clubInfo.insured_members[i].specific_event_end_date,
        team_has_any_agreement: this.clubInfo.insured_members[i].team_has_any_agreement,
        additional_insurance: this.clubInfo.insured_members[i].additional_insurance,
        additional_insurance_member: this.clubInfo.insured_members[i].additional_insurance_member,
        index: i
      };
    },
    deleteSiteRow(i, row) {
      console.log(i);
      //console.log(this.clubInfoData.team_sites);
      // this.clubInfo.team_sites.splice(i,1);

      axios.delete(this.basePath + "api/club/ski_site/" + row.item.id, {headers: this.clubHeaders})
          .then(res => this.getActiveRegistration())
          .catch(err => console.log(err));
    },
    ChangeClubMember(data) {
      if (data != null) {
        data.contact_type = this.contactType;
        var newData = Object.assign({}, data);
        if (data.contact_type == 0) {
          this.clubInfo.primary_contact = newData;
        } else if (data.contact_type == 1) {
          this.clubInfo.junior_development.push(newData);
        } else if (data.contact_type == 2) {
          this.clubInfo.president_info = newData;
        } else if (data.contact_type == 3) {
          this.clubInfo.vp_info = newData;
        } else if (data.contact_type == 4) {
          this.clubInfo.secretary_info = newData;
        } else if (data.contact_type == 5) {
          this.clubInfo.treasurer_info = newData;
        } else if (data.contact_type == 6) {
          this.clubInfo.ski_directors.push(newData);
        } else if (data.contact_type == 7) {
          this.clubInfo.assistant_skidirectors.push(newData);
        } else if (data.contact_type == 8) {
          this.clubInfo.safe_sport_coordinator = newData;
        }
      } else {
        this.resetMemberLookup(this.contactType);
      }
    },
    setContactType(type) {
      this.contactType = type;
      this.editContactType = type;
    },
    resetModalClubSite() {
      this.address = {
        school: '',
        is_primary: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        state_code: '',
        country: '',
        zip: '',
        index: '',
        extId: '',
        primary_club: ''
      }

    },
    handleOkClubSite(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleClubSite()
    },
    handleClubSite() {
      // Exit when the form isn't valid
      if (!this.siteValidate()) {
        return;
      }
      this.address.primary_club = 'No';
      if (this.address.is_primary == true) {
        this.address.primary_club = 'Yes';
        this.clubInfo.team_sites.forEach((value, index) => {
          this.clubInfo.team_sites[index].is_primary = false;
          this.clubInfo.team_sites[index].primary_club = 'No';
          this.clubInfoData.team_sites[index].is_primary = false;
        });
      }
      if (this.edit == false) {
        this.clubInfo.team_sites.push(this.address);
        //this.clubInfoData.team_sites.push(this.address);
        this.address.extId = "";
      } else {
        this.clubInfo.team_sites[this.address.index] = this.address;
        this.clubInfoData.team_sites[this.address.index] = this.address;
        this.edit = false;
      }
      this.isPrimarySiteAdded = false;
      let primarySite = this.clubInfo.team_sites.filter(site => site.is_primary == true);
      if (primarySite.length) this.isPrimarySiteAdded = true;
      this.$refs.teamSite.refresh();

      this.addressValid = true;

      //this.createOrUpdateSite(this.address);

      this.resetModalClubSite();
      this.$nextTick(() => {
        this.$bvModal.hide('modal-club-address')
      })
    },
    // Custom Validation Rules
    validate() {
      this.errors = {}

      const validName = validateName(this.form.name, "Name");
      this.errors.name = validName.error;

      const addressLengthValid = this.clubAddresses.length;
      if (addressLengthValid == 0) {
        this.errors.team_sites = 'Add at least one school information';
      } else {
        this.addressValid = true;
        this.errors.team_sites = '';
      }
      console.log(addressLengthValid);
      if (validName.valid && this.addressValid) {
        this.valid = true;
      }

      return this.valid;
    },
    addressValidate() {
      this.errors = {}
      this.valid = false;
      const validSchool = validateStateName(this.address.school, "School name");
      this.errors.school = validSchool.error;

      const validAddress = validateStateName(this.address.address, "Address");
      this.errors.address = validAddress.error;

      const validCountry = validateSelect(this.address.country, "Country");
      this.errors.country = validCountry.error;

      const validStateCode = validateSelect(this.address.state_code, "State");
      const validState = validateStateName(this.address.state, "state");
      if (validCountry.valid && this.address.country != 'USA') {
        this.errors.state = validState.error;
      } else {
        this.errors.state_code = validStateCode.error;
      }
      const validCity = validateStateName(this.address.city, "City");
      this.errors.city = validCity.error;

      const validZip = validateZip(this.address.zip);
      this.errors.zip = validZip.error;

      if (validCountry.valid && validCity.valid && validZip.valid) {
        this.addressValid = true;
        if (this.address.country == 'USA') {
          this.address.state = '';
          if (!validStateCode.valid) {
            this.addressValid = false;
          }
        } else if (!validState.valid) {
          this.address.state_code = '';
          this.addressValid = false;
        }
      }

      return this.addressValid;
    },
    siteValidate() {
      this.errors = {}
      this.siteValid = false;
      const validSchool = validateStateName(this.address.school, "");
      this.errors.school = validSchool.error;

      //const validAddress = validateStateName(this.address.address);
      //this.errors.address = validAddress.error;

      // const validStateCode = validateSelect(this.address.state_code);
      const validState = validateStateName(this.address.state_code);
      this.errors.state_code = validState.error;
      const validCity = validateStateName(this.address.city);
      this.errors.city = validCity.error;

      const validZip = validateZip(this.address.zip);
      this.errors.zip = validZip.error;

      if (validSchool.valid && validState.valid && validCity.valid && validZip.valid) {
        this.siteValid = true;
      }

      return this.siteValid;
    },
    collectClubData() {
      let clubFormData = this.clubForm;
      for (let prop in clubFormData) {
        if (Object.prototype.hasOwnProperty.call(this.clubInfoData, prop)) {
          console.log("m 1 prop" + prop);
          this.clubInfoData[prop] = Array.isArray(clubFormData[prop]) ? clubFormData[prop].join(",") : clubFormData[prop];
        }
      }
      if (this.fields.club_info != undefined && this.fields.club_info.club_discipilines != undefined) {
        // let disciplines = this.clubInfoData.club_disciplines.split(",");
        // if(Array.isArray(disciplines)){
        //     let disciplines_txt = [];
        //     for(let item of disciplines){
        //         disciplines_txt.push({ id: item, name: this.clubDisciplinesList[item]});
        //     }
        //     this.clubInfoData.club_disciplines = disciplines_txt;
        // }
        this.clubInfoData.club_disciplines = this.clubInfoData.club_disciplines.split(",");
      }
    },
    collectContactData() {
      let contactFormData = this.contactForm;
      for (let prop in contactFormData) {
        if (Object.prototype.hasOwnProperty.call(this.clubInfoData, prop)) {
          this.clubInfoData[prop] = Array.isArray(contactFormData[prop]) ? contactFormData[prop].join(",") : contactFormData[prop];
          this.clubInfoData.primary_contact[prop] = this.clubInfoData[prop];
          if (prop == 'address') this.clubInfoData.primary_contact['address1'] = this.clubInfoData[prop];
        }
      }

      this.paymentForm.billing_addressline1 = this.clubInfoData.address;
      this.paymentForm.billing_city = this.clubInfoData.city;
      this.paymentForm.billing_state = this.clubInfoData.state;
      this.paymentForm.billing_state_code = this.clubInfoData.state_code;
      this.paymentForm.billing_country = this.clubInfoData.country;
      this.paymentForm.billing_zip = this.clubInfoData.zip;
    },
    pushClubInfo(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (this.validateClub()) {
        // Trigger submit handler
        console.log('valid');
        this.collectClubData();
        this.$nextTick(() => {
          this.$bvModal.hide('modal-club-edit')
        });
        return;
      } else {
        console.log('un valid');
      }
    },
    loadClubInfo() {
      axios.get(this.basePath + "api/club/info", {headers: this.headers})
          .then((response) => {
            if (response.data.status == 'success') {
              const resp = response.data.data;
              this.regId = resp.registration.id;
              this.eligibleForPurchaseSanction = response.data.data.eligible_for_purchase;
              if (this.eligibleForPurchaseSanction == 0) {
                this.practiceExbhition = 'no';
              }
              this.verifyClubApiData();
            }

          }).catch((error) => {
        console.log(error);
      });
    },
    getActiveRegistration(triggerLoading = false) {
      this.loading = triggerLoading || false;
      let token = localStorage.getItem('clubToken')
      axios.get(this.basePath + 'api/club/active_registration?renew=1', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
          .then((res) => {
            let active = res.data.data;
            this.clubInfo = active;

            if(active.membership_cost === 0) this.$router.push({ name: "clubDashboard" });
            if(res.data.data.clubRenew !== undefined && !res.data.data.clubRenew) this.$router.push({ name: "clubDashboard" });

            // this.form = active;
            for (let prop in active) {
              if (Object.prototype.hasOwnProperty.call(this.clubInfoData, prop)) {
                if (prop != 'members' && prop != 'club_sanction_data') {
                  this.clubInfoData[prop] = active[prop];
                } else if (prop == 'club_sanction_data' && active[prop] != '') {
                  this.clubInfoData[prop] = active[prop];
                } else {
                  for (let prp in active[prop]) {
                    if (Object.prototype.hasOwnProperty.call(this.clubInfoData.members[0], prp)) {
                      this.clubInfoData.members[0][prp] = active[prop][prp];
                    }

                  }
                }
              }
            }
            this.clubInfoData.name = active.name;
            this.clubInfoData.type = active.type;
            this.clubInfoData.secondary_type = active.secondary_type;
            this.clubInfoData.email = active.email;
            this.clubInfoData.address = active.address;
            this.clubInfoData.city = active.city;
            this.clubInfoData.state = active.state;
            this.clubInfoData.state_code = active.state_code;
            this.clubInfoData.country = active.country;
            this.clubInfoData.zip = active.zip;
            this.clubInfoData.website = active.web;
            this.clubInfoData.team_sites = active.team_sites;
            this.clubInfoData.exclude = active.exclude;
            this.clubInfoData.membership_id = active.membership_type_id;

            this.membershipCost = active.membership_cost;
            this.membership.fee = this.membershipCost;
            this.membership.type = active.membership_type;
            this.membership.id = active.membership_type_id;
            this.membership.member_type_id = active.member_type_id;

            if (this.clubInfo.team_sites != undefined) {
              let primarySite = this.clubInfo.team_sites.filter(site => site.is_primary == true);
              if (primarySite.length) this.isPrimarySiteAdded = true;
            }
            if(this.clubInfoData.id == '96675') {
              this.clubInfoData.club_sanction_data = null;
              this.clubInfoData.club_sanction = null;
              this.practiceExbhition = 'no';
            }

            this.insuranceFee = active.insuranceFee;
            this.sanctionFee  = active.sanctionFee;

            this.mountPropData();

            this.loading = false;
            for (let i=0; this.clubInfo.insured_members.length > i; i++) {
              if (this.clubInfo.insured_members[i].specific_event_from_to_date == "") {
                this.insuranceForm.specific_event_start_date = "";
                this.insuranceForm.specific_event_end_date = "";
              }
              else{
                this.clubInfo.insured_members[i].specific_event_start_date = moment(this.clubInfo.insured_members[i].start_date, "YYYY-MM-DD").format("MM/DD/YYYY");
                this.clubInfo.insured_members[i].specific_event_end_date = moment(this.clubInfo.insured_members[i].end_date, "YYYY-MM-DD").format("MM/DD/YYYY");
              }
              this.editInsuranceRow(i,true);
              if (!this.insuranceValidate()) {
                this.clubInfo.insured_members[i].isRenew = false;
                this.clubInfo.insured_members[i].isNotValid = true;
              }
              else{
                this.clubInfo.insured_members[i].isRenew = true;
                this.clubInfo.insured_members[i].fulfilled = 0;
                this.clubInfo.insured_members[i].fulfilled_date = null;
              }
              this.updateKey++;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.button.nextButton = 'Next Step';
            return false;
          }).finally(() => {


      });
    },
    verifyClubApiData() {
      console.log("Verify!");
      axios.get(this.basePath + 'api/club_registration/' + this.regId)
          .then((res) => {
            //Perform Success Action
            //alert('success')
            console.log('verify success');
            this.verifyClubInfoData = res.data.data;
            // this.verifyClubInfoData.membership_id = this.verifyClubInfoData.membership_id;

            //Payment form
            this.paymentForm.registration_id = this.regId;
            this.paymentForm.billing_addressline1 = this.verifyClubInfoData.address1;
            this.paymentForm.billing_city = this.verifyClubInfoData.city;
            this.paymentForm.billing_state = this.verifyClubInfoData.state;
            this.paymentForm.billing_state_code = this.verifyClubInfoData.state_code;
            this.paymentForm.billing_country = this.verifyClubInfoData.country;
            this.paymentForm.billing_zip = this.verifyClubInfoData.zip;
            console.log('verify data');
            console.log(this.verifyClubInfoData);
            this.calculateTotals();
            //this.merchandiseDataApi();
            //console.log(this.verifyData);
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            this.button.nextButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        this.waiverApi();
      });
    },
    waiverApi() {
      console.log("Waiver!");
      axios.get(this.basePath + 'api/waivers/' + this.regId + '/' + 1)
          .then((res) => {
            //Perform Success Action
            //alert('success')
            console.log('waiver success');
            //this.waiverData = res.data.data;
            console.log('waiver data');
            console.log(this.waiverData);
            //this.merchandiseDataApi();
            //console.log(this.verifyData);
            this.waiversList[0] = true;
            this.waiverData = res.data.data;
            console.log('------------');
            console.log(this.waiverData);
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    ageGroup(birth_date) {
      console.log(birth_date);
      let age = this.getAge(birth_date);
      console.log(age);
      if (age > 17) {
        return 'Minor';
      } else if (age <= 17) {
        return 'Adult';
      }
    },
    removeAddress(i) {
      console.log("remove index=" + i);
      console.log(this.form.team_sites.length);
      this.form.team_sites.splice(i, 1);
      this.clubAddresses.splice(i, 1);
      if (this.form.team_sites.length == 0) {
        this.valid = false;
        this.addressValid = false;
      }
    },
    editAddress(i, row) {
      this.edit = true;
      this.$bvModal.show('modal-club-address');
      this.address = {
        extId: row.item.id,
        school: this.clubInfo.team_sites[i].school,
        address: this.clubInfo.team_sites[i].address,
        city: this.clubInfo.team_sites[i].city,
        state: this.clubInfo.team_sites[i].state,
        state_code: this.clubInfo.team_sites[i].state_code,
        country: this.clubInfo.team_sites[i].country,
        zip: this.clubInfo.team_sites[i].zip,
        is_primary: this.clubInfoData.team_sites[i].is_primary,
        index: i
      }

      console.log(this.address);
      console.log(this.address.school);
    },
    clubInformationApi() {
      console.log(this.clubInfoData);
      this.primaryErrors = [];
      this.clubInfoData.members = [];

      if (this.clubInfo.primary_contact == null && this.fields.club_contact != undefined && this.fields.club_contact.search_by_member != undefined) {
        this.primaryErrors.push('Primary Contact information is required! Please check above');
      }

      this.clubInfoData.members.push(this.clubInfo.primary_contact);
      if (this.fields.club_junior != undefined) {
        this.clubInfo.junior_development.forEach(function (item) {
          this.clubInfoData.members.push(item);
        }.bind(this));
      }
      if (this.clubInfo.president_info != null) {
        this.clubInfoData.members.push(this.clubInfo.president_info);
      }
      if (this.clubInfo.vp_info != null) {
        this.clubInfoData.members.push(this.clubInfo.vp_info);
      }
      if (this.clubInfo.secretary_info != null) {
        this.clubInfoData.members.push(this.clubInfo.secretary_info);
      }
      if (this.clubInfo.treasurer_info != null) {
        this.clubInfoData.members.push(this.clubInfo.treasurer_info);
      }
      if (this.clubInfo.ski_directors != null) {
        this.clubInfo.ski_directors.forEach(function (item) {
          this.clubInfoData.members.push(item);
        }.bind(this));
      }
      if (this.clubInfo.assistant_skidirectors != null) {
        this.clubInfo.assistant_skidirectors.forEach(function (item) {
          this.clubInfoData.members.push(item);
        }.bind(this));
      }

      if (this.clubInfo.safe_sport_coordinator != null) {
        this.clubInfoData.members.push(this.clubInfo.safe_sport_coordinator);
      }

      if (this.clubInfoData.team_sites != undefined && this.clubInfoData.team_sites.length == 0) {
        this.primaryErrors.push('At least one site information is required!');
      }
      if (this.clubInfo.insured_members != null) {
        this.clubInfoData.insured_members = this.clubInfo.insured_members.filter(item => !item.id || item.isRenew );
      }
      /*if(this.clubInfoData.insured_members.length == 0) {
                this.primaryErrors.push('At least one insurance is required!');
            }*/
      if (this.primaryErrors.length > 0) {
        return;
      }
      this.primaryErrors = [];


      this.calculateTotals();
      console.log('membership id=' + this.membership.id);
      console.log('another===' + this.wiz_step);
      //this.registrationNextstep();
      this.wiz_step3 = false;
      this.wiz_step4 = true;
      this.wiz_step++;
      console.log('another 1===' + this.wiz_step);
      this.waiverApi();

    },
    calculateTotals() {
      console.log('calculate total');
      let membershipFee = this.membership.fee;
      if (this.couponApplied.status == 1) {
        membershipFee = this.membership.fee - this.couponApplied.amount;
        membershipFee = membershipFee < 0 ? 0 : membershipFee;
      }
      this.totals.sub_total = parseFloat(this.removeCommaFromNumber(membershipFee) + this.removeCommaFromNumber(this.donated.amount)).toFixed(2);
      if (this.clubInfoData.insured_members != undefined && this.clubInfoData.insured_members.length > 0) {
        console.log('insurance length' + this.clubInfoData.insured_members.length);
        this.totals.sub_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total) + (parseInt(this.clubInfoData.insured_members.length) * this.removeCommaFromNumber(this.insuranceFee))).toFixed(2);
      }
      if (this.clubInfoData.club_sanction_data && this.clubInfoData.club_sanction_data.selected && this.clubInfoData.club_sanction_data.selected == true && this.eligibleForPurchaseSanction == 1 && this.practiceExbhition == 'yes') {
        this.totals.sub_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total) + parseFloat(this.removeCommaFromNumber(this.sanctionFee))).toFixed(2);
        this.sanctionFee = parseFloat(this.removeCommaFromNumber(this.sanctionFee)).toFixed(2);
      }
      console.log('---------');
      console.log(this.totals);
      this.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.totals.sub_total)) / 100 * this.removeCommaFromNumber(this.processingFeePercent)).toFixed(2);
      this.totals.grand_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total) + this.removeCommaFromNumber(this.processingFee)).toFixed(2);
      console.log('---------');
      console.log(this.processingFee);
      console.log(this.totals);

      this.totals.sub_total = this.totals.sub_total < 0 ? 0 : this.totals.sub_total;
      this.totals.grand_total = this.totals.grand_total < 0 ? 0 : this.totals.grand_total;

      if (this.totals.grand_total == 0.0) {
        this.paymentForm.visible = false;
        this.paymentForm.auto_renew = 0;
      } else {
        this.paymentForm.visible = true;
        this.paymentForm.auto_renew = this.getSiteProps('general.club_auto_renewal_option') ? 1 : 0;
      }
    },
    removeCommaFromNumber(number) {
      if (typeof (number) != "undefined") {
        return parseFloat(number.toString().replace(/,(?=.*\d+)/g, ''));
      }
    },
    waiverFormSubmit() {
      if (this.validateWaiver()) {
        console.log('waiver valid');
        this.registerationStep++;
        return;
      } else {
        console.log('waiver un valid');
      }
    },
    validatePayment() {
      this.errors = {}
      this.valid = false;
      console.log(this.paymentForm);
      const validPaymentFirstName = validatePaymentName(this.paymentForm.billing_firstname, "Name on Card");
      this.errors.billing_firstname = validPaymentFirstName.error;

      let validCardNumber = {valid: true, error: null};
      let validExpirationYear = {valid: true, error: null};
      let validExpirationMonth = {valid: true, error: null};
      let validCvv = {valid: true, error: null};
      if (this.getSiteProps('general.paymentGateway') == 'authorizenet' || this.getSiteProps('general.paymentGateway') == 'stripe_intent') {
        validCardNumber = validateCardNumber(this.paymentForm.cardNumber, "Card Number");
        this.errors.cardNumber = validCardNumber.error;

        validExpirationYear = validateSelect(this.paymentForm.expiration_year, "Year");
        this.errors.expiration_year = validExpirationYear.error;

        validExpirationMonth = validateSelect(this.paymentForm.expiration_month, "Month");
        this.errors.expiration_month = validExpirationMonth.error;

        validCvv = validateCvv(this.paymentForm.cvv, "CVV/CVC");
        this.errors.cvv = validCvv.error;
      }

      const validAddress1 = validatePaymentName(this.paymentForm.billing_addressline1, "Address");
      this.errors.billing_addressline1 = validAddress1.error;

      const validCountry = validateSelect(this.paymentForm.billing_country, "Country");
      this.errors.billing_country = validCountry.error;

      const validStateCodeSelect = validateSelect(this.paymentForm.billing_state_code, "State code");
      const validStateCodeInput = validateStateName(this.paymentForm.billing_state);

      if (validCountry.valid && this.paymentForm.billing_country == 'USA') {
        this.errors.billing_state_code = validStateCodeSelect.error;
      } else if(this.paymentForm.billing_country == 'Canada'){
        this.errors.billing_state = validStateCodeInput.error;
      }

      const validCity = validatePaymentName(this.paymentForm.billing_city, "City");
      this.errors.billing_city = validCity.error;

      const validZip = validateBillingzip(this.paymentForm.billing_zip, this.paymentForm.billing_country);
      this.errors.billing_zip = validZip.error;

      const validPaymentSign = validateWaiverInitials(this.paymentForm.sign);
      this.errors.payment_sign = validPaymentSign.error;

      const validPaymentAgree = validateWaiverCheckbox(this.paymentForm.agree);
      this.errors.payment_agree = validPaymentAgree.error;

      if (validPaymentFirstName.valid && validCardNumber.valid
          && validExpirationYear.valid && validExpirationMonth.valid && validCvv.valid && validAddress1.valid && validCountry.valid
          && validCity.valid && validZip.valid && validPaymentAgree.valid && validPaymentSign.valid && this.stripeError == "") {
        this.valid = true;
        if (this.paymentForm.billing_country == 'USA') {
          this.paymentForm.billing_state = '';
          if (!validStateCodeSelect.valid) {
            this.valid = false;
          }
        } else if (!validStateCodeInput.valid && this.paymentForm.billing_country == 'Canada') {
          this.paymentForm.billing_state_code = '';
          this.valid = false;
        }
      }

      return this.valid;
    },
    validateWaiver() {
      console.log('this waiver' + this.currentWaiver);
      console.log(this.waiverDataAgree);
      console.log(this.waiverData);
      this.errors = {}
      this.valid = false;
      let currentWaiverLength = this.waiverData[this.currentWaiver].user_info.length;
      let validWaiverAgree = {};
      let validWaiverSign = {};
      let validWaiverSignedMember = {};
      console.log('-----------------------' + currentWaiverLength);
      for (let i = 0; i < currentWaiverLength; i++) {
        console.log('-----------------------');
        console.log(this.waiverDataAgree[this.currentWaiver]);
        console.log(this.waiverData[this.currentWaiver]);
        validWaiverAgree = validateWaiverCheckbox(this.waiverDataAgree[this.currentWaiver].agree[i]);
        validWaiverSign = validateWaiverInitials(this.waiverData[this.currentWaiver].user_info[i].member_initials);

        validWaiverSign = {valid: true, errors: ''};

        //console.log('this waiver validWaiverAgree' + this.waiverDataAgree[this.currentWaiver].agree[i]);
        //console.log('this waiver validWaiverSign' + this.waiverData[this.currentWaiver].user_info[i].member_initials);
        if (this.membership.member_type_id != 4) {
          validWaiverSignedMember = validateSignedUserName(this.waiverData[this.currentWaiver].user_info[i].signed_member);
          this.waiverErrors[this.currentWaiver].signed_member[i] = validWaiverSignedMember.error;
        } else {
          validWaiverSignedMember.valid = true;
        }
        this.waiverErrors[this.currentWaiver].agree[i] = validWaiverAgree.error;
        this.waiverErrors[this.currentWaiver].initials[i] = validWaiverSign.error;
      }

      if (validWaiverAgree.valid && validWaiverSign.valid && validWaiverSignedMember.valid) {
        console.log('pass');
        this.valid = true;
        if (this.membership.member_type_id == 4) {
          //const validWaiverSignedUser = validateSignedUserName(this.waiverData[this.currentWaiver].user_info.signed_user);
          const validClubWaiverFirstName = validateSignedFirstName(this.clubSubmittedBy.first_name, 'First Name');
          const validClubWaiverLastName = validateSignedLastName(this.clubSubmittedBy.last_name, 'Last Name');
          const validClubWaiverPosition = validateSignedUserName(this.clubSubmittedBy.position, 'Club Position');
          const validClubWaiverEmail = validateSignedUserName(this.clubSubmittedBy.email, 'E-Mail');
          //this.waiverErrors.signed_user = validWaiverSignedUser.error;
          this.waiverClubErrors.club_submitted_first_name = validClubWaiverFirstName.error;
          this.waiverClubErrors.club_submitted_last_name = validClubWaiverLastName.error;
          this.waiverClubErrors.club_submitted_position = validClubWaiverPosition.error;
          this.waiverClubErrors.club_submitted_email = validClubWaiverEmail.error;
          if (!validClubWaiverFirstName.valid || !validClubWaiverLastName.valid || !validClubWaiverPosition.valid || !validClubWaiverEmail.valid) {
            this.valid = false;
            return this.valid;
          }
        }
        console.log(this.currentWaiver + ' === ' + this.waiverData.length);
        if (this.currentWaiver < this.waiverData.length) {
          console.log(this.currentWaiver + ' < ' + this.waiverData.length);
          this.currentWaiver = this.currentWaiver + 1;
          console.log(this.currentWaiver + ' < ' + this.waiverData.length);
        }
      }
      return this.valid;
    },
    handleInsuranceOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleInsuranceSubmit()
    },
    handleInsuranceSubmit() {
      // Exit when the form isn't valid
      if (!this.insuranceValidate()) {
        return;
      }
      if (this.insuranceForm.first_name != '' && this.insuranceForm.last_name != '' && this.notACompany) {
        this.insuranceForm.insurance_type = 1;
        this.insuranceForm.company_name = '';
      } else if (this.insuranceForm.company_name != '') {
        this.insuranceForm.insurance_type = 2;
      }
      if (this.insuranceEdit == false && this.clubInfo.insured_members != null) {
        this.insuranceForm.index = this.clubInfoData.insured_members.length;
      }

      if (this.insuranceEdit) {
        this.insuranceForm.isRenew = this.clubInfo.insured_members[this.insuranceEditKey].isRenew;
        this.insuranceForm.isNotValid = false;
        this.clubInfo.insured_members.splice(this.insuranceEditKey, 1, this.insuranceForm);
        this.updateKey++;
      } else {
        this.clubInfo.insured_members.push(this.insuranceForm);
      }

      /*console.log(this.insuranceForm.index);
            console.log("*************");
            console.log(this.clubInfoData.insured_members.length);
            console.log("-----------");
            console.log(this.form);
            this.$emit('insurance-data', this.form);*/
      // this.clubInfo.insured_members[this.insuranceForm.index] = this.insuranceForm;
      this.valid = true;
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-center6')
      })
    },
    insuranceValidate() {
      this.errors = {}
      this.insuranceErrors = {}
      this.valid = false;
      console.log('------validate-------');
      console.log(this.insuranceForm);
      if (this.insuranceForm.home_phone.length == 15){
        this.validTelinput = true;
      }
      else{
        this.validTelinput = false;
      }
      const validFirstName = validateInsuranceFirstName(this.insuranceForm.first_name, "First Name");
      const validLastName = validateInsuranceLastName(this.insuranceForm.last_name, "Last Name");
      const validCompanyName = validateInsuranceName(this.insuranceForm.company_name, "Company Name");
      const validAddress1 = validateInsuranceName(this.insuranceForm.address1, "Address");
      const validStateCode = validateInsuranceName(this.insuranceForm.state_code, "state");
      const validCity = validateInsuranceName(this.insuranceForm.city,"City");
      const validZip = validateInsuranceName(this.insuranceForm.zip, "Zip");
      //const validHomePhoneCode = validateName(this.insuranceForm.home_phone_country_code);
      const validHomePhone = validatePhone(this.insuranceForm.home_phone, this.validTelinput);
      const validCertificateType = validateInsuranceName(this.insuranceForm.certificate_type,"Certificate Type");
      const validSpecificEventTitle = validateInsuranceName(this.insuranceForm.specific_event_title,"specific Event Title");
      const validEventSanctionStartDate = validateName(this.insuranceForm.specific_event_start_date, "Event Start Date");
      const validEventSanctionEndDate = validateName(this.insuranceForm.specific_event_end_date, "Event End Date");
      const validSanctionedType = validateRadio(this.insuranceForm.sanctioned_type, "Specific Sanctioned Type");
      const validEventSanctionNo = validateInsuranceName(this.insuranceForm.event_sanction, "Event Sanction");
      const validTeamHasAnyAgreement = validateRadio(this.insuranceForm.team_has_any_agreement, "Agreement");
      const validAdditionalInsurance = validateRadio(this.insuranceForm.additional_insurance, "Insured Status");
      const validNotACompany = validateCheck(this.notACompany);

      this.insuranceErrors.first_name = validFirstName.error; //'Please Enter First Name';
      this.insuranceErrors.last_name = validLastName.error; //'Please Enter Last Name';
      this.insuranceErrors.company_name = validCompanyName.error;//'Please Company Name';
      this.insuranceErrors.address1 = validAddress1.error;
      this.insuranceErrors.state_code = validStateCode.error;
      this.insuranceErrors.city = validCity.error;
      this.insuranceErrors.zip = validZip.error;
      //this.errors.home_phone_country_code = validHomePhoneCode.error;
      this.insuranceErrors.home_phone = validHomePhone.error;
      this.insuranceErrors.certificate_type = validCertificateType.error;
      this.insuranceErrors.specific_event_title = validSpecificEventTitle.error;
      this.insuranceErrors.sanctioned_type = validSanctionedType.error;
      this.insuranceErrors.event_sanction = validEventSanctionNo.error;
      this.insuranceErrors.team_has_any_agreement = validTeamHasAnyAgreement.error;
      this.insuranceErrors.additional_insurance = validAdditionalInsurance.error;
      this.insuranceErrors.specific_event_start_date = validEventSanctionStartDate.error;
      this.insuranceErrors.specific_event_end_date = validEventSanctionEndDate.error;

      /*if(validFirstName.valid || validLastName.valid) {
                this.insuranceErrors.company_name = '';
            }
            if(validCompanyName.valid) {
                this.insuranceErrors.first_name = '';
                this.insuranceErrors.last_name = '';
            }*/
      if (this.insuranceForm.certificate_type == 'year') {
        this.insuranceErrors.sanctioned_type = '';
      }
      if(this.insuranceForm.certificate_type == 'specificEvent' && this.insuranceForm.specific_event_start_date != '' && this.insuranceForm.specific_event_end_date != ''){
        if(new Date(moment(this.dateVar)).getTime() < new Date(this.insuranceForm.specific_event_start_date).getTime()){
              this.valid = false;
              this.insuranceErrors.specific_event_start_date = "Your selected date is exceed of your expire date";
            }
        else if(new Date().getTime() > new Date(this.insuranceForm.specific_event_start_date).getTime()){
              this.valid = false;
              this.insuranceErrors.specific_event_start_date= "Your selected date is Invalid";
        }

        if(new Date(moment(this.dateVar)).getTime() < new Date(this.insuranceForm.specific_event_end_date).getTime()){
              this.valid = false;
              this.insuranceErrors.specific_event_end_date = "Your selected date is exceed of your expire date";
            }
        else if(new Date().getTime() > new Date(this.insuranceForm.specific_event_end_date).getTime()){
              this.valid = false;
              this.insuranceErrors.specific_event_end_date= "Your selected date is Invalid";
        }else if(new Date(this.insuranceForm.specific_event_start_date).getTime() > new Date(this.insuranceForm.specific_event_end_date).getTime()){
              this.valid = false;
              this.insuranceErrors.specific_event_end_date= "Your selected end date is earlier than start date";
        }
      }
      if ((validCompanyName.valid || (validFirstName.valid && validLastName.valid)) && validAddress1.valid
          && validStateCode.valid && validCity.valid && validZip.valid && validHomePhone.valid
          && validCertificateType.valid
          && validTeamHasAnyAgreement.valid && validAdditionalInsurance.valid) {
        this.valid = true;
        if (this.notACompany && (!validFirstName.valid || !validLastName.valid)) {
          this.valid = false;
        } else if (!this.notACompany && !validCompanyName.valid) {
          this.valid = false;
        }
        if (this.insuranceForm.certificate_type == 'specificEvent' && (!validSpecificEventTitle.valid || !validSanctionedType.valid || !validEventSanctionNo.valid || !validEventSanctionStartDate.valid || !validEventSanctionEndDate.valid)) {
          this.valid = false;
        } else {
          if (this.insuranceForm.certificate_type == 'specificEvent' && validEventSanctionStartDate.valid && !this.validateDate(this.insuranceForm.specific_event_start_date)) {
            this.valid = false;
            this.insuranceErrors.specific_event_start_date = "Invalid Date";
          }
          if(this.insuranceForm.certificate_type == 'specificEvent' && this.insuranceForm.specific_event_start_date != '' && new Date(moment(this.dateVar)).getTime() < new Date(this.insuranceForm.specific_event_start_date).getTime()){
            this.valid = false;
            this.insuranceErrors.specific_event_start_date = "Your selected date is exceed of your expire date";
          }
          if(this.insuranceForm.certificate_type == 'specificEvent' && this.insuranceForm.specific_event_start_date != '' && new Date().getTime() > new Date(this.insuranceForm.specific_event_start_date).getTime()){
              this.valid = false;
              this.insuranceErrors.specific_event_start_date= "Your selected date is Invalid";
          }

          if (this.insuranceForm.certificate_type == 'specificEvent' && validEventSanctionEndDate.valid && !this.validateDate(this.insuranceForm.specific_event_end_date)) {
            this.valid = false;
            this.insuranceErrors.specific_event_end_date = "Invalid Date";
          }
          if(this.insuranceForm.certificate_type == 'specificEvent' && this.insuranceForm.specific_event_end_date != '' && new Date(moment(this.dateVar)).getTime() < new Date(this.insuranceForm.specific_event_end_date).getTime()){
            this.valid = false;
            this.insuranceErrors.specific_event_end_date = "Your selected date is exceed of your expire date";
          }
          if(this.insuranceForm.certificate_type == 'specificEvent' && this.insuranceForm.specific_event_end_date != '' && new Date().getTime() > new Date(this.insuranceForm.specific_event_end_date).getTime()){
              this.valid = false;
              this.insuranceErrors.specific_event_end_date= "Your selected date is Invalid";
          }
        }
        if (this.valid) {
          this.errors = {}
          this.insuranceErrors = {}
        }
      }

      return this.valid;
    },
    removeInsurance(i) {
      console.log("remove index=" + i);
      this.$emit('insurance-remove-data', i);
      console.log("remove index=" + i);
      console.log(this.clubInfoData.insured_members.length);
      this.myInsurances.splice(i, 1);
      if (this.myInsurances.length == 0) {
        this.valid = false;
      }
    },
    resetModal() {
      this.form = {}
      this.company = {}
      this.member = {}

      if (Object.keys(this.clubInfo.insured_members).length == 0) {
        this.insuranceData = '';
      }
    },
    paymentApi() {
      this.showLoader = true;
      console.log("Payment submitted!");
      this.button.nextStepButton = 'Payment is in progress...';
      this.paymentForm.cardNumber = this.paymentForm.cardNumber.replace(/\s/g, '');
      this.paymentForm.name_on_card = this.paymentForm.billing_firstname;
      axios.post(this.basePath + 'api/paynow/' + this.regId, this.paymentForm)
          .then((res) => {
            if (res.data.status == 'success') {
              //Perform Success Action
              //alert('success')
              this.showLoader = false;
              console.log('payment success');
              this.button.nextStepButton = 'Next Step';
              console.log(res.data.message);
              this.paymentData = res.data.message;
              this.registration_message_flag = res.data.data.registration_message_flag;
              this.registration_message_text = res.data.data.registration_message_text;
              //console.log(this.verifyData);
              console.log('Finally');
              console.log("f=" + this.wiz_step);
              this.wiz_step++;
              console.log("s=" + this.wiz_step);
              this.wiz_step6 = false;
              this.wiz_step7 = true;
              //this.verifyApiData();
              return res;
            } else {
              this.showLoader = false;
              this.errors.payment_agree = res.data.errors;
              this.button.nextStepButton = 'Next Step';
            }
          })
          .catch((error) => {
            this.showLoader = false;
            console.log('Error:' + error);
            this.errors.payment_agree = error.errors;
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    submitWaiverForm() {
      console.log("Waiver Form submitted!");
      //this.button.nextButton = 'Registration is in progress...';
      axios.post(this.basePath + 'api/waivers/' + this.regId, this.signedWaiverData)
          .then((res) => {
            if (res.data.status == 'success') {
              //Perform Success Action
              //alert('success')
              //console.log('success');
              //console.log('success');
              //this.step++;
              //this.button.nextButton = 'Next Step';
              //this.regId = res.data.data.id;
              //this.paymentForm.registration_id = this.regId;
              //console.log(this.verifyData);
            }
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
        //this.verifyApiData();

      });
    },
    clubData() {
      if (this.validateClub()) {
        console.log('valid');
        let clubFormData = this.clubForm;
        for (let prop in clubFormData) {
          if (Object.prototype.hasOwnProperty.call(this.clubInfoData, prop)) {
            console.log("m 1 prop" + prop);
            this.clubInfoData[prop] = clubFormData[prop]
          }
        }
        this.$nextTick(() => {
          this.$bvModal.hide('modal-club-edit')
        });
        return;
      } else {
        console.log('un valid');
      }
    },
    // Custom Validation Rules
    validateClub() {
      this.errors = {}
      this.validClub = false;

      const validName = validateName(this.clubForm.name, "Club Name");
      this.errors.name = validName.error;

      const clubType = validateSelect(this.clubForm.type, "Primary Club Type");
      this.errors.type = clubType.error;

      const clubEmail = validateName(this.clubForm.email, "Email");
      this.errors.email = clubEmail.error;

      const clubAddress = validateName(this.clubForm.address , "Address");
      this.errors.club_info_address = clubAddress.error;

      const clubCity = validateName(this.clubForm.city , "City");
      this.errors.club_info_city = clubCity.error;

      const clubStateCode = validateSelect(this.clubForm.state_code , "State");
      this.errors.club_info_state_code = clubStateCode.error;

      const clubZip = validateZip(this.clubForm.zip , "Zip Code");
      this.errors.club_info_zip = clubZip.error;

      // if (this.fields && this.fields.club_info != undefined && this.fields.club_info.clubname != undefined)
      //   validName.valid = true;
      // if (this.fields && this.fields.club_info != undefined && this.fields.club_info.club_type != undefined)
      //   clubType.valid = true;
      // if (this.fields && this.fields.club_info != undefined && this.fields.club_info.email != undefined)
      //   clubEmail.valid = true;

      if (validName.valid && clubEmail.valid && clubType.valid && clubAddress.valid && clubCity.valid && clubStateCode.valid && clubZip.valid) {
        this.validClub = true;
      }

      //Shooting
      var clubVisibleType = "";
      if (this.fields.club_info != undefined
          && this.fields.club_info.club_visible_type != undefined && this.fields.club_info.club_visible_type.required != undefined
          && this.fields.club_info.club_visible_type.required) {
        clubVisibleType = validateSelect(this.clubForm.visibility_type, "Visibility Type");
        this.errors.club_visible_type = clubVisibleType.error;
      }
      var clubType2 = "";
      if (this.fields.club_info != undefined
          && this.fields.club_info.club_type != undefined && this.fields.club_info.club_type.required != undefined
          && this.fields.club_info.club_type.required) {
        clubType2 = validateSelect(this.clubForm.club_type, "Club Type");
        if (Array.isArray(this.clubForm.club_type)) {
          if (this.clubForm.club_type.length == 0) {
            clubType2.valid = false;
            clubType2.error = "This field is required";
          } else {
            clubType2.valid = true;
            clubType2.error = "";
          }
        }
        this.errors.club_type = clubType2.error;
      }
      var clubdisciplines = "";
      if (this.fields.club_info != undefined
          && this.fields.club_info.club_discipilines != undefined && this.fields.club_info.club_discipilines.required != undefined
          && this.fields.club_info.club_discipilines.required) {
        clubdisciplines = validateCheck(this.clubForm.club_disciplines);
        this.errors.club_discipilines = clubdisciplines.error;
      }
      var clubAdminID = "";
      if (this.fields.club_info != undefined
          && this.fields.club_info.club_admin_member_id != undefined && this.fields.club_info.club_admin_member_id.required != undefined
          && this.fields.club_info.club_admin_member_id.required) {
        clubAdminID = validateName(this.clubForm.club_admin_member_id.toString());
        this.errors.club_admin_member_id = clubAdminID.error;
      }
      var clubAdminName = "";
      if (this.fields.club_info != undefined
          && this.fields.club_info.club_admin_name != undefined && this.fields.club_info.club_admin_name.required != undefined
          && this.fields.club_info.club_admin_name.required) {
        clubAdminName = validateName(this.clubForm.club_admin_name, "Club Admin Name");
        this.errors.club_admin_name = clubAdminName.error;
      }

      if (clubVisibleType != "" && clubType2 != "" && clubdisciplines != "" && clubAdminID != "" && clubAdminName != "") {
        if (clubVisibleType.valid && clubType2.valid && clubdisciplines.valid && clubAdminID.valid && clubAdminName.valid)
          this.validClub = true;
        else
          this.validClub = false;
      }

      //Shooting

      return this.validClub;
    },
    fillClubData() {
      let clubInfoData = this.clubInfoData;
      for (let prop in clubInfoData) {
        if (Object.prototype.hasOwnProperty.call(this.clubForm, prop)) {
          // console.log("m prop"+prop);
          if (clubInfoData[prop] != null) {
            this.clubForm[prop] = clubInfoData[prop];
          }else{
            this.clubForm[prop] = '';
          }
        }
      }
      if (Object.prototype.hasOwnProperty.call(this.clubForm, 'club_type') && clubInfoData.club_type != null)
        this.clubForm.club_type = clubInfoData.club_type.split(",");

      // if(Object.prototype.hasOwnProperty.call(this.clubForm, 'club_disciplines') && Array.isArray(this.clubForm.club_disciplines) && this.clubForm.club_disciplines.length)
      //     this.clubForm.club_disciplines = this.clubInfoData.club_disciplines.map(item => item.id);

      if (Object.prototype.hasOwnProperty.call(this.clubForm, 'retype_email'))
        this.clubForm.retype_email = clubInfoData.email;

      this.clubForm['primary_contact'] = clubInfoData['primary_contact'];
      if (this.clubForm.primary_contact != undefined) {
        this.clubForm.primary_contact.state_code = this.clubForm.primary_contact.state_code || this.clubForm.state_code;
      }
    },
    fillContactData() {
      this.contactForm.email = this.clubInfoData.email;
      this.contactForm.retype_email = this.clubInfoData.email;
      this.contactForm.address = this.clubInfoData.primary_contact.address1;
      this.contactForm.city = this.clubInfoData.primary_contact.city;
      this.contactForm.state_code = this.clubInfoData.primary_contact.state_code;
      this.contactForm.zip = this.clubInfoData.primary_contact.zip;
      this.contactForm.phone_1 = this.clubInfoData.primary_contact.phone_1;
      this.contactForm.physical_address = this.clubInfoData.primary_contact.physical_address;
      this.contactForm.physical_city = this.clubInfoData.primary_contact.physical_city;
      this.contactForm.physical_state_code = this.clubInfoData.primary_contact.physical_state_code;
      this.contactForm.physical_zip = this.clubInfoData.primary_contact.physical_zip;
    },
    applyCoupon() {
      let regData = {};
      regData.price = parseFloat(this.membership.fee);
      regData.coupon_code = this.couponApplied.coupon_code;
      regData.coupon_type_id = 2;
      this.button.coupon = 'Applying Coupon...';
      this.couponErrors = {};
      console.log('---------------');
      console.log(regData);
      axios.post(this.basePath + 'api/check_coupon', regData)
          .then((res) => {
            if (res.data.status == 'success') {
              this.loadWaiver = false;
              this.couponApplied.status = 1;
              this.couponApplied.amount = res.data.data.value;
              this.couponApplied.text = res.data.data.text + ' off membership (' + this.couponApplied.coupon_code + ')';
              this.couponErrors.success = res.data.message;

              this.clubInfoData.coupon_id = res.data.data.id;
              this.clubInfoData.discount_amount = res.data.data.value;

              if (this.membership.member_type_id == 4) {
                this.verifyClubApiData();
              }
            } else {
              this.couponErrors.error = res.data.message;
              this.couponApplied.status = 0;
              this.couponApplied.amount = 0.00;
              this.couponApplied.text = '';

              this.clubInfoData.coupon_id = null;
              this.clubInfoData.discount_amount = 0.00;

              this.calculateTotals();
            }
            this.button.coupon = 'Apply Coupon';
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            this.button.coupon = 'Apply Coupon';
            return false;
          }).finally(() => {
      });
    },
    addFilledWaiverData() {
      let waiverNumber = this.currentWaiver > 0 ? this.currentWaiver - 1 : 0;
      let currentWaiverLength = this.waiverData[waiverNumber].user_info.length;
      for (let i = 0; i < currentWaiverLength; i++) {
        this.waiverForm.waivers_id = this.waiverData[waiverNumber].waivers_id;
        this.waiverForm.initials = this.waiverData[waiverNumber].user_info[i].member_initials;
        this.waiverForm.signed_for = this.waiverData[waiverNumber].user_info[i].member_name;
        this.waiverForm.signed_user = this.waiverData[waiverNumber].user_info[i].signed_member;
        if (this.membership.member_type_id == 4) {
          this.waiverForm.club = this.clubSubmittedBy;
        }
        console.log(this.waiverForm);
        this.signedWaiverData.waivers.push(this.waiverForm);
      }
    },
    donationAmountData() {
      if (this.validateDonation()) {
        console.log('valid');
        //this.button.nextStepButton = 'Payment is in progress...';
        this.$emit('donation-amount-data', this.form);
        //this.button = 'Next';
        return;
      } else {
        console.log('un valid');
      }
    },
    validateDonation() {
      this.errors = {};
      this.valid = false;
      const validDonationDiscipline = validateSelect(this.donationForm.donation_discipline, "Sport Discipline");
      const validDonationAmount = validateAmount(this.donationForm.donation_amount, "Donation Amount");
      
      if(!this.validateConfirmation(true)) return false;

      if (validDonationDiscipline.valid && this.donationForm.donation_discipline != "0") {
        if (!validDonationAmount.valid) {
          this.errors.donation_amount = validDonationAmount.error;
          this.valid = false;
        } else {
          this.valid = true;
        }
      } else {
        if (validDonationAmount.valid && !validDonationDiscipline.valid) {
          this.errors.donation_discipline = validDonationDiscipline.error;
          this.valid = false;
        } else {
          this.valid = true;
        }
      }

      if (this.donationForm.donation_amount.length && this.donationForm.donation_amount != '' && this.donationForm.donation_discipline == "0") {
        this.errors.donation_discipline = 'This field is required';
        this.valid = false;
      }

      return this.valid;
    },
    validateYesNo() {

      if (this.subdomain == "shooting") return true;      
      this.overAllError = 1;
      const validPracticeExbhition = validateRadio(this.practiceExbhition, "Purchase Practice/Exhibition Sanctioning");
      const validprsidentInfo = validateRadio(this.prsidentInfoData, "President");
      const validvprsidentInfo = validateRadio(this.vprsidentInfoData, "Vice President");
      const validtreasurerInfo = validateRadio(this.treasurerInfoData, "Treasurer");
      const validsecretaryInfo = validateRadio(this.secretaryInfoData, "Secretary");
      const validsjuniorInfo = validateRadio(this.juniorInfoData, "Junior Development Leader");
      const validskiDirectors = validateRadio(this.skiDirectorsData, "Show Director");
      const validaskiDirectors = validateRadio(this.askiDirectorsData, "Assistant Show Director");
      const validsafeSportCoordinator = validateRadio(this.safeSportCoordinatorData, "Safe Sport Coordinator");
      const validinsurance = validateRadio(this.insuranceData, "Purchase Certificate of Insurance");
      this.practiceExbhitionError = '';
      this.presidentError = '';
      this.vpresidentError = '';
      this.treasurerError = '';
      this.secretaryError = '';
      this.juniorError = '';
      this.skiDirectorsError = '';
      this.askiDirectorsError = '';
      this.safeSportCoordinatorError = '';
      this.insuranceError = '';
      this.errors = {};
      this.valid = true;  
      this.overAllError = 0;
      if (this.clubInfo.club_sanction_data == null && !validPracticeExbhition.valid  && this.eligibleForPurchaseSanction == 1) {
        this.practiceExbhitionError = validPracticeExbhition.error;                
        this.valid = false;  
        this.overAllError = 1;
        this.clubForm.club_sanction_data = {
          selected: false,
          practise_with_guest: 0,
          practise_without_guest: 0,
          exhibition: 0,
          practise_agree: 0,
          exhibition_agree: 0,
          practise_sign_name: '',
          exhibition_sign_name: '',
          club_id: ''
        };
      }
      if (this.clubInfo.president_info == null && !validprsidentInfo.valid) {
        this.presidentError = validprsidentInfo.error;        
        this.valid = false;  
        this.overAllError = 1;      
      }
      if (this.clubInfo.vp_info == null && !validvprsidentInfo.valid) {
        this.vpresidentError = validvprsidentInfo.error;        
        this.valid = false; 
        this.overAllError = 1;       
      }
      if (this.clubInfo.treasurer_info == null && !validtreasurerInfo.valid) {
        this.treasurerError = validtreasurerInfo.error;        
        this.valid = false; 
        this.overAllError = 1;       
      }
      if (this.clubInfo.secretary_info == null && !validsecretaryInfo.valid) {
        this.secretaryError = validsecretaryInfo.error;        
        this.valid = false;
        this.overAllError = 1;        
      }
      if (Object.keys(this.clubInfo.junior_development).length == 0 && !validsjuniorInfo.valid) {
        this.juniorError = validsjuniorInfo.error;        
        this.valid = false;        
        this.overAllError = 1;
      }
      if (Object.keys(this.clubInfo.ski_directors).length == 0 && !validskiDirectors.valid) {
        this.skiDirectorsError = validskiDirectors.error;        
        this.valid = false;  
        this.overAllError = 1;      
      }
      if (Object.keys(this.clubInfo.assistant_skidirectors).length == 0 && !validaskiDirectors.valid) {
        this.askiDirectorsError = validaskiDirectors.error;        
        this.valid = false;
        this.overAllError = 1;
      }
      if (this.clubInfo.safe_sport_coordinator == null && !validsafeSportCoordinator.valid) {
        this.safeSportCoordinatorError = validsafeSportCoordinator.error;        
        this.valid = false;
        this.overAllError = 1;
      }
      this.teamSiteError = '';
      if (this.subdomain == 'waterski') {
        const addressLengthValid = this.clubInfo.team_sites.length;
        if (addressLengthValid == 0) {
          this.teamSiteError = 'Add at least one ski site where club activities take place.';
          
        } else {
          if (this.isPrimarySiteAdded == false) {
            this.teamSiteError = 'Add at least one primary / ski site';            
            this.valid = false;   
            this.overAllError = 1;
          }
        }
        let siteZip = this.clubInfo.team_sites.filter(site => site.zip == '');
        if (siteZip.length) {
          this.teamSiteError = 'Please enter zip code ';
          this.valid = false;
          this.overAllError = 1;
        }
      }
      if (Object.keys(this.clubInfo.insured_members).length == 0 && !validinsurance.valid) {
        this.insuranceError = validinsurance.error;
        this.valid = false;
        this.overAllError = 1;
        
      }

      return this.valid;
    },
    amountFormat() {
      let s = this.donationForm.donation_amount;
      var rgx = /^[0-9]*\.?[0-9]*$/;
      this.donationForm.donation_amount = s.match(rgx);
    },
    saveDonation() {
      if (this.donationForm.donation_amount > 0) {
        this.button.nextStepButton = 'Save Donation...';
        console.log(this.donationForm);
        axios.post(this.basePath + 'api/saveDonation/' + this.regId, this.donationForm)
            .then((res) => {
              this.button.nextStepButton = 'Next Step';
            })
            .catch((error) => {
              this.button.nextStepButton = 'Next Step';
              return false;
            }).finally(() => {
          document.getElementById('app').scrollIntoView();
        });
      } else {
        document.getElementById('app').scrollIntoView();
      }
    },
    mountPropData() {
      const retry = setInterval(() => {
        this.steps = this.getSiteProps('memberRegistration.club');
        if (this.steps.fields != undefined) {
          let sections = Object.keys(this.steps.fields);
          if (sections && sections.length) {
            // console.log(">>>>>>>>>>>>>>>", sections);
            // let data = [];
            for (let name of sections) {
              this.fields[name] = this.getSiteProps('memberRegistration.club.fields.' + name);
            }
            // sections.forEach(name => {
            //     data[name] = this.getSiteProps('memberRegistration.club.fields.' + name);
            // });
            if (Object.keys(this.fields).length) {
              clearInterval(retry);

              this.$forceUpdate();

              if (this.fields.club_info != undefined && this.fields.club_info.club_discipilines != undefined) {
                this.getClubDisciplines();
              }
            }
          }
        }
      }, 200);
    },
    triggerSelectDisciplinesAll(event) {
      if (event.target.checked) {
        var ids = Object.keys(this.clubDisciplinesList);
        if (ids.length) {
          ids.forEach(id => {
            this.clubForm.club_disciplines.push(id);
          });
        }
      } else {
        this.clubForm.club_disciplines = [];
      }
    },
    searchMember() {
      this.clubAdminSearch.searched = false;
      this.clubAdminSearch.memberIDSearched = "";
      if (this.clubForm.club_admin_member_id) {
        axios.get(this.basePath + "api/member_lookup?member_id=" + this.clubForm.club_admin_member_id).then(res => {
          if (res.data.data != undefined && res.data.data.length) {
            this.errors.club_admin_name = "";
            //   console.log(res.data.data[0].personal_details.full_name);
            this.clubForm.club_admin_name = res.data.data[0].personal_details.full_name;
          } else {
            this.clubForm.club_admin_name = "";
          }
        }).catch(err => {
          console.log(err);
          this.clubAdminSearch.searched = true;
          this.clubAdminSearch.memberIDSearched = this.clubForm.club_admin_member_id;

        }).finally(() => {
          this.clubAdminSearch.searched = true;
          this.clubAdminSearch.memberIDSearched = this.clubForm.club_admin_member_id;
        });
      }
    },
    changeSameAddress(event) {
      if (this.contactForm.same_as_mailing_address) {
        this.contactForm.physical_address = this.contactForm.address;
        this.contactForm.physical_city = this.contactForm.city;
        this.contactForm.physical_state_code = this.contactForm.state_code;
        this.contactForm.physical_zip = this.contactForm.zip;
      }
    },
    validateTelinput(obj) {
      this.validTelinput = obj.valid;
      console.log(obj);
      if (obj.valid) {
        this.errors.mobilephone = '';
      }
    },
    validateContact() {
      this.errors = {};

      const validEmail = validateEmail(this.contactForm.email, "E-Mail");
      this.errors.email = validEmail.error;

      const validRetypeEmail = validateEmail(this.contactForm.retype_email, "Retype E-Mail");
      this.errors.retype_email = validRetypeEmail.error;

      const validAddress1 = validateName(this.contactForm.address, "Club Address");
      this.errors.club_address = validAddress1.error;

      const validCity = validateName(this.contactForm.city, "City");
      this.errors.club_city = validCity.error;

      const validState = validateSelect(this.contactForm.state_code, "State");
      this.errors.club_state = validState.error;

      const validZip = validateZip(this.contactForm.zip);
      this.errors.club_zip = validZip.error;

      const validPhysicalZip = validateNonMandatoryZip(this.contactForm.physical_zip);
      this.errors.club_physical_zip = validPhysicalZip.error;

      const validPhone1 = validatePhone(this.contactForm.phone_1, this.validTelinput);
      this.errors.mobilephone = validPhone1.error;

      if (
          this.errors.email == null &&
          this.errors.retype_email == null &&
          this.errors.club_address == null &&
          this.errors.club_city == null &&
          this.errors.club_state == null &&
          this.errors.club_zip == null &&
          this.errors.mobilephone == null &&
          this.errors.club_physical_zip == null
      ) {
        if (this.contactForm.email != this.contactForm.retype_email) {
          this.errors.retype_email = 'The email id does not match';
          return false;
        }
        return true;
      }

      return false;
    },
    updateClubContact(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.validateContact()) {
        //   this.handleSubmit();
        // console.log(this.clubForm);
        this.collectContactData();
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('edit-club-contact')
        });
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    setCurrentAgreement(value) {
      this.currentAgreement;
    },
    removeDonation() {
      this.donated = {
        type: '',
        amount: 0.00
      };
      this.clubInfoData.donation_id = null;
      this.clubInfoData.donation_amount = 0;
      this.calculateTotals();
    },
    SanctionAgreementCheck(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.errors = {}
      let practise = true;
      let exhibition = true;
      let sanction = false;

      this.clubForm.club_sanction_data.selected = false;

      if (this.clubForm.club_sanction_data.practise_without_guest == 1 || this.clubForm.club_sanction_data.practise_with_guest == 1 || this.clubForm.club_sanction_data.exhibition == 1) {
        sanction = true;
        this.practice_and_sanction = true;
      }
      if (sanction == true && this.clubForm.club_sanction_data.practise_agree == 0) {
        this.errors.practise_agree = "Please acknowledge by checking the box";
        practise = false;
      }
      if (sanction == true && this.clubForm.club_sanction_data.practise_sign_name == '') {
        this.errors.practise_sign_name = "Please enter your name to proceed";
        practise = false;
      }
      if (sanction == true && this.clubForm.club_sanction_data.exhibition_agree == 0) {
        this.errors.exhibition_agree = "Please acknowledge by checking the box";
        exhibition = false;
      }
      if (sanction == true && this.clubForm.club_sanction_data.exhibition_sign_name == '') {
        this.errors.exhibition_sign_name = "Please enter your name to proceed";
        exhibition = false;
      }

      if (practise && exhibition) {
        if (this.clubForm.club_sanction_data.practise_without_guest == 1 || this.clubForm.club_sanction_data.practise_with_guest == 1 || this.clubForm.club_sanction_data.exhibition == 1) {
          this.clubForm.club_sanction_data.selected = true;
        }
        this.clubInfoData.club_sanction_data = this.clubForm.club_sanction_data;

        this.$nextTick(() => {
          this.$bvModal.hide('modal-club-sanction-edit')
        });
      }
    },
    submitForm() {
      this.showLoader = true;
      if (this.newRegId != '') {
        return this.paymentApi();
      }
      if(this.practiceExbhition != 'yes'){
        if(this.clubInfoData.club_sanction_data != undefined && this.clubInfoData.club_sanction_data.selected != undefined){
          this.clubInfoData.club_sanction_data.selected = false;
        }
      }
      axios.post(this.basePath + 'api/club_registration', this.clubInfoData)
          .then((res) => {
            if (res.data.status == 'success') {
              console.log('success');
              //this.step++;
              // this.button.nextStepButton = 'Next Step';
              console.log('===' + this.wiz_step);
              // this.verifyClubInfoData = res.data.data;
              this.regId = res.data.data.registration_id;
              this.newRegId = res.data.data.registration_id;
              //this.verifyClubApiData();
              // this.verifyClubInfoData.membership_id = this.verifyClubInfoData.membership_type_id;
              // //primary contact
              // this.verifyClubInfoData.primary_contact.address1 = this.verifyClubInfoData.address1;
              // this.verifyClubInfoData.primary_contact.city = this.verifyClubInfoData.city;
              // this.verifyClubInfoData.primary_contact.state = this.verifyClubInfoData.state;
              // this.verifyClubInfoData.primary_contact.state_code = this.verifyClubInfoData.state_code;
              // this.verifyClubInfoData.primary_contact.country = this.verifyClubInfoData.country;
              // this.verifyClubInfoData.primary_contact.zip = this.verifyClubInfoData.zip;
              //Payment form
              this.paymentForm.registration_id = this.regId;
              // this.paymentForm.billing_addressline1 = this.verifyClubInfoData.address1;
              // this.paymentForm.billing_city = this.verifyClubInfoData.city;
              // this.paymentForm.billing_state = this.verifyClubInfoData.state;
              // this.paymentForm.billing_state_code = this.verifyClubInfoData.state_code;
              // this.paymentForm.billing_country = this.verifyClubInfoData.country;
              // this.paymentForm.billing_zip = this.verifyClubInfoData.zip;

              // this.calculateTotals();
              //this.paymentForm.registration_id = this.regId;
              console.log('membership id=' + this.membership.id);
              console.log('another===' + this.wiz_step);
              //this.registrationNextstep();
              // this.wiz_step3 = false;
              // this.wiz_step4 = true;
              // this.wiz_step++;
              // console.log('another 1===' + this.wiz_step);
              // this.waiverApi();

              this.signedWaiverData.registration_id = this.regId;
              this.submitWaiverForm();

              this.paymentApi();
              return res;
            } else {
              this.showLoader = false;
              this.button.nextStepButton = 'Next Step';
              this.errors.clubRegError = res.data.message;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            this.errors.clubRegError = error.errors;
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Club Finally');
        //this.verifyApiData();
      });
    },
    clearAdminName() {
      this.clubAdminSearch.searched = false;
      this.clubForm.club_admin_name = "";
    },
    stripeTokenCreated(res) {
      this.stripeError = "";
      this.stripeToken = res.id;
      this.paymentForm.stripeToken = res.id;
      this.submitForm();
      // document.getElementById('app').scrollIntoView();
    },
    captureStripeErrors(errors) {
      this.stripeError = errors;
      console.log("===========>", errors);
      this.showLoader = false;
    },
    stripeFormChanges(obj) {
      this.stripeValidate = false;
      if (obj.complete) {
        this.stripeError = "";
        this.stripeValidate = true;
      }
    },
    showPurchasePractice() {
      if (this.practiceExbhition == 'yes') {
        this.$bvModal.show('modal-club-sanction-edit')
      }
    },
    resetPurchasePractice() {
      if(this.practice_and_sanction != true){
        this.practiceExbhition = '';
      }
    },
    showMemberLookup(type, val) {
      if (val == 'yes') {
        this.$bvModal.show('member-lookup-modal')
        this.setContactType(type);
      }

    },
    resetMemberLookup(data) {
      if (data == 1) {
        if (Object.keys(this.clubInfo.junior_development).length == 0) {
          this.juniorInfoData = '';
        }
      } else if (data == 2) {
        if (this.clubInfo.president_info == null) {
          this.prsidentInfoData = '';
        }
      } else if (data == 3) {
        if (this.clubInfo.vp_info == null) {
          this.vprsidentInfoData = '';
        }
      } else if (data == 4) {
        if (this.clubInfo.secretary_info == null) {
          this.secretaryInfoData = '';
        }
      } else if (data == 5) {
        if (this.clubInfo.treasurer_info == null) {
          this.treasurerInfoData = '';
        }
      } else if (data == 6) {
        if (Object.keys(this.clubInfo.ski_directors).length == 0) {
          this.skiDirectorsData = '';
        }
      } else if (data == 7) {
        if (Object.keys(this.clubInfo.assistant_skidirectors).length == 0) {
          this.askiDirectorsData = '';
        }
      } else if (data == 8) {
        if (this.clubInfo.safe_sport_coordinator == null) {
          this.safeSportCoordinatorData = '';
        }
      }
    },
    createOrUpdateSite(siteInfo) {
      axios.post(this.basePath + "api/club/ski_site", siteInfo, {headers: this.clubHeaders})
          .then(response => {
            this.getActiveRegistration();
          })
          .catch(error => {
            console.log(error);
          })
    },
    removeStateCode(){
      this.paymentForm.billing_state = '';
      this.paymentForm.billing_state_code = '';
    }
  },
  mounted() {
    let year1 = new Date().getFullYear();
            if(new Date() > new Date("10/31/"+year1)){
                this.dateVar = "12/31/"+(parseInt(year1)+1);
            }
    this.loadPropReqData();
    this.loadClubInfo();
    this.getActiveRegistration(true);
    this.getClubType();

    const year = new Date().getFullYear();
    this.yearArray = Array.from({length: 12}, (value, index) => year + index);

    const retry2 = setInterval(() => {
      //Init processing fee percent from property file
      let registration_fee_percent = this.getSiteProps("general.registration_fee_percent");
      if (registration_fee_percent && this.processingFeePercent == 0 && registration_fee_percent != undefined && !isNaN(registration_fee_percent)) {
        this.processingFeePercent = registration_fee_percent;
        clearInterval(retry2);
      }
    }, 200);

    const retry3 = setInterval(() => {
      this.prop_confirmation = this.getSiteProps('memberRegistration.confirmation');
      if (this.prop_confirmation) clearInterval(retry3);
    }, 200);
    
  },
  filters: {
    filterBr(value) {
      return value.replace("<br>", " ");
    },
    fullNumber(value) {
      if (typeof (value) != "undefined" && !isNaN(value)) {
        return parseFloat(value).toFixed(2);
      }
    },
  },
  computed: {
    club_sanction() {
        return this.clubInfo.club_sanction_data.club_id != '' ? true : false;
      
    }
  }
}
</script>